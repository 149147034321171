export function doesFileExceedSizeLimit(videoFile) {
    const videoFileSize = videoFile.size;

    const videoFileSizeInGB = Math.round(videoFileSize / 1000000000);

    if (videoFileSizeInGB > 6) {
        return true;
    } else {
        return false;
    }
}

export function isVideoFileTooShort(videoFile, completionCallback) {
    let duration = 0;
    let video = document.createElement("video");
    video.src = URL.createObjectURL(videoFile);
    video.preload = "metadata";

    video.onloadedmetadata = function () {
        duration = video.duration;
        console.log("DURATION:", duration);

        console.log("VIDEO FILE DURATION:", duration);

        completionCallback(duration);
    }
}

export function getVideoDuration(videoFile) {
    return new Promise((resolve, reject) => {
        let video = document.createElement("video");
        video.src = URL.createObjectURL(videoFile);
        video.preload = "metadata";

        video.onloadedmetadata = function () {
            resolve(video.duration);
        };

        video.onerror = function (error) {
            reject(error);
        };
    });
}
