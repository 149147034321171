import React, { useState, useEffect } from 'react';
import LoadingBar from './LoadingBar';
import { Socket } from 'socket.io-client';

interface LoadingNotificationModalProps {
    setIsOpen: (isOpen: boolean) => void;
    timeToCompletion: number;
    message?: string;
    doneButtonLink?: string;
    isCompletedProp?: boolean | undefined;
    socket?: Socket;
    taskID?: string | null;
}

const LoadingNotificationModal = ({ 
    setIsOpen, 
    timeToCompletion, 
    message, 
    doneButtonLink, 
    isCompletedProp,
    socket,
    taskID
 }: LoadingNotificationModalProps) => {

    const [progress, setProgress] = useState(0);
    const [updateMessage, setUpdateMessage] = useState<string>(message || "Mobilizing our AI baristas... 🤖");
    const [isCompleted, setIsCompleted] = useState<boolean | undefined>(isCompletedProp);

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const getErrorMessage = (error: string) => {
        const errorString = error.toLowerCase();

        if (errorString.includes("private")) {
            return "Oops. An error occurred while processing your request. Your youtube video seemed to be a private link. Please try again with a public or unlisted video. 🤗";
        } else if (errorString.includes("unavailable")) {
            return "Oops. An error occurred while processing your request. Your youtube video was unavailable - this might be due to geographic restrictions or the video being deleted. Please try again with a different video. 🤗";
        } else if (errorString.includes("stream")) {
            return "Oops. An error occurred while processing your request. Your youtube link may have been a live stream. Please try again with a different video. 🤗";
        } else {
            return "Oops. An error occurred while processing your request. Please try again with a different video. If the problem persists, please email us at hello@latte.social and we'll handle it for you right away. 🤗";
        }
    }

    const handleErrorMessage = (error: string) => {
        const errorMessage = getErrorMessage(error);
        setErrorMessage(errorMessage);
        setError(true);
        setIsCompleted(true);
    }

    useEffect(() => {
        const handleSocketMessage = (event: any) => {
            const { message, error } = event;
            const newProgress = event.progress;

            if (error) {
                handleErrorMessage(error);
            } else {
                if (newProgress) {
                    setProgress(Math.max(newProgress * 100, progress));
                    newProgress === 1 && setIsCompleted(true); // if progress is 1, set isCompleted to true
                }
                if (event.timeToCompletion) {
                  timeToCompletion = event.timeToCompletion;
                }
                setUpdateMessage(message);
            }
        };

        socket?.on("connect", () => {
            console.log("🚀 Connected to server:", socket.id);
            socket?.emit('join', taskID);
            socket?.on("real-time-update", handleSocketMessage);
          });
        
          if (socket?.connected) {
            socket?.emit('join', taskID);
            socket?.on("real-time-update", handleSocketMessage);
          } else {
            socket?.connect();
          }
      
        return () => {
            socket?.off("real-time-update", handleSocketMessage);
            socket?.disconnect();
        };
      }, [socket]);

    useEffect(() => {
        let interval: NodeJS.Timeout;
    
        if (!isCompleted) {
            interval = setInterval(() => {
                setProgress((prevProgress) => {
                    if (prevProgress >= 99) {
                        clearInterval(interval);
                        return prevProgress;
                    } else {
                        const increment = 100 / (timeToCompletion * 1000 / 100);
                        const newProgress = prevProgress + increment;
                        return Math.min(newProgress, 99);
                    }
                });
            }, 100);
        }
        return () => {
            clearInterval(interval);
        };
    }, [isCompleted, timeToCompletion]);

    useEffect(() => {
        if (isCompletedProp === true) {
            setIsCompleted(true);
        }
    }, [isCompletedProp])

    useEffect(() => {
        if (!isCompleted) {
            setProgress(0);
        }
        return () => {
            console.log("CLEANED UP")
        }
    }, [isCompleted])

    useEffect(() => {
        setProgress(0);
    }, [])
    
    useEffect(() => {
        if (isCompletedProp === true) {
            setIsCompleted(true);
        }
    }, [isCompletedProp])

    const getEstimatedTimeRemaining = () => {
        const secondsRemaining = Math.round((100 - progress) * timeToCompletion / 100);
        const minutesRemaining = Math.floor(secondsRemaining / 60);
        const seconds = secondsRemaining % 60;
        return `${minutesRemaining}m ${seconds}s`;
    }

    // 📺 VISUAL DISPLAY COMPONENTS
    const LoadingBarComponent = () => {
        if (!isCompleted) {
            return (
                <>
                    <LoadingBar progress={progress} />
                    <p className="text-sm text-gray-500">Estimated time remaining: {getEstimatedTimeRemaining()}</p>
                </>
            )
        }
        return null;
    }

    const ProgressUpdateMessage = () => {
        if (error) {
            return (
                <h1 className="font-poppins font-medium text-center">Seems like something went wrong 😔</h1>
            )
        }
        return (
            <h1 className="font-poppins font-medium text-center">{updateMessage}</h1>
        )
    }

    const CompletionMessage = () => {

        if (error) {
            return (
                <p className="text-center text-sm font-poppins text-[#545454] h-20">
                    {errorMessage}
                </p>
            )
        }

        if (isCompleted) {
            if (doneButtonLink) {
                return (
                    <a
                        href={doneButtonLink}
                        className="text-center text-sm underline font-poppins text-[#545454]"
                        target="_blank"
                        rel="noopener noreferrer">
                        Your videos are ready 🎉 click here to view
                    </a>
                );
            } else {
                return (
                    <p className="text-center text-sm font-poppins text-[#545454]">
                        Your videos are ready 🎉
                    </p>
                );
            }
        }
    
        return null;
    };

    return (
        <div className={`fixed z-20 mx-auto mt-4 inset-0 p-4 shadow-lg w-11/12 md:w-[500px] rounded-2xl bg-white border-0 border-[#ffd9c1] ${error ? "h-40" : "h-28"}`}>
            <div className="flex flex-col space-y-3 items-center justify-center h-full w-full bg-white">
                <ProgressUpdateMessage />
                <LoadingBarComponent />
                <CompletionMessage />
            </div>
        </div>
    )
}

export default LoadingNotificationModal;