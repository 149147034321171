import axios from 'axios';

export const fetchVideoMetadata = async (videoID: string, fields: string[] = []) => {
    const videoMetadataEndpoint = `https://latte-video-server-dwr7snkjfq-ew.a.run.app/get-video-metadata`
    const projection = Object.fromEntries(fields.map(field => [field, 1]));

    const params = {
        videoID: videoID,
        projection: encodeURIComponent(JSON.stringify(projection)),
    }
    const response = await axios.get(videoMetadataEndpoint, { params: params })
    return response.data;
}

export const updateVideoMetadata = async (videoID: string, field: string, value: any) => {
    const updateVideoMetadataEndpoint = "https://latte-video-server-dwr7snkjfq-ew.a.run.app/update-video-metadata"
    const payload = {
        videoID: videoID,
        field: field,
        value: value,
    }

    const res = await axios.post(updateVideoMetadataEndpoint, payload)
    return res.data;
}

// To-do: post to /subtitles-edited with videoID and repurposingInstructions
export const notifySubtitlesEdited = async (videoID: string, repurposingInstructions: string[]) => {
    console.log(`Repurposing instructions to be modified: ${repurposingInstructions}`)
    const endpoint = "https://latte-video-server-dwr7snkjfq-ew.a.run.app/subtitles-edited"
    const payload = {
        videoID: videoID,
        repurposingInstructions: repurposingInstructions,
    }

    const res = await axios.post(endpoint, payload)
    console.log(res.data)
    return res.data;
}