import React, { useRef, useEffect, useState, ChangeEventHandler, FormEventHandler } from "react"

interface UpgradeModalProps {
    isOpen: boolean;
    onClose: () => void;
    message?: string;
}

export const UpgradeModal = ({ isOpen, onClose, message }: UpgradeModalProps) => {
    const messageToShow = message ? message : "Sorry, you've used up all your videos for the month. Tap below to upgrade 🚀"

    return (
        <div className="fixed z-100 mx-auto my-auto inset-0 min-h-28 max-h-52 flex p-4 shadow-lg w-[500px] rounded-2xl bg-white border-0 border-[#ffd9c1]"
            style={
                isOpen ? { display: "flex" } : { display: "none" }
            }>
            <div className="flex flex-col space-y-3 items-center justify-center h-full w-full bg-white">
                <h1 className="font-poppins font-semibold text-2xl text-center">
                    Upgrade plan to continue
                </h1>
                <p className="font-poppins font-normal text-sm text-center">
                    {messageToShow}
                </p>
                <button className="font-poppins bg-main-gradient bg-cover rounded-2xl px-4 py-2"
                onClick={() => {
                    window.open("/video/upgrade", "_self")        
                }}>
                    Upgrade
                </button>
            </div>
            <button className="absolute top-0 right-0 m-4 font-poppins"
                onClick={onClose}>
                Close
            </button>
        </div>
    )
}