import React, { useState, useEffect, useContext } from 'react';
import { QueryBasedVideoEntry } from '../../Types/DataTypes';
import { PostProcessedVideoEntry } from '../../Types/DataTypes';
import { VideoOrSummaryContainerDisplay } from '../../Types/Enums';
import axios from 'axios';
import TimedProgressBar from '../../Components/EssentialComponents/TimedProgressBar';
import socket from '../../socket';
import { fetchVideoMetadata } from '../../HelperFunctions/VideoMetadataHelpers';
import { emitProgressUpdate, fetchProgressFromServer } from '../../HelperFunctions/EmitProgressUpdate';
import UserManager from '../../HelperClasses/Networking/UserManager';
import CurrentlyViewingContext from '../../ContextManagers/CurrentlyViewingContext';
import { sleep } from '../../HelperFunctions/sleep';
import MixpanelTracker from '../../HelperClasses/Analytics/MixpanelTracker';

interface VariantsMenuModalProps {
    videoEntry: QueryBasedVideoEntry | undefined
    updateVideoToDisplay: (videoURL: string | null) => void;
    setIsOpen: (isOpen: boolean) => void;
    displayVideoOrSummary: VideoOrSummaryContainerDisplay;
    setVideoOrSummary: (videoOrSummary: VideoOrSummaryContainerDisplay) => void;
    setCurrentlyViewingVideoOrientation: (orientation: string) => void;
    setShowMagicSubtitlesUpgradeModal: (showMagicSubtitlesUpgradeModal: boolean) => void;
}

type VariantTypeMapType = {
  [key: string]: string[];
  "Magic Subtitles ✨": string[];
  "Vertical & Subtitles 📱": string[];
  "Subtitles 📝": string[];
  "Vertical ⬆": string[];
};

const getMatchingOption = (variantDisplay: string, variants: any[] | null) => {
  if (!variants) {
      return null;
  }
  return variants.find((variant) => {
      const variantType = variant.type;   
      return variantType === variantDisplay;
  });
};

// This should probably be moved to a helper function
const magicSubtitlesUsageCheck = async () => {
  const email = localStorage.getItem("email");
  if (!email) return false;
  const userManager = new UserManager();
  const fieldsToGet = ["magicSubtitlesUsedThisMonth", "maxMagicSubtitles"]
  const { magicSubtitlesUsedThisMonth, maxMagicSubtitles } = await userManager.getUserAccountFields(email, fieldsToGet);
  console.log("magicSubtitlesUsedThisMonth", magicSubtitlesUsedThisMonth);
  console.log("maxMagicSubtitles", maxMagicSubtitles);
  return magicSubtitlesUsedThisMonth < maxMagicSubtitles;
}

const VariantsMenuModal = ({ 
  videoEntry, 
  setIsOpen, 
  updateVideoToDisplay, 
  setVideoOrSummary, 
  setCurrentlyViewingVideoOrientation,
  setShowMagicSubtitlesUpgradeModal,

}: VariantsMenuModalProps) => {
    const [variants, setVariants] = useState<any[] | null>(null);
    const [videoID, setVideoID] = useState<string | null>(null);
    const [variantsLoaded, setVariantsLoaded] = useState<boolean>(false);
    const [repurposingInstructionsSelected, setRepurposingInstructionsSelected] = useState<string[]>([]);
    const [variantMenuKey, setVariantMenuKey] = useState<string>('');
    const [refreshKey, setRefreshKey] = useState<number>(0);
    const {
      currentlyViewingVariantType,
      setCurrentlyViewingVideoID,
      setCurrentlyViewingVariantType,
      setCurrentlyViewingVideoMetadataEntry,
      setShowRepurposingInstructionsSentModal,
    } = useContext(CurrentlyViewingContext);
    const mixpanelTracker = new MixpanelTracker();

    const [defaultRepurposingOptions, setDefaultRepurposingOptions] = useState<string[]>([
        "Magic Subtitles ✨",
        "Vertical & Subtitles 📱",
        "Subtitles 📝",
        "Vertical ⬆"
    ]);

    const variantTypeMap: VariantTypeMapType = {
      "Magic Subtitles ✨": ["vertical", "magic-subtitles"],  
      "Vertical & Subtitles 📱": ["vertical", "subtitles"],
      "Subtitles 📝": ["subtitles"],
      "Vertical ⬆": ["vertical"],
        
    }
    const reverseVariantTypeMap: Record<string, string> = {};

    Object.entries(variantTypeMap).forEach(([key, value]) => {
        const listKey = JSON.stringify(value);
        reverseVariantTypeMap[listKey] = key;
    });
    const fetchVideoMetadataVariants = async () => {
      // Get variants of the videoID from videoMetadataCollection
      // const videoMetadata = await fetchVideoMetadata(videoID!, ["variants"]);
      const videoMetadata = await fetchVideoMetadata(videoID!);
      console.log(`VideoID: ${videoID}`);
      // console.log(`🦄 Fetched video metadata:`, videoMetadata);
      setCurrentlyViewingVideoMetadataEntry(videoMetadata);

      const fetchedVariants = videoMetadata.variants;
      // map the keys of the variantTypeMap to the fetched variants
      const mappedVariants = fetchedVariants && fetchedVariants.map((variant: any) => {
          const listKey = JSON.stringify(variant.variant);
          const type = reverseVariantTypeMap[listKey];
          return {
              ...variant,
              type,
          }
      });

      mappedVariants && setVariants(mappedVariants);
      setVariantsLoaded(true);
  };

    const handleVariantOptionClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>, option: any) => {
        // await fetchVideoMetadataVariants();
        // console.log(`🦄 Clicked ${option.type} option`);
        setCurrentlyViewingVariantType(variantTypeMap[option.type]);

        e.preventDefault();

        const videoURLResponse = await axios.post(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/internal-video-download?videoName=${option.filename}`)

        const videoURL = videoURLResponse.data.url[0];
        
        updateVideoToDisplay(videoURL)

        setVideoOrSummary(VideoOrSummaryContainerDisplay.Video);

        if (option.type.includes("Vertical") || option.type.includes("Magic")) {
          // console.log(`🦄 Setting orientation to portrait`);
            setCurrentlyViewingVideoOrientation("portrait");
        } else {
            setCurrentlyViewingVideoOrientation("landscape");
        }

        setIsOpen(false);
    }

    const handleSummaryClick = () => {
        setVideoOrSummary(VideoOrSummaryContainerDisplay.Summary);
        setIsOpen(false);
    }

    // useEffect(() => {
    //   if (currentlyViewingVariantType.length === 1 && currentlyViewingVariantType[0] === "original") {
    //     handleOriginalVideoClick();
    //   }
    // }, [currentlyViewingVariantType])

    const handleOriginalVideoClick = () => {
        setVideoOrSummary(VideoOrSummaryContainerDisplay.Video);
        setCurrentlyViewingVariantType(["original"]);
        updateVideoToDisplay(null);
        setIsOpen(false);
    }

    const handleMouseLeave = () => {
        setIsOpen(false);
    }   

    useEffect(() => {
      videoID && fetchVideoMetadataVariants();
    }, [refreshKey])

    useEffect(() => {
        videoID && fetchVideoMetadataVariants();
    }, [videoID])

    useEffect(() => {
        const videoID = videoEntry!.videoID;
        setVideoID(videoID);
        setCurrentlyViewingVideoID(videoID);
    }, [videoEntry])
   

    function generateKeyFromVariants(variants: any[] | undefined | null) {
      if (!variants) return "";
      // Stringify the list of objects
      const jsonString = JSON.stringify(variants);
    
      // Replace special characters to create a valid React key
      const reactKey = jsonString.replace(/[\[\]{},"]+/g, '');
    
      return reactKey;
    }

    useEffect(() => {
      const reactKey = generateKeyFromVariants(variants);
      setVariantMenuKey(reactKey);
    }, [variants])


    const sendToRepurposingProcessor = async (e: any) => {
      const endpoint = 'https://repurposing-processor-router-uhhxc4xeaq-ew.a.run.app/repurpose';
      const payloads = [];
      for (let repurposingInstructionDisplay of repurposingInstructionsSelected) {
        const repurposingInstructions = variantTypeMap[repurposingInstructionDisplay];
        if (repurposingInstructionDisplay === "Magic Subtitles ✨") {
          mixpanelTracker.track("🪄 User requested Magic Subtitles", localStorage.getItem("email")!);
          const magicSubtitlesUsageAuthorized = await magicSubtitlesUsageCheck();
          if (!magicSubtitlesUsageAuthorized) {
            setShowMagicSubtitlesUpgradeModal(true);
            setIsOpen(false);
            return;
          } else {
            const userManager = new UserManager();
            userManager.incrementMagicSubtitlesUsage(localStorage.getItem("email")!);
          }
        }

        const taskID = `${videoID}-${repurposingInstructionDisplay}`;
        // Force emit first update to set progress bar in motion
        await emitProgressUpdate(taskID, 0.0001, true);
        const payload = {
          videoID,
          repurposingInstructions,
        };
        payloads.push(payload);
      }
      setRepurposingInstructionsSelected([]);
      setIsOpen(false);
      setShowRepurposingInstructionsSentModal(true);

      for (let payload of payloads) {
        axios.post(endpoint, payload);
        await sleep(2000);  // sleep for 2 seconds in between each repurposing instruction
      }
    }

    // const renderRepurposingMenuOption = (optionType: string, index: any) => {
    //     const matchingOption = getMatchingOption(optionType);
    //     const optionProps = matchingOption
    //       ? { option: matchingOption }
    //       : { optionType, taskID: `${videoID}-${optionType}` };
      
    //     return (
    //       <div
    //         className="justify-between flex items-center space-y-2 flex-col w-full"
    //         key={`${optionType}-${index}`}
    //         onClick={(e) => matchingOption && handleVariantOptionClick(e, matchingOption)}
    //       >
    //         <RepurposingMenuOption {...optionProps} />
    //         {// add divider if not the last option
    //         index !== defaultRepurposingOptions.length - 1 && (
    //           <div className="w-full h-[1px] bg-gray-100"></div>
    //         )}
    //       </div>
    //     );
    //   };

      const repurposingMenuOption = (optionType: string, index: any) => {
        const repurposingInstructions = variantTypeMap[optionType];

        return (
          videoID && variantsLoaded &&
          <div
            className="justify-between flex items-center space-y-2 flex-col w-full"
            key={`${optionType}-${index}-${refreshKey}`}
            onClick={(e) => {console.log(optionType)}}
          >
            <RepurposingMenuOption 
              videoID={videoID!}
              variantDisplay={optionType} 
              repurposingInstructions={repurposingInstructions} 
              taskID={`${videoID}-${optionType}`}
              variants={variants}
              handleVariantOptionClick={handleVariantOptionClick}
              repurposingInstructionsSelected={repurposingInstructionsSelected}
              setRepurposingInstructionsSelected={setRepurposingInstructionsSelected}
              setRefreshKey={setRefreshKey}
              />
            {// add divider if not the last option
            index !== defaultRepurposingOptions.length - 1 && (
              <div className="w-full h-[1px] bg-gray-100"></div>
            )}
          </div>
        );
      }
      if (!variantsLoaded) return (
        <></>
      );



    return (
        <div className="z-10000 absolute rounded-xl bg-white top-0 right-0 px-3 py-3 shadow-xl shadow-[#ffe7d5] flex flex-col min-w-[200px] absolute top-1/2 transform -translate-y-1/2"
            onMouseLeave={handleMouseLeave}
            // key={generateKeyFromVariants()}
            >
            <div className="flex flex-col justify-between items-center space-y-2"
                // key={variantMenuKey}
            >
                {   
                    defaultRepurposingOptions.map((optionType, index) =>
                    // renderRepurposingMenuOption(optionType, index)
                    repurposingMenuOption(optionType, index))
                }
                {/* <div className="w-full font-poppins cursor-pointer text-sm text-center hover:scale-105 duration-200 flex flex-col space-y-2 justify-center items-center">
                    <div className="w-full h-[1px] bg-gray-100"></div>
                    <button onClick={handleSummaryClick}>
                        Summary
                    </button>
                </div> */}
                <div className="w-full font-poppins cursor-pointer text-sm text-center hover:scale-105 duration-200 flex flex-col space-y-2 justify-center items-center">
                    <div className="w-full h-[1px] bg-gray-100"></div>
                    <button onClick={handleOriginalVideoClick}>
                        Original
                    </button>
                </div>
                {
                    repurposingInstructionsSelected.length > 0 &&
                    <div className="w-full font-poppins font-semibold cursor-pointer text-sm text-center hover:scale-105 duration-200 flex flex-col space-y-2 justify-center items-center">
                        <div className="w-full h-[1px] bg-gray-100"></div>
                        <button onClick={sendToRepurposingProcessor} className="w-full flex justify-center items-center space-x-2">
                            Repurpose 🪄
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}

interface RepurposingMenuOptionProps {
  videoID: string;
  variantDisplay: string;
  repurposingInstructions: string[];
  taskID: string;
  variants: any;
  handleVariantOptionClick: (e: any, option: any) => void;
  repurposingInstructionsSelected: string[];
  setRepurposingInstructionsSelected: (instructions: string[]) => void;
  setRefreshKey: (key: number) => void;
}

const RepurposingMenuOption = ({ 
  videoID, 
  variantDisplay, 
  repurposingInstructions, 
  taskID, 
  variants, 
  handleVariantOptionClick,
  repurposingInstructionsSelected,
  setRepurposingInstructionsSelected,
  setRefreshKey,
}: RepurposingMenuOptionProps) => {
  const [hover, setHover] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showPercentage, setShowPercentage] = useState(false);
  const [childProgress, setChildProgress] = useState(0);
  const [initialProgressFetched, setInitialProgressFetched] = useState(false);
  const [lastEmittedProgress, setLastEmittedProgress] = useState(0);
  const [content, setContent] = useState<React.ReactNode>(null);
  const [taskAlreadyCompleted, setTaskAlreadyCompleted] = useState(false);
  const {
    currentlyViewingVideoMetadataEntry,
  } = useContext(CurrentlyViewingContext);

  useEffect(() => {
    updateContent();
  }, [variants, repurposingInstructionsSelected]);

  useEffect(() => {
    if (!taskID) return;
  
    const fetchAndUpdateProgress = async () => {
      const fetchedProgress = await fetchProgressFromServer(taskID);
      // console.log(`Fetched progress for ${variantDisplay}: ${fetchedProgress}`);
      if (fetchedProgress && fetchedProgress > progress) {
        // console.log(`Existing progress for ${variantDisplay}: ${progress}`);
        // console.log(`Updating progress for ${variantDisplay}: ${fetchedProgress}`);
        setProgress(fetchedProgress);
        setChildProgress(fetchedProgress);
      }
    };
  
    const handleRealTimeUpdate = (data: any) => {
      socket.emit('join', taskID);
      if (data.entryID === taskID) {
        console.log(`Received real-time update for ${taskID}:`, data.progress);
        // console.log(`Updating progress for ${variantDisplay}: ${data.progress}`);
        // setProgress(data.progress);
        // setChildProgress(data.progress);
      }
    };
  
    // Set up the interval to fetch and update progress periodically
    const fetchProgressInterval = setInterval(fetchAndUpdateProgress, 5000);
    // Set up the socket listener for real-time updates
    socket.on('real-time-update', handleRealTimeUpdate);
  
    // Clean up the interval and socket listener when the component is unmounted or taskID changes
    return () => {
      clearInterval(fetchProgressInterval);
      socket.off('real-time-update', handleRealTimeUpdate);
    };
  }, [taskID]);
  

  const fetchProgress = async () => {
    // This function fetches from mongo (this is not necessarily real time, but is based on last emitted update, 
    // either by the client or the server
    if (!taskID || progress === 1) return;
    const response = await fetch(
      `https://latte-real-time-updates-dwr7snkjfq-ew.a.run.app/progress/${taskID}`
    );
    const data = await response.json();
    // console.log(`Data for ${taskID}:`, data);
    if (data.progress === null) {
      // console.log(`Task ${taskID} is complete!`);
      // refreshVariants();
      if (!initialProgressFetched) {
        setTaskAlreadyCompleted(true);
      }
      setProgress(1);
      setChildProgress(1);
      return;
    }

    console.log(`📈 Fetched progress for ${taskID}:`, data.progress);
    console.log(`Existing progress: ${progress}`);
    if (data.progress && data.progress !== progress) {
      if (data.progress > progress) {
          console.log(`Updating progress for ${taskID}: ${data.progress}`);
          setProgress(data.progress);
          setChildProgress(data.progress);
      }
    }
  };

  useEffect(() => {
    if (childProgress == 1 && !taskAlreadyCompleted) {
      setRefreshKey(Math.random());
    } 
    
  }, [childProgress]);

  useEffect(() => {
    if (!taskID || progress === 1) return;
  
    // Fetch progress every 5 seconds (5000 ms)
    const intervalId = setInterval(fetchProgress, 5000);
  
    // Cleanup function to clear the interval when the component is unmounted or dependencies change
    return () => clearInterval(intervalId);
  }, [taskID, progress]);

  useEffect(() => {
    fetchProgress();
    setInitialProgressFetched(true);
  }, []);


    useEffect(() => {
        if (!taskID) return;
    
        const fetchAndUpdateProgress = async () => {
          // Set a threshold for progress updates, e.g. 5% difference
          const progressUpdateThreshold = 0.01;
    
          if (childProgress > 0 && Math.abs(childProgress - lastEmittedProgress) >= progressUpdateThreshold) {
            console.log(`Child progress: ${childProgress}`);
            emitProgressUpdate(taskID!, childProgress);
            setLastEmittedProgress(childProgress);
          }
        };
    
        // Set up the interval to emit updates periodically
        const emitUpdatesInterval = setInterval(fetchAndUpdateProgress, 5000);
    
        // Clean up the interval when the component is unmounted or taskID changes
        return () => {
          clearInterval(emitUpdatesInterval);
        };
      }, [taskID, childProgress, lastEmittedProgress]);

  useEffect(() => {
    if (!initialProgressFetched) return;
    setShowPercentage(true);
  }, [initialProgressFetched]);

  const safeEmitProgressUpdate = () => {
      // check if abs(childProgress - lastEmittedProgress) >= 1
      // if so, emit
      if (!taskID) return;

      if (Math.abs(childProgress - lastEmittedProgress) >= 0.01) {
          emitProgressUpdate(taskID!, childProgress);
          setLastEmittedProgress(childProgress);
      }
  }

  const childProgressConverter = (childProgress: number) => {
    return (childProgress * 100).toFixed(0);
  };

  const handleClick = async (e: any, option: any, taskID: string, fetchedProgress: number | null) => {
      console.log(`Clicked ${variantDisplay} - ${option}`);
      console.log(`Fetched Progress: ${fetchedProgress}`);
      if (option !== null && option !== undefined) {
        handleVariantOptionClick(e, option);
        return;
      }

      if (fetchedProgress === null) {
          // no progress found, send to repurposing processor
          // await sendToRepurposingProcessor(repurposingInstructions);
          let newRepurposingInstructionsSelected;

          if (repurposingInstructionsSelected.includes(variantDisplay)) {
            newRepurposingInstructionsSelected = repurposingInstructionsSelected.filter(
              (item) => item !== variantDisplay
            );
          } else {
            newRepurposingInstructionsSelected = [
              ...repurposingInstructionsSelected,
              variantDisplay,
            ];
          }
          setRepurposingInstructionsSelected(newRepurposingInstructionsSelected);

          // emitProgressUpdate(taskID, 0, true);
          // setProgress(0);
          // setChildProgress(0);
          // setLastEmittedProgress(0);
          // setShowPercentage(true);
          return;
      }
  }

  const updateContent = async () => {
    const option = getMatchingOption(variantDisplay, variants);
    const fetchedProgress = await fetchProgressFromServer(taskID);
    // console.log(`Fetched progress for ${variantDisplay}: ${fetchedProgress}`);
    // console.log(`Converted progress for ${variantDisplay}: ${childProgressConverter(fetchedProgress)}`);
    // console.log(`PROGRESS: ${progress}`);

    
    const newContent = (
      <div
        onClick={(e) => handleClick(e, option, taskID, fetchedProgress)}
        style={{
          color: repurposingInstructionsSelected.includes(variantDisplay) ? '#8adb98' : 'inherit',
        }}
      >
        {`${variantDisplay}`}
      </div>
    );
      
    setContent(newContent);
  };

  const renderContent =  () => {
    // ⏰ Time Estimates: 
    // For 5 min vid: 
    // Subtitles only - took 5 min (5 min 30 to be safe)
    // Magic Subtitles took 25 min for 5 min vid
    // Vertical took 21 min for 5 min vid
    // Vertical + Subtitles took 24 min for 5 min vid
    // --- //
    // For ~90s vid:
    // Magic Subtitles - 7 min 
    // Vertical + Subtitles - 7 min 
    // Vertical - 6ish min
    // Subtitles - 2 min
    // --- //
    // For ~60s vid:
    // Magic Subtitles - 6 min
    // Vertical + Subtitles - 6 min
    // --- //
    // For ~30s vid:
    // Magic Subtitles - 3 min 
    // Vertical + Subtitles - 3 min 
    // Vertical - 3 min
    // Subtitles - less than 1 min
    const taskInvolvesTalkNet = variantDisplay.includes("Vertical") || variantDisplay.includes("Magic");

    let timeToCompletion = 16 * 60;

    if (currentlyViewingVideoMetadataEntry) {
      const startTimeOfVideoClip = currentlyViewingVideoMetadataEntry.startTime;
      const endTimeOfVideoClip = currentlyViewingVideoMetadataEntry.endTime;
      const videoClipDurationInSeconds = endTimeOfVideoClip - startTimeOfVideoClip;
      const videoClipDurationInMinutes = videoClipDurationInSeconds / 60;
      if (taskInvolvesTalkNet) {
        if (videoClipDurationInMinutes <= 0.6) {
          timeToCompletion = 3.2 * 60;
        } else if (videoClipDurationInMinutes <= 1.1) {
          // Less than around 1 minute
          timeToCompletion = 6.2 * 60;
        } else if (videoClipDurationInMinutes <= 1.6) {
          timeToCompletion = 8 * 60;
        } else if (videoClipDurationInMinutes <= 3.1) {
          timeToCompletion = 15 * 60;
        } else {
          timeToCompletion = 25 * 60;
        }
      } else {
        timeToCompletion = videoClipDurationInSeconds + 30;
      }
    }

    // Add an extra 30 seconds if magic
    if (variantDisplay.includes("Magic")) {
      timeToCompletion += 30;
    }

    const progressBarDisplay = (
      <>
        <h1 key={`${variantDisplay}-${childProgressConverter(childProgress)}`}>
          {variantDisplay} {childProgressConverter(childProgress)}%
        </h1>
        <div
          style={{
            width: 0,
            height: 0,
            overflow: "hidden",
            visibility: "hidden",
          }}
        >
          <TimedProgressBar 
            progress={progress} 
            setParentProgress={setChildProgress} 
            timeToCompletion={timeToCompletion * 1.2} />
        </div>
      </>
    );

    // console.log(`Progress for ${variantDisplay}: ${progress}`);
    // console.log(`Child progress for ${variantDisplay}: ${childProgress}`);
    if (childProgress && progress !== 1) {
      return progressBarDisplay;
    }

    return (
      <div
        onMouseLeave={safeEmitProgressUpdate}
      >
        {content}
      </div>
    );

      // if (option) {
      //   return <h1>{option.type}</h1>;
      // } else if (showPercentage && optionType) {
      //   // Render the percentage only if showPercentage is true
      //   return (
      //     <>
      //       <h1 key={`${optionType}-${childProgressConverter(childProgress)}`}>
      //         {optionType} {childProgressConverter(childProgress)}%
      //       </h1>
      //       <div
      //         style={{
      //           width: 0,
      //           height: 0,
      //           overflow: "hidden",
      //           visibility: "hidden",
      //         }}
      //       >
      //         <TimedProgressBar progress={progress} setParentProgress={setChildProgress} timeToCompletion={
      //             optionType.includes("Vertical") ? 480 : 300
      //         } />
      //       </div>
      //     </>
      //   );
      // } else {
      //   return <h1>{optionType}</h1>;
      // }
    };

  return (
    <div
      className="w-full font-poppins cursor-pointer text-sm hover:scale-105 duration-200 flex items-center justify-center"
      // key={`${variantDisplay}-${childProgressConverter(childProgress)}-${progress}`}
      onMouseLeave={safeEmitProgressUpdate}
      >
      {renderContent()}
    </div>
  );
};

// interface RepurposingMenuOptionProps {
//     option?: PostProcessedVideoEntry;
//     optionType?: string;
//     taskID?: string;
//   }

//   const RepurposingMenuOption = ({ option, optionType, taskID }: RepurposingMenuOptionProps) => {
//     const [hover, setHover] = useState(false);
//     const [progress, setProgress] = useState(0);
//     const [showPercentage, setShowPercentage] = useState(false);
//     const [childProgress, setChildProgress] = useState(0);
//     const [initialProgressFetched, setInitialProgressFetched] = useState(false);
//     const [lastEmittedProgress, setLastEmittedProgress] = useState(0);

//     useEffect(() => {
//         if (!taskID) return;
    
//         const setInitialProgress = async () => {
//           if (!initialProgressFetched) {
//             await fetchProgress();
//             setInitialProgressFetched(true);
//             // setShowPercentage(true);
//           }
//         };
    
//         const handleRealTimeUpdate = (data: any) => {
//           if (data.entryID === taskID) {
//             console.log(`Received real-time update for ${taskID}:`, data.progress);
//             setProgress(data.progress);
//             setChildProgress(data.progress);
//           }
//         };
    
//         const fetchAndUpdateProgress = async () => {
//           if (childProgress > 0) {
//             emitProgressUpdate(taskID!, childProgress);
//           }
//         };
    
//         setInitialProgress();
//         socket.on('real-time-update', handleRealTimeUpdate);
//         const interval = setInterval(fetchProgress, 10000);
//         const emitUpdatesInterval = setInterval(fetchAndUpdateProgress, 5000);
    
//         return () => {
//           socket.off('real-time-update', handleRealTimeUpdate);
//           clearInterval(interval);
//           clearInterval(emitUpdatesInterval);
//         };
//       }, [taskID, childProgress]);
  
//     const fetchProgress = async () => {
//       if (!taskID || progress === 1) return;
//       const response = await fetch(
//         `https://latte-real-time-updates-dwr7snkjfq-ew.a.run.app/progress/${taskID}`
//       );
//       const data = await response.json();
//       if (data.progress && data.progress !== progress) {
//         if (data.progress > progress) {
//             console.log(`🥳 Fetched initial progress: ${data.progress}`);
//             setProgress(data.progress);
//             setChildProgress(data.progress);
//         }
//       }
//     };

  
//       useEffect(() => {
//           if (!taskID) return;
      
//           const fetchAndUpdateProgress = async () => {
//             // Set a threshold for progress updates, e.g. 5% difference
//             const progressUpdateThreshold = 1;
      
//             if (childProgress > 0 && Math.abs(childProgress - lastEmittedProgress) >= progressUpdateThreshold) {
//               // console.log(`Child progress: ${childProgress}`);
//               emitProgressUpdate(taskID!, childProgress);
//               setLastEmittedProgress(childProgress);
//             }
//           };
      
//           // Set up the interval to emit updates periodically
//           const emitUpdatesInterval = setInterval(fetchAndUpdateProgress, 5000);
      
//           // Clean up the interval when the component is unmounted or taskID changes
//           return () => {
//             clearInterval(emitUpdatesInterval);
//           };
//         }, [taskID, childProgress, lastEmittedProgress]);

//     useEffect(() => {
//       if (!initialProgressFetched) return;
//       setShowPercentage(true);
//     }, [initialProgressFetched]);

//     const safeEmitProgressUpdate = () => {
//         // check if abs(childProgress - lastEmittedProgress) >= 1
//         // if so, emit
//         if (!taskID) return;
//         console.log(`Child progress: ${childProgress}`);

//         if (Math.abs(childProgress - lastEmittedProgress) >= 0.01) {
//             emitProgressUpdate(taskID!, childProgress);
//             setLastEmittedProgress(childProgress);
//         }
//     }

//     const childProgressConverter = (childProgress: number) => {
//       return (childProgress * 100).toFixed(0);
//     };
  
//     const renderContent = () => {
//         if (option) {
//           return <h1>{option.type}</h1>;
//         } else if (showPercentage && optionType) {
//           // Render the percentage only if showPercentage is true
//           return (
//             <>
//               <h1 key={`${optionType}-${childProgressConverter(childProgress)}`}>
//                 {optionType} {childProgressConverter(childProgress)}%
//               </h1>
//               <div
//                 style={{
//                   width: 0,
//                   height: 0,
//                   overflow: "hidden",
//                   visibility: "hidden",
//                 }}
//               >
//                 <TimedProgressBar progress={progress} setParentProgress={setChildProgress} timeToCompletion={
//                     optionType.includes("Vertical") ? 480 : 300
//                 } />
//               </div>
//             </>
//           );
//         } else {
//           return <h1>{optionType}</h1>;
//         }
//       };
  
//     return (
//       <div
//         className="w-full font-poppins cursor-pointer text-sm hover:scale-105 duration-200 flex items-center justify-center"
//         key={optionType ? optionType : `${option?.type}-${progress}`}
//         onMouseLeave={safeEmitProgressUpdate}
//         >
//         {renderContent()}
//       </div>
//     );
// };
  

export default VariantsMenuModal;