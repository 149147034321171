import React, { useRef, useEffect, useState, ChangeEventHandler, FormEventHandler } from "react"

export interface GeneralUpgradeModalProps {
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    buttonText?: string;
    buttonLink?: string;
    message?: string;
    buttonClickFunction?: () => void;
    style?: React.CSSProperties;
    className?: string;
}

export const GeneralUpgradeModal = (
    { 
        isOpen, 
        onClose, 
        title,
        message,
        buttonText,
        buttonLink,
        buttonClickFunction,
        style,
        className
    }: GeneralUpgradeModalProps) => {

        const modalHasButton = buttonText && (buttonLink || buttonClickFunction)

    const defaultClassName = "fixed z-100 mx-auto my-auto inset-0 min-h-28 max-h-52 flex p-4 shadow-lg w-[500px] rounded-2xl bg-white border-0 border-[#ffd9c1] h-auto"


    return (
        <div 
            className = {className ? className : defaultClassName}
            style={
                {
                    ...style, // Merge the passed styles with the existing ones
                    display: isOpen ? "flex" : "none"
                }
        }>
            <div className="flex flex-col space-y-3 items-center justify-center h-full w-full bg-white">
                <h1 className="font-poppins font-semibold text-2xl text-center">
                    {title}
                </h1>
                <p className="font-poppins font-normal text-sm text-center">
                    {message}
                </p>
                {modalHasButton &&
                    <button className="font-poppins bg-main-gradient bg-cover rounded-2xl px-4 py-2"
                    onClick={() => {
                        // if buttonClickFunction is defined, call it
                        // otherwise, open the buttonLink in a new tab
                        buttonClickFunction ? buttonClickFunction() : window.open(buttonLink, "_self")
                    }}>
                        {buttonText}
                    </button>
                }
            </div>
            <button className="absolute top-0 right-0 m-4 font-poppins"
                onClick={onClose}>
                Close
            </button>
        </div>
    )
}