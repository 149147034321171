export interface VideoHistoryObject {
    entryID: string;
    thumbnailFilename: string;
    title: string;
    creationTimestamp: number;
    flowType?: string;
    repurposedVideos?: VideoEntry[];
    repurposedVideosUncut: QueryBasedVideoEntry[] | undefined;
}

export interface UserAccount {
    email: string,
    plan: string,
    minutesUploadedThisMonth: number,
    maxUploadMinutes: number,
    clipsGeneratedThisMonth: number,
    maxClipsGenerated: number,
    numClipsToReturn: number,
    videoHistory: VideoHistoryObject[],
    pendingVideosToProcess: string[],
    subscriptionExpiryDate: number,
    requiresUpgrade: boolean,
    clipsMeteredSubscriptionID?: string | null | undefined,
    minutesMeteredSubscriptionID?: string | null | undefined,
    inputVideoMinutesUsed: number,
    inputVideoMinutesAllowance: number,
    creditsUsed: number,
    creditsAllowance: number,
}

export interface VideoEntry {
    videoURL: string;
    caption: string;
    videoID: string;
    thumbnailURL?: string;
    postProcessedVideos?: OldPostProcessedVideoEntry[];
}

export interface OldPostProcessedVideoEntry {
    videoURL: string;
    postProcessingApplied: string[];
    thumbnailURL?: string;
}

export interface PostProcessingRequest {
    clipID: string;
    taskSent: boolean;
}

export interface QueryRequest {
    entryID: string;
    query: string;
    desiredDuration: number;
    numClipsToReturn: number;
}

export interface PostProcessedVideoEntry {
    type: string;
    filename: string;
}

export interface QueryBasedVideoEntry {
    title: string;
    caption: string;
    videoID: string;
    summary: string;
    startTime: number;
    endTime: number;
    transcript: any;
    suggestedQuery: string;
    postProcessedVideos?: PostProcessedVideoEntry[] | undefined;
    isPublic: boolean | undefined;
    queriesLeft: number | undefined;
    email?: string | undefined;
    entryID?: string | undefined;
}

export interface PriceInfo {
    price: number;
    id: string;
    name: string;
    benefits: string[];
    buttonText: string;
    img: string;
    checkoutURL: string;
    addMostPopular?: boolean;
    unReducedPrice?: number;
    couponCode?: string;
  }

export const ListeningEndpoints = {
    InitialProcessing: "query-based-processing-updates",
    NewClipsReady: "query-based-results-updates",
    ClipTrimmed: "query-based-trimming-updates"
}

export interface IVideoVariant {
    filename: string;
    variant: string[];
}

export interface IVideoMetadataEntry {
    videoID: string;
    title: string;
    caption: string;
    variants: IVideoVariant[];
    startTime: number;
    endTime: number;
    subtitleSequencesLandscape?: any[];
    subtitleSequencesPortrait?: any[];
    subtitleSequencesPortraitMagic?: any[];
}