import React, { useRef, useEffect, useState, ChangeEventHandler, FormEventHandler } from "react"
import { DownloadButton } from "../pages/ResultsPage";

interface PostProcessedVideoModalProps {
    videoURL: string;
    isOpen: boolean;
    onClose: () => void;
    userRequiresUpgrade: boolean;
}

export const PostProcessedVideoModal = ({ videoURL, isOpen, onClose, userRequiresUpgrade }: PostProcessedVideoModalProps) => {
    let [videoToPlay, setVideoToPlay] = useState(videoURL)

    return (
        <div className="fixed inset-0 mx-auto my-auto z-20 overflow-y-auto h-96 w-96 rounded-2xl shadow-lg bg-white"
        style={
            isOpen ? { display: "flex" } : { display: "none" }
        }>
            <div className="flex flex-col items-center justify-center h-full w-full bg-white space-y-3">
                <video className='w-full h-80 rounded-2xl'
                controls={true}
                playsInline={true}
                autoPlay={true}
                preload="metadata"
                controlsList="nodownload"
                onContextMenu={(e) => e.preventDefault()}>
                    <source src={`${videoToPlay}`} type="video/mp4" />
                </video>
                <DownloadButton downloadURL={videoToPlay} userRequiresUpgrade={userRequiresUpgrade}/>
            </div>
            <button className="absolute top-0 right-0 m-4 font-poppins"
            onClick={onClose}>
                Close
            </button>
        </div>
    )
}