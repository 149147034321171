import React, { useState, useEffect, FormEventHandler, useRef } from 'react';
import { TbSend } from "react-icons/tb"

interface EmailFormProps {
    onEmailSubmit: (email: string) => void;
}

const EmailForm = ({ onEmailSubmit }: EmailFormProps) => {
    const [email, setEmail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [textValue, setTextValue] = useState("");

    const inputRef = useRef<HTMLInputElement>(null);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTextValue(e.target.value);
        const userInputString = e.target.value;
        const emailRegex = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/g;
        const replacedString = userInputString.replace(emailRegex, "");

        if (replacedString == "" && emailRegex.test(userInputString)) {
            setEmail(userInputString.toLowerCase());
            setIsValidEmail(true);
            console.log("VALID")
        } else {
            setIsValidEmail(false);
            console.log("INVALID")
        }
    }

    const handleDoneButtonClick: FormEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        onEmailSubmit(email);
        setTextValue("");
    }

    const handleEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && isValidEmail) {
            onEmailSubmit(email);
            setTextValue("");
        }
    }

    useEffect(() => {
        if (inputRef.current != null) {
            inputRef.current.focus();
        }
    })

    return (
        <div className="bg-white flex flex-row w-full h-10 border-0 border-[#f3efef] shadow-sm focus-within:border-2 focus-within:shadow-none rounded-2xl px-5 items-center justify-center">
            <input
                type="email"
                className="w-full h-full text-center font-poppins bg-transparent text-[12px] text-[#545454] rounded-xl outline-none"
                placeholder="Email"
                onChange={handleEmailChange}
                onKeyUp={handleEnterKeyPress}
                value={textValue}
                ref={inputRef}>
            </input>
            {
                isValidEmail &&
                <button className="w-6 h-6 bg-transparent z-50"
                    onClick={handleDoneButtonClick}>
                    <TbSend />
                </button>
            }

        </div>
    )
}

export default EmailForm;