import React from 'react';
import logo from './logo.svg';
import './App.css';
import SignUpPage from './pages/SignUp';
import VideoBetaPage from './pages/VideoBeta';
import InternalVideoDownloaderPage from './pages/InternalVideoDownloader';
import ResultsPage from './pages/ResultsPage';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import VideoHomePage from './VideoHome/VideoHomePage';
import { SubtitleSequenceEditComponent } from './pages/SubtitleSequenceEditPage';
import QueryResultsPage from './ResultsPages/QueryResultsPage/QueryResultsPage';
import { VideoUploadMode } from './Types/Enums';
import { TrimSlider, TrimVideoContainer } from './CustomReactTags/CustomSlider/CustomSlider';
import { videoSubscriptionMonthlyPrices, videoSubscriptionYearlyPrices, videoUpgradePageHeader } from './DataObjects/PricingData';
import V2MigrationPage from './pages/V2Migration/V2MigrationPage';

import LoadingNotificationModal from './Components/EssentialComponents/LoadingNotificationModal';
import socket from './socket';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<VideoHomePage uploadMode={VideoUploadMode.Autopilot} />} />
        {/* <Route path="/getStarted" element={<SignUpPage dataToDisplay={subscriptionPrices} pageHeader={subscriptionPageHeader} showTryFreeButton={true} />} />
        <Route path="/images" element={<SignUpPage dataToDisplay={imageCreditPrices} pageHeader={imageCreditPageHeader} showTryFreeButton={false} />} /> */}
        <Route path="/video" element={<VideoHomePage uploadMode={VideoUploadMode.Autopilot} />} />
        <Route path="/video/upgrade" element={<SignUpPage monthlyPrices={videoSubscriptionMonthlyPrices} yearlyPrices={videoSubscriptionYearlyPrices} pageHeader={videoUpgradePageHeader} showTryFreeButton={false} />} />
        <Route path="/video/verify" element={<VideoHomePage uploadMode={VideoUploadMode.Autopilot} />} />
        <Route path="/internal-video-download" element={<InternalVideoDownloaderPage />} />
        <Route path="/video-snippets" element={<ResultsPage />} />
        <Route path="/video-v2" element={<VideoHomePage uploadMode={VideoUploadMode.Advanced} />} />
        <Route path="/video-v2/verify" element={<VideoHomePage uploadMode={VideoUploadMode.Advanced} />} />
        <Route path="/video-advanced" element={<QueryResultsPage />} />
        <Route path="/v2-migration" element={<V2MigrationPage />} />
      </Routes>
    </Router>
  );
}

export default App;