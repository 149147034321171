import React, { useState, useEffect, useContext } from "react";
import VideoPostProcessor from "../../HelperClasses/Networking/VideoPostProcessor";
import LoadingNotificationModal from "../../Components/EssentialComponents/LoadingNotificationModal";
import { VideoOrSummaryContainerDisplay } from "../../Types/Enums";
import { QueryBasedVideoEntry } from "../../Types/DataTypes";
import MixpanelTracker from "../../HelperClasses/Analytics/MixpanelTracker";
import CurrentlyViewingContext from "../../ContextManagers/CurrentlyViewingContext";


interface RepurposingMenuModalProps {
    setIsOpen: (isOpen: boolean) => void;
    entry: QueryBasedVideoEntry;
    entryID: string | null;
    setShowLoadingModal: (showLoadingModal: boolean) => void;
    setVideosArePostProcessing: (videosArePostProcessing: boolean) => void;
    setVideoContainerDisplay: (displayVideoOrSummary: VideoOrSummaryContainerDisplay) => void;
}

const RepurposingMenuModal = ({ setIsOpen, entry, entryID, setShowLoadingModal, setVideosArePostProcessing, setVideoContainerDisplay }: RepurposingMenuModalProps) => {
    const [repurposingIndicesSelected, setRepurposingIndicesSelected] = useState<number[]>([])
    const [isSendingVideoForRepurposing, setIsSendingVideoForRepurposing] = useState<boolean>(false);

    const {
        currentlyViewingVideoID,
        setCurrentlyViewingVideoID,
        currentlyViewingVariantType,
        setCurrentlyViewingVariantType,
        currentlyViewingVideoMetadataEntry,
      } = useContext(CurrentlyViewingContext);
    const trimDurationButtonText = "Trim duration ✂️";
    const editSubtitlesButtonText = "Edit subtitles ✍️";
    const options = [trimDurationButtonText];

    const [repurposingOptions, setRepurposingOptions] = useState<string[]>(options);
    const repurposingOptionsKeys = [["vertical"], ["subtitles"], ["vertical", "subtitles"]];

    useEffect(() => {
        if (entry.postProcessedVideos && entry.postProcessedVideos.find(video => video.type.includes("Subtitles"))) {

            setRepurposingOptions(repurposingOptions.concat(editSubtitlesButtonText));

        }
    }, [])

    useEffect(() => {
        // Check if subtitleSequencesLandscape exists
        if (!currentlyViewingVariantType) return;
        // if following conditions are met:
        // currentlyViewingVariantType has "subtitles" and subtitleSequencesLandscape exist
        // OR
        // currentlyViewingVariantType has "vertical" and "subtitles" and subtitleSequencesPortrait exist
        // OR
        // currentlyViewingVariantType has "magic-subtitles" and subtitleSequencesPortraitMagic exist
        // then add "Edit subtitles" to repurposing options

        if (
            (currentlyViewingVariantType.includes("subtitles") && currentlyViewingVideoMetadataEntry?.subtitleSequencesLandscape) ||
            (currentlyViewingVariantType.includes("vertical") && currentlyViewingVariantType.includes("subtitles") && currentlyViewingVideoMetadataEntry?.subtitleSequencesPortrait) ||
            (currentlyViewingVariantType.includes("magic-subtitles") && currentlyViewingVideoMetadataEntry?.subtitleSequencesPortraitMagic)
        ) {
            if (!repurposingOptions.includes(editSubtitlesButtonText)) {
                setRepurposingOptions(repurposingOptions.concat(editSubtitlesButtonText));
            }
        }
        
    }, [currentlyViewingVideoID, currentlyViewingVariantType])

    const postProcessor = new VideoPostProcessor();
    const mixpanelTracker = new MixpanelTracker();

    const handleRepurposingOptionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, repurposingOptionIndex: number) => {
        e.preventDefault();

        console.log("repurposingOptionIndex", repurposingOptionsKeys[repurposingOptionIndex])


        if (repurposingOptions[repurposingOptionIndex] === trimDurationButtonText) {

            console.log("Trim duration")
            setVideoContainerDisplay(VideoOrSummaryContainerDisplay.EditDuration);
            setIsOpen(false);
            return;
        }


        if (repurposingOptions[repurposingOptionIndex] === editSubtitlesButtonText) {

            console.log("Edit subtitles")
            setVideoContainerDisplay(VideoOrSummaryContainerDisplay.EditSubtitles);
            setIsOpen(false);
            return;
        }

        if (repurposingIndicesSelected.includes(repurposingOptionIndex)) {
            setRepurposingIndicesSelected(repurposingIndicesSelected.filter(index => index !== repurposingOptionIndex));
            console.log(repurposingIndicesSelected, "repurposingIndicesSelected")
            console.log("removed")
        } else {
            const newArray = [...repurposingIndicesSelected];
            setRepurposingIndicesSelected(newArray.concat(repurposingOptionIndex));
        }
        setIsOpen(false);
        console.log("repurposingIndicesSelected", repurposingIndicesSelected)
    }

    const sendVideoForRepurposing = async () => {
        setIsSendingVideoForRepurposing(true);
        setShowLoadingModal(true);
        setVideosArePostProcessing(true);

        for (let i = 0; i < repurposingIndicesSelected.length; i++) {
            console.log("repurposingIndicesSelected", repurposingIndicesSelected[i])
            const key = repurposingIndicesSelected[i];
            console.log(repurposingOptionsKeys[key], "Repurposing Option to send")
            postProcessor.sendVideoToPostProcessor(entryID, entry.videoID, repurposingOptionsKeys[key]);
            mixpanelTracker.track("🪄 Repurposing Video", 
                localStorage.getItem("email"),
                {  "entryID": entryID, "videoID": entry.videoID, "repurposingInstructions": repurposingOptionsKeys[key] }
            );
        }

        setIsOpen(false);
    }

    const handleMouseLeave = () => {
        setIsOpen(false);
    }

    return (
        <div className="z-50 absolute rounded-xl bg-white top-0 left-0 px-3 py-3 shadow-xl shadow-[#ffe7d5] flex flex-col absolute top-1/2 transform -translate-y-1/2"
        onMouseLeave={handleMouseLeave}>
            <div className="flex flex-col justify-between items-center space-y-2 pt-2 pb-2">
                {
                    repurposingOptions?.map((repurposingOption, index) => {
                        return (
                            <div
                                className="justify-between flex items-center space-y-2 flex-col"
                                key={index}
                                onClick={(e) => handleRepurposingOptionClick(e, index)}>
                                <RepurposingMenuOption optionName={repurposingOption} />
                                { // add divider if not last option
                                    index !== repurposingOptions.length - 1 &&
                                    <div className="p-1 w-full">
                                        <div className="w-full h-[1px] bg-gray-100"></div>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
                {
                    repurposingIndicesSelected.length > 0 &&
                    <div className="w-full font-poppins font-semibold cursor-pointer text-sm text-center hover:scale-105 duration-200 flex flex-col space-y-2 justify-center items-center">
                        <div className="w-full h-[1px] bg-gray-100"></div>
                        <button onClick={sendVideoForRepurposing}>
                            Repurpose 🪄
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}

interface RepurposingMenuOptionProps {
    optionName: string;
}

const RepurposingMenuOption = ({ optionName }: RepurposingMenuOptionProps) => {
    const [selected, setSelected] = useState(false);

    const handleOptionClick = () => {
        setSelected(!selected);
    }

    return (
        <div className="h-4 w-full font-poppins cursor-pointer text-sm hover:scale-105 duration-200 px-4"
            onClick={handleOptionClick}
            style={{ color: selected ? "#8adb98" : "#333333" }}>
            <h1>{optionName}</h1>
        </div>
    )
}

export default RepurposingMenuModal;