import React, { useEffect, useState } from 'react'

interface LoadingBarProps {
    progress: number;
}

export const LoadingBar = ({ progress }: LoadingBarProps) => {
    const [showPercentage, setShowPercentage] = useState(progress);

    useEffect(() => {
        setShowPercentage(progress);
    }, [progress])

    return (
        <div className="w-full h-4 bg-gray-200 rounded-full">
            <div
                className="h-full text-center text-xs text-white bg-new-gradient bg-cover rounded-full flex items-center px-1 loading-bar"
                
                style={{
                    width: `${progress}%`,    
                }}>
                {
                    showPercentage &&
                    <h1 className="h-full text-center text-xs text-white m-auto flex items-center">
                        {Math.round(progress)}%
                    </h1>
                }
            </div>
        </div>
    )
}

export default LoadingBar