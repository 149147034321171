import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import RepurposingMenuModal from "./RepurposingMenuModal";
import VariantsMenuModal from "./VariantsMenuModal";
import LoadingNotificationModal from "../../Components/EssentialComponents/LoadingNotificationModal";

import { VideoOrSummaryContainerDisplay } from "../../Types/Enums";
import { QueryBasedVideoEntry } from "../../Types/DataTypes";
import { PostProcessedVideoEntry } from "../../Types/DataTypes";

interface SnippetOptionsToolbarProps {
    videoContainerDisplay: VideoOrSummaryContainerDisplay;
    snippet: QueryBasedVideoEntry;
    entryID: string | null;
    currentlyViewingSnippetIndex: number;
    snippetsCount: number;
    setDisplayVideoOrSummary: (displayVideoOrSummary: VideoOrSummaryContainerDisplay) => void;
    setCurrentlyViewingSnippetIndex: (snippetNumber: number) => void;
    updateVideoToDisplay: (videoURL: string | null) => void; // maybe change to "displayPostProcessedVideoWithURL"
    setVideosArePostProcessing: (videosArePostProcessing: boolean) => void;
    setVideoContainerDisplay: (display: VideoOrSummaryContainerDisplay) => void;
    setShouldSendRequestToUpdateDuration: (shouldSend: boolean) => void;
    setShouldSendRequestToUpdateSubtitles: (shouldSend: boolean) => void;
    setCurrentlyViewingVideoOrientation: (orientation: string) => void;
    setShowMagicSubtitlesUpgradeModal: (showMagicSubtitlesUpgradeModal: boolean) => void;
}

const SnippetOptionsToolbar = ({
    videoContainerDisplay,
    snippet,
    entryID,
    currentlyViewingSnippetIndex,
    snippetsCount,
    setDisplayVideoOrSummary,
    setCurrentlyViewingSnippetIndex,
    updateVideoToDisplay,
    setVideosArePostProcessing,
    setVideoContainerDisplay,
    setShouldSendRequestToUpdateDuration,
    setShouldSendRequestToUpdateSubtitles,
    setCurrentlyViewingVideoOrientation,
    setShowMagicSubtitlesUpgradeModal,
}: SnippetOptionsToolbarProps) => {
    return (
        <div className="flex flex-row bg-white rounded-3xl px-16 py-3 z-50 relative items-center justify-center shadow-sm">
            <PreviousButton
                currentlyViewingSnippetIndex={currentlyViewingSnippetIndex}
                setCurrentlyViewingSnippetIndex={setCurrentlyViewingSnippetIndex}
                snippetsCount={snippetsCount}
                updateVideoToDisplay={updateVideoToDisplay}
                setVideoContainerDisplay={setVideoContainerDisplay} />
            <div className="flex flex-row space-x-2">
                <EditButton
                    snippet={snippet}
                    setShowLoadingModal={setVideosArePostProcessing}
                    setVideosArePostProcessing={setVideosArePostProcessing}
                    entryID={entryID}
                    setVideoContainerDisplay={setVideoContainerDisplay}
                    videoContainerDisplay={videoContainerDisplay}
                    setShouldSendRequestToUpdateDuration={setShouldSendRequestToUpdateDuration}
                    setShouldSendRequestToUpdateSubtitles={setShouldSendRequestToUpdateSubtitles} />
                <VariantsButton 
                    snippet={snippet} 
                    setDisplayVideoOrSummary={setDisplayVideoOrSummary} 
                    updateVideoToDisplay={updateVideoToDisplay} 
                    setCurrentlyViewingVideoOrientation={setCurrentlyViewingVideoOrientation} 
                    setShowMagicSubtitlesUpgradeModal={setShowMagicSubtitlesUpgradeModal}
                     />
            </div>
            <NextButton currentlyViewingSnippetIndex={currentlyViewingSnippetIndex} setCurrentlyViewingSnippetIndex={setCurrentlyViewingSnippetIndex} snippetsCount={snippetsCount} updateVideoToDisplay={updateVideoToDisplay} setVideoContainerDisplay={setVideoContainerDisplay} />
        </div>
    )
}

interface ToggleVideoOrSummaryButtonProps {
    videoOrSummary: VideoOrSummaryContainerDisplay;
    setDisplayVideoOrSummary: (displayVideoOrSummary: VideoOrSummaryContainerDisplay) => void;
    snippetsCount: number;
}

interface EditButtonProps {
    snippet: QueryBasedVideoEntry;
    setShowLoadingModal: (showLoadingModal: boolean) => void;
    setVideosArePostProcessing: (videosArePostProcessing: boolean) => void;
    entryID: string | null;
    setVideoContainerDisplay: (displayVideoOrSummary: VideoOrSummaryContainerDisplay) => void;
    videoContainerDisplay: VideoOrSummaryContainerDisplay;
    setShouldSendRequestToUpdateDuration: (shouldSend: boolean) => void;
    setShouldSendRequestToUpdateSubtitles: (shouldSend: boolean) => void;
}

const EditButton = ({ snippet, setShowLoadingModal, setVideosArePostProcessing, entryID, setVideoContainerDisplay, videoContainerDisplay, setShouldSendRequestToUpdateDuration, setShouldSendRequestToUpdateSubtitles }: EditButtonProps) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const handleClick = () => {

        setVideoContainerDisplay(VideoOrSummaryContainerDisplay.Video);

        // setModalOpen(true);
        if (videoContainerDisplay === VideoOrSummaryContainerDisplay.EditDuration) {
            setShouldSendRequestToUpdateDuration(true);
        } else if (videoContainerDisplay === VideoOrSummaryContainerDisplay.EditSubtitles) {
            setShouldSendRequestToUpdateSubtitles(true);
        } else {
            setModalOpen(true);
        }
    }

    return (
        <div>
            <button
                className="w-20 relative py-2 bg-cover rounded-2xl bg-[#f2efee] text-center font-poppins text-xs font-medium text-[#333333] hover:scale-110 duration-300 "
                onClick={handleClick}>
                {videoContainerDisplay === VideoOrSummaryContainerDisplay.EditDuration || videoContainerDisplay === VideoOrSummaryContainerDisplay.EditSubtitles ? "BACK" : "EDIT"}
            </button>
            {
                modalOpen &&
                <RepurposingMenuModal entry={snippet} entryID={entryID} setIsOpen={setModalOpen} setShowLoadingModal={setShowLoadingModal} setVideosArePostProcessing={setVideosArePostProcessing} setVideoContainerDisplay={setVideoContainerDisplay}/>
            }
        </div>
    )
}

interface VariantsButtonProps {
    snippet: QueryBasedVideoEntry | undefined;
    setDisplayVideoOrSummary: (displayVideoOrSummary: VideoOrSummaryContainerDisplay) => void;
    updateVideoToDisplay: (videoURL: string | null) => void;
    setCurrentlyViewingVideoOrientation: (orientation: string) => void;
    setShowMagicSubtitlesUpgradeModal: (showMagicSubtitlesUpgradeModal: boolean) => void;
}

const VariantsButton = ({ 
    snippet, 
    setDisplayVideoOrSummary, 
    updateVideoToDisplay, 
    setCurrentlyViewingVideoOrientation,
    setShowMagicSubtitlesUpgradeModal,
}: VariantsButtonProps) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [labelText, setLabelText] = useState<string>("SUMMARY"); 
    const [variants, setVariants] = useState<PostProcessedVideoEntry[]>([]);
    const [displayVideoOrSummary, setVideoOrSummaryDisplay] = useState<VideoOrSummaryContainerDisplay>(VideoOrSummaryContainerDisplay.Video);
    const [breatheAnimation, setBreatheAnimation] = useState<boolean>(false);

    const handleClick = () => {
        // If there are no variants, don't open the modal and display the summary instead
        // if (variants.length === 0 && displayVideoOrSummary === VideoOrSummaryContainerDisplay.Video) {
        //     setVideoOrSummaryDisplay(VideoOrSummaryContainerDisplay.Summary);
        //     setDisplayVideoOrSummary(VideoOrSummaryContainerDisplay.Summary);
        //     setLabelText("VIDEO");
        //     return;
        // }

        // // If there are no variants, don't open the modal and display the video instead
        // if (variants.length === 0 && displayVideoOrSummary === VideoOrSummaryContainerDisplay.Summary) {
        //     setVideoOrSummaryDisplay(VideoOrSummaryContainerDisplay.Video);
        //     setDisplayVideoOrSummary(VideoOrSummaryContainerDisplay.Video);
        //     setLabelText("SUMMARY");
        //     return;
        // }

        setLabelText("VERSIONS");

        setModalOpen(true);
    }

    useEffect(() => {
        // if (snippet?.postProcessedVideos === undefined || snippet?.postProcessedVideos.length === 0) {
        //     setLabelText("SUMMARY");
        // } else {
        setLabelText("VERSIONS");
        snippet?.postProcessedVideos && setVariants(snippet.postProcessedVideos);
    }, [snippet])

    useEffect(() => {
        if (variants.length !== 0) {
            // turn on the breathe animation
            setBreatheAnimation(true);
        }
    }, [variants])

    return (
        <div>
            <button
                className={`w-20 relative py-2 bg-cover rounded-2xl bg-[#f2efee] text-center font-poppins text-xs font-medium text-[#333333] hover:scale-110 duration-300 ${breatheAnimation ? 'breathing-animation' : ''}`}
                // className="w-20 relative py-2 bg-cover rounded-2xl bg-[#f2efee] text-center font-poppins text-xs font-medium text-[#333333] hover:scale-110 duration-300 shine-animation"
                onClick={handleClick}>
                {labelText}
            </button>
            {
                modalOpen &&
                <VariantsMenuModal 
                    videoEntry={snippet} 
                    updateVideoToDisplay={updateVideoToDisplay} 
                    setIsOpen={setModalOpen} 
                    setVideoOrSummary={setDisplayVideoOrSummary} 
                    displayVideoOrSummary={VideoOrSummaryContainerDisplay.Video} 
                    setCurrentlyViewingVideoOrientation={setCurrentlyViewingVideoOrientation}
                    setShowMagicSubtitlesUpgradeModal={setShowMagicSubtitlesUpgradeModal}
                    />
            }
        </div>
    )
}

interface PreviousAndNextButtonProps {
    currentlyViewingSnippetIndex: number;
    setCurrentlyViewingSnippetIndex: (snippetNumber: number) => void;
    snippetsCount: number;
    updateVideoToDisplay: (videoURL: string | null) => void;
    setVideoContainerDisplay: (displayVideoOrSummary: VideoOrSummaryContainerDisplay) => void;
} 

const PreviousButton = ({ currentlyViewingSnippetIndex, setCurrentlyViewingSnippetIndex, snippetsCount, updateVideoToDisplay, setVideoContainerDisplay }: PreviousAndNextButtonProps) => {
    const handleClick = () => {
        if (currentlyViewingSnippetIndex === 0 || snippetsCount === 0) {
            return;
        }

        setCurrentlyViewingSnippetIndex(currentlyViewingSnippetIndex - 1);

        updateVideoToDisplay(null);
        setVideoContainerDisplay(VideoOrSummaryContainerDisplay.Video);
    }

    return (
        <button 
        className="absolute left-4 font-poppins hover:animate-pulse"
        onClick={handleClick}>
            <IoIosArrowBack
            size={24}
            color="#333333"/>
        </button>
    )
}

// NEED TO ADD A CHECK TO SEE IF THE CURRENTLY VIEWING SNIPPET INDEX IS 0 OR THE LAST SNIPPET INDEX
const NextButton = ({ currentlyViewingSnippetIndex, setCurrentlyViewingSnippetIndex, snippetsCount, updateVideoToDisplay, setVideoContainerDisplay }: PreviousAndNextButtonProps) => {
    const handleClick = () => {
        if (currentlyViewingSnippetIndex === snippetsCount - 1 || snippetsCount === 0) {
            return;
        }

        setCurrentlyViewingSnippetIndex(currentlyViewingSnippetIndex + 1);

        updateVideoToDisplay(null);
        setVideoContainerDisplay(VideoOrSummaryContainerDisplay.Video);
    }

    return (
        <button
        className="absolute right-4 font-poppins hover:animate-pulse"
        onClick={handleClick}>
            <IoIosArrowForward
            size={24}
            color="#333333"/>
        </button>
    )
}

export default SnippetOptionsToolbar;

// const ToggleVideoOrSummaryButton = ({ videoOrSummary, setDisplayVideoOrSummary, snippetsCount }: ToggleVideoOrSummaryButtonProps) => {
//     const handleClick = () => {
//         if (snippetsCount === 0) {
//             return;
//         }

//         let newVideoOrSummary: VideoOrSummaryContainerDisplay;

//         if (videoOrSummary === VideoOrSummaryContainerDisplay.Video) {
//             newVideoOrSummary = VideoOrSummaryContainerDisplay.Summary;
//         } else {
//             newVideoOrSummary = VideoOrSummaryContainerDisplay.Video;
//         }

//         setDisplayVideoOrSummary(newVideoOrSummary);
//     }

//     return (
//         <button
//             className="px-5 py-2 bg-cover rounded-2xl bg-[#f2efee] text-center font-poppins text-xs font-medium text-[#333333] hover:scale-110 duration-300"
//             onClick={handleClick}>
//             {videoOrSummary === VideoOrSummaryContainerDisplay.Video ? "SUMMARY" : "VIDEO"}
//         </button>
//     )
// }