import { PriceInfo} from "../Types/DataTypes";

export const videoUpgradePageHeader = "Flexible Plans"

const videoCheckoutURL = "https://latte-payment-server-qo2mn26dqq-ew.a.run.app/video/create-checkout-session"

export const videoSubscriptionMonthlyPrices: PriceInfo[] = [
  { price: 20, id: "price_1MugNBActtX7chRw2PnlkARl", name: "Starter", benefits: ["80% off your first month", "25 clips per month", "4 Magic Subtitles", "25 subtitled clips", "25 vertical clips", "Fine tune video duration", "Edit subtitles", "Customer support through Discord", "150 upload minutes", "Additional usage-based minutes charged at $0.08 per additional minute", "Additional usage-based clips charged at $0.08 per additional clip"], buttonText: "Start free trial", img: "price-image-free", checkoutURL: videoCheckoutURL, couponCode: "starter_first_month" },
  { price: 50, id: "price_1MugNrActtX7chRweQcNMiLo", name: "Marketer", benefits: ["Unlimited clips", "20 Magic Subtitles", "Unlimited subtitled clips", "Unlimited vertical clips", "Fine tune video duration", "Edit subtitles", "Priority customer support", "600 upload minutes", "3GB max upload size", "Additional usage-based minutes charged at $0.07 per additional minute"], buttonText: "Start free trial", img: "price-image-standard", checkoutURL: videoCheckoutURL, addMostPopular: true },
  { price: 100, id: "price_1MugO9ActtX7chRwXv7mC8yE", name: "Pro", benefits: ["Unlimited clips", "50 Magic Subtitles", "Unlimited subtitled clips", "Unlimited vertical clips", "Fine tune video duration", "Edit subtitles", "Guided set up", "Priority customer support", "1200 upload minutes", "6GB max upload size", "Additional usage-based minutes charged at $0.05 per additional minute"], buttonText: "Start free trial", img: "price-image-autopilot", checkoutURL: videoCheckoutURL }
]

export const videoSubscriptionYearlyPrices: PriceInfo[] = [
    { price: 180, id: "price_1MugXmActtX7chRwfvrxeNV2", name: "Starter", benefits: ["25 clips per month", "4 Magic Subtitles", "25 subtitled clips", "25 vertical clips", "Fine tune video duration", "Edit subtitles", "Customer support through Discord", "150 upload minutes", "Additional usage-based minutes charged at $0.08 per additional minute", "Additional usage-based clips charged at $0.08 per additional clip"], buttonText: "Start free trial", img: "price-image-free", checkoutURL: videoCheckoutURL, unReducedPrice: 240 },
    { price: 450, id: "price_1MugYbActtX7chRwMUoXzFDx", name: "Marketer", benefits: ["Unlimited clips", "20 Magic Subtitles", "Unlimited subtitled clips", "Unlimited vertical clips", "Fine tune video duration", "Edit subtitles", "Priority customer support", "600 upload minutes", "3GB max upload size", "Additional usage-based minutes charged at $0.07 per additional minute"], buttonText: "Start free trial", img: "price-image-standard", checkoutURL: videoCheckoutURL, addMostPopular: true, unReducedPrice: 600 },
    { price: 900, id: "price_1MugZEActtX7chRww9evyj5q", name: "Pro", benefits: ["Unlimited clips", "50 Magic Subtitles", "Unlimited subtitled clips", "Unlimited vertical clips", "Fine tune video duration", "Edit subtitles", "Guided set up", "Priority customer support", "1200 upload minutes", "6GB max upload size", "Additional usage-based minutes charged at $0.05 per additional minute"], buttonText: "Start free trial", img: "price-image-autopilot", checkoutURL: videoCheckoutURL, unReducedPrice: 1200 }
]