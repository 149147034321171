import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

const InternalVideoDownloaderPage = () => {
    let [queryParams, setQueryParams] = useSearchParams();
    const [videoUrl, setVideoUrl] = useState<string>("");

    const videoId = queryParams.get("videoId") as string;

    useEffect(() => {
        axios.post(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/internal-video-download?videoName=${videoId}`)
            .then((response) => {
                axios.get(response.data.url[0], {
                    headers: {
                        "Content-Type": "video/mp4",
                    },
                    responseType: "blob"
                })
                    .then((response) => {
                        const blob = new Blob([response.data], { type: "video/mp4" });
                        const url = window.URL.createObjectURL(blob);
                        const hiddenDownloadLink = document.createElement("a");
                        hiddenDownloadLink.href = url;
                        hiddenDownloadLink.download = `${videoId}.mp4`;
                        hiddenDownloadLink.click();
                    })
            })
    })

    return (
        <div className="relative flex flex-col min-h-screen bg-[#fffbf9] m-auto p-6 items-center space-y-10 mt-20">
        </div>
    )
}

export default InternalVideoDownloaderPage;