import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

import { VideoEntry, QueryBasedVideoEntry, IVideoMetadataEntry } from "../../Types/DataTypes";
import { ListeningEndpoints } from "../../Types/DataTypes";

import QueryBasedResultsDownloader from "../../HelperClasses/Networking/QueryBasedResultsDownloader";
import UserManager from "../../HelperClasses/Networking/UserManager";

import MenuBar from "../../Components/EssentialComponents/MenuBar";
import GetMoreClipsButton from "../../Components/ResultsPageComponents/GetMoreClipsButton";
import OriginalVideoContainer from "./OriginalVideoContainer";
import SnippetVideoAndSummaryContainer from "./SnippetVideoAndSummaryContainer";
import { VideoOrSummaryContainerDisplay } from "../../Types/Enums";
import SnippetOptionsToolbar from "./SnippetOptionsToolbar";
import QueryInputContainer from "./QueryInputContainer";
import QueryResultsCompletionListener from "./QueryResultsCompletionListener";
import LoadingNotificationModal from "../../Components/EssentialComponents/LoadingNotificationModal";
import { UpgradeModal } from "../../Modals/UpgradeModal";
import { GeneralUpgradeModal } from "../../Modals/GeneralUpgradeModal";
import LoginOrSignUpModal from "../../Modals/LoginOrSignUpModal";
import PostProcessedCompletionListener from "../../Components/EssentialComponents/PostProcessedCompletionListener";
import MixpanelTracker from "../../HelperClasses/Analytics/MixpanelTracker";
import socket from '../../socket';
import CurrentlyViewingContext from "../../ContextManagers/CurrentlyViewingContext";
import { AutoCloseUpgradeModal } from "../../Modals/AutoCloseModal";
import SomethingWrongButton from "../../Components/EssentialComponents/SomethingWrongButton";
import TopBanner from "../../Components/TopBanner";

const QueryResultsPage = () => {
    const [queryParams, setQueryParams] = useSearchParams();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userRequiresUpgrade, setUserRequiresUpgrade] = useState<boolean>(true);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [entry, setEntry] = useState<QueryBasedVideoEntry | null>(null);
    const [originalVideoURL, setOriginalVideoURL] = useState<string | null>(null);
    const [postProcessedVideoURL, setPostProcessedVideoURL] = useState<string | null>(null);
    const [videoSnippets, setVideoSnippets] = useState<QueryBasedVideoEntry[]>([]);
    const [currentlyViewingSnippetIndex, setCurrentlyViewingSnippetIndex] = useState<number>(0);
    const [displayVideoOrSummary, setDisplayVideoOrSummary] = useState<VideoOrSummaryContainerDisplay>(VideoOrSummaryContainerDisplay.None);
    const [startListeningForRepurposingCompletion, setStartListeningForRepurposingCompletion] = useState<boolean>(false);
    const [startListeningForTrimmedVideoCompletion, setStartListeningForTrimmedVideoCompletion] = useState<boolean>(false);
    const [showLoadingNotificationModal, setShowLoadingNotificationModal] = useState<boolean>(false);
    const [showQueryLoadingNotificationModal, setShowQueryLoadingNotificationModal] = useState<boolean>(false);
    const [loadingCompleted, setLoadingCompleted] = useState<boolean>(false);
    const [showSignUpOrLoginModal, setShowSignUpOrLoginModal] = useState<boolean>(false);
    const [videosArePostProcessing, setVideosArePostProcessing] = useState<boolean>(false);
    const [justFetchedPostProcessedVideo, setJustFetchedPostProcessedVideo] = useState<boolean>(false);
    const [shouldSendRequestToUpdateDuration, setShouldSendRequestToUpdateDuration] = useState<boolean>(false);
    const [shouldSendRequestToUpdateSubtitles, setShouldSendRequestToUpdateSubtitles] = useState<boolean>(false);
    const [currentlyViewingVideoOrientation, setCurrentlyViewingVideoOrientation] = useState<string>("landscape");
    const [shouldRefreshSnippets, setShouldRefreshSnippets] = useState<boolean>(false);
    const [showNoQueriesLeftModal, setShowNoQueriesLeftModal] = useState<boolean>(false);
    const [showGetMoreClipsLoadingModal, setShowGetMoreClipsLoadingModal] = useState<boolean>(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const [getMoreClipsEstimatedTime, setGetMoreClipsEstimatedTime] = useState<number>(180);
    const [showMagicSubtitlesUpgradeModal, setShowMagicSubtitlesUpgradeModal] = useState<boolean>(false);
    const [currentlyViewingVideoID, setCurrentlyViewingVideoID] = useState<string | null>(null);
    const [currentlyViewingVariantType, setCurrentlyViewingVariantType] = useState<string[] | null>(null);
    const [currentlyViewingVideoMetadataEntry, setCurrentlyViewingVideoMetadataEntry] = useState<IVideoMetadataEntry | null>(null);
    const [showRepurposingInstructionsSentModal, setShowRepurposingInstructionsSentModal] = useState<boolean>(false);
    const [showVideoSentToBeRerenderedModal, setShowVideoSentToBeRerenderedModal] = useState(false);

    const entryID = queryParams.get("entryID");

    const userManager = new UserManager();
    const mixpanelTracker = new MixpanelTracker();

    // Networking
    // potentially refactor to use purely states instead of callbacks and then just have general: error occurred + error message etc
    const originalVideoFetchCompletionCallback = (isSuccessful: boolean, videoURL: string) => {
        if (isSuccessful) {
            setOriginalVideoURL(videoURL);
            //console.log("Original video URL:", videoURL);
        }
    }

    const snippetsFetchedCompletionCallback = (isSuccessful: boolean, snippets: QueryBasedVideoEntry[]) => {
        //console.log("Snippets downloaded completion callback called")
        if (!snippets) {
            return;
        }

        if (isSuccessful) {
            setVideoSnippets(snippets);
        }

        // only set the currently viewing snippet index on the first fetch
        if (snippets.length === 1) {
            setCurrentlyViewingSnippetIndex(0);
        }

        setDisplayVideoOrSummary(VideoOrSummaryContainerDisplay.Video);

        setShouldRefreshSnippets(false);
    }

    const resultsDownloader = new QueryBasedResultsDownloader(setStartListeningForRepurposingCompletion, originalVideoFetchCompletionCallback, snippetsFetchedCompletionCallback);

    const newSnippetsReadyListenerCallback = async (isFinalSnippet: boolean) => {
        //console.log("New snippets ready listener callback called")
        if (entryID) {
            await resultsDownloader.fetchSnippets(entryID);
        }

        if (isFinalSnippet) {
            console.log("Final snippet received");
            setShowLoadingNotificationModal(false);
            setStartListeningForRepurposingCompletion(false);
            setShowQueryLoadingNotificationModal(false);
            setLoadingCompleted(true);
        }
    }

    const savedVideoReadyListenerCallback = async (videoID: string) => {
        if (entryID) {
            console.log(`Video ID: ${videoID} is ready`);
            const videoURL = await resultsDownloader.downloadVideoFromVideoID(videoID);
            //console.log("Downloaded video:", videoURL);
            const hiddenDownloadLink = document.createElement("a");
            hiddenDownloadLink.href = videoURL;
            hiddenDownloadLink.download = `${new Date()}.mp4`;
            hiddenDownloadLink.click();
        }

        setShowLoadingNotificationModal(false);
        setStartListeningForTrimmedVideoCompletion(false);
        setLoadingCompleted(true);
    }

    const postProcessedVideoReadyListenerCallback = async (isFinal: boolean) => {
        if (entryID) {
            await resultsDownloader.fetchSnippets(entryID);
        }

        setJustFetchedPostProcessedVideo(true);

        if (isFinal) {
            setVideosArePostProcessing(false);
            setLoadingCompleted(true);
        }
    }

    // User actions
    const handleSendQuery = (queryText: string, selectedDuration: number) => {
        if (userRequiresUpgrade === true && isLoggedIn === true) {
            setShowUpgradeModal(true);
            return;
        }

        if (!isLoggedIn && entry?.isPublic === false) {
            setShowSignUpOrLoginModal(true);
            return;
        }

        setStartListeningForRepurposingCompletion(true);
        setLoadingCompleted(false);
        setShowQueryLoadingNotificationModal(true);

        if (queryText.length > 0 && entryID) {
            mixpanelTracker.track("🧐 Query Submitted", localStorage.getItem('email'), { "query": queryText, "entryID": entryID }); 
            resultsDownloader.createSnippets(entryID, queryText, selectedDuration);
        }
    }

    const handleClickedToSaveSnippet = async (snippet: QueryBasedVideoEntry) => {
        setStartListeningForTrimmedVideoCompletion(true);
        setLoadingCompleted(false);
        setShowLoadingNotificationModal(true);

        if (entryID) {
            await resultsDownloader.sendRequestToSaveAndTrimSnippet(entryID, snippet);
        }
    }

    const setupView = async () => {
        if (entryID) {
            await resultsDownloader.fetchOriginalVideoURL(entryID);
            await resultsDownloader.fetchSnippets(entryID);
            const entry = await resultsDownloader.fetchEntry(entryID);
            if (entry) {
                setEntry(entry);
            }
        }

        const userAccount = await userManager.getUserAccount(localStorage.getItem('email'));

        if (userAccount) {
            setIsLoggedIn(true);
            setUserRequiresUpgrade(userAccount.requiresUpgrade);
        }
    }

    useEffect(() => {
        setupView();
        mixpanelTracker.track("🚀 Results Page View", localStorage.getItem('email'), { "entryID": entryID });
    }, []);

    useEffect(() => {
        if (videosArePostProcessing) {
            console.log("Videos are post processing")
            setLoadingCompleted(false);
            setJustFetchedPostProcessedVideo(false);
        }
    }, [videosArePostProcessing])

    useEffect(() => {
        const fetchSnippets = async () => {
            if (entryID) {
                await resultsDownloader.fetchSnippets(entryID);
                console.log("⚡️ Should refresh snippets");
                setPostProcessedVideoURL(null);
            }
        };
    
        fetchSnippets();
    }, [shouldRefreshSnippets]);

    const refreshUserRequiresUpgrade = async () => {
        const userAccount = await userManager.getUserAccount(localStorage.getItem('email'));
        if (userAccount) {
            setUserRequiresUpgrade(userAccount.requiresUpgrade);
        }
    }

    const handleGetMoreClipsCompletion = () => {
        // refresh snippets
        setShouldRefreshSnippets(!shouldRefreshSnippets);
        setShowGetMoreClipsLoadingModal(false);
        setRefreshKey((prevKey) => prevKey + 1);
    }

    const handleGetMoreClipsDurationClicked = async (entryID: string | null, duration: number) => {
        setLoadingCompleted(false);
        mixpanelTracker.track("🙌 Get More Clips Clicked", localStorage.getItem('email'), { "entryID": entryID, "duration": duration });
        console.log(`User requires upgrade: ${userRequiresUpgrade}`);

        await refreshUserRequiresUpgrade();
        if (userRequiresUpgrade === true && isLoggedIn === true) {
            setShowUpgradeModal(true);
            return;
        }

        const durationTimeToCompletionMap = {
            "30": 150,
            "60": 170,
            "90": 190,
            "180": 250,
            "300": 300,
        } as { [key: string]: number };

        const durationTimeToCompletion = durationTimeToCompletionMap[duration.toString()];
        setGetMoreClipsEstimatedTime(durationTimeToCompletion);

        const getMoreClipsEndpoint = "https://latte-video-nl-query-manager-uhhxc4xeaq-ew.a.run.app"
        const payload = {
            "entryID": entryID,
            "desiredDuration": duration
        }

        // set loading bar
        setShowGetMoreClipsLoadingModal(true);
        console.log("🪄 Sending request to get more clips")
        // it's awaiting, therefore, we don't need handleCompletion for the progress modal
        await axios.post(getMoreClipsEndpoint, payload)
        // refresh snippets
        handleGetMoreClipsCompletion();
    }

    const MagicSubtitlesModal = () => {
        const entryBelongsToUser = entry?.email === localStorage.getItem('email');
        let title;
        let message;
        let buttonText = null;
        let buttonLink = null;
        
        if (!entryBelongsToUser) {
            title = "Unauthorized 🚨";
            message = "You can upload your own video to try out this feature! 🔥";
            buttonText = "Upload a Video";
            buttonLink = "/video";
        } else {
            title = "Upgrade Now ⚡️";
            message = "Looks like you've reached your magic subtitles limit. Upgrade to get more! 🪄";
            buttonText = "Upgrade";
            buttonLink = "/video/upgrade";
        }

        const shouldShowModal = () => {
            return !!entry && showMagicSubtitlesUpgradeModal
        }

        return (
            <GeneralUpgradeModal
                title={title}
                message={message}
                buttonText={buttonText}
                buttonLink={buttonLink}
                isOpen={shouldShowModal()}
                onClose={() => { setShowMagicSubtitlesUpgradeModal(false); }}
            />

        )
    }

    return (
        <div className="bg-[#f2efee] pb-10 min-h-[2000px]">
            <TopBanner />
            <MenuBar isLoggedIn={isLoggedIn} />
            <CurrentlyViewingContext.Provider
                value={{
                    currentlyViewingVideoID,
                    setCurrentlyViewingVideoID,
                    currentlyViewingVariantType,
                    setCurrentlyViewingVariantType,
                    currentlyViewingVideoMetadataEntry,
                    setCurrentlyViewingVideoMetadataEntry,
                    setShowRepurposingInstructionsSentModal,
                    setPostProcessedVideoURL,
                    setShowVideoSentToBeRerenderedModal
                }}>
                <div className="flex flex-col space-y-10 bg-transparent m-auto items-center mt-20 relative">
                    {
                        !originalVideoURL &&
                        <h1 className="font-poppins text-3xl">
                            Loading...
                        </h1>
                    }
                    {
                        originalVideoURL &&
                        <div className="flex flex-col md:flex-row space-y-6 md:space-x-10 md:space-y-0 bg-transparent h-[60vh] max-h-[90vh] w-[80vw] m-auto min-h-[400px]">
                            <OriginalVideoContainer originalVideoURL={originalVideoURL} entry={entry}/>
                            <div 
                            className="flex flex-col md:w-5/12 space-y-6 justify-center items-center h-auto min-h-[400px] min-w-[30vw]">
                                <SnippetVideoAndSummaryContainer
                                    key={`${currentlyViewingVariantType}-${refreshKey}`}
                                    originalVideoURL={originalVideoURL}
                                    postProcessedVideoURL={postProcessedVideoURL}
                                    snippets={videoSnippets}
                                    currentlyViewingSnippetIndex={currentlyViewingSnippetIndex}
                                    videoContainerDisplay={displayVideoOrSummary}
                                    handleSaveSnippet={handleClickedToSaveSnippet}
                                    setVideoContainerDisplay={setDisplayVideoOrSummary}
                                    shouldSendRequestToUpdateDuration={shouldSendRequestToUpdateDuration}
                                    shouldSendRequestToUpdateSubtitles={shouldSendRequestToUpdateSubtitles}
                                    setShouldSendRequestToUpdateDuration={setShouldSendRequestToUpdateDuration}
                                    setShouldSendRequestToUpdateSubtitles={setShouldSendRequestToUpdateSubtitles}
                                    currentlyViewingVideoOrientation={currentlyViewingVideoOrientation}
                                    setShouldRefreshSnippets={setShouldRefreshSnippets}
                                    setVideosArePostProcessing={setVideosArePostProcessing}
                                    />
                                <SnippetOptionsToolbar
                                    videoContainerDisplay={displayVideoOrSummary}
                                    snippet={videoSnippets[currentlyViewingSnippetIndex]}
                                    entryID={entryID}
                                    currentlyViewingSnippetIndex={currentlyViewingSnippetIndex}
                                    snippetsCount={videoSnippets.length}
                                    setDisplayVideoOrSummary={setDisplayVideoOrSummary}
                                    setCurrentlyViewingSnippetIndex={setCurrentlyViewingSnippetIndex}
                                    updateVideoToDisplay={setPostProcessedVideoURL}
                                    setVideosArePostProcessing={setVideosArePostProcessing}
                                    setVideoContainerDisplay={setDisplayVideoOrSummary}
                                    setShouldSendRequestToUpdateDuration={setShouldSendRequestToUpdateDuration}
                                    setShouldSendRequestToUpdateSubtitles={setShouldSendRequestToUpdateSubtitles}
                                    setCurrentlyViewingVideoOrientation={setCurrentlyViewingVideoOrientation}
                                    setShowMagicSubtitlesUpgradeModal={setShowMagicSubtitlesUpgradeModal}
                                />
                            </div>
                            {
                                // Only show get more clips button, if the user is logged in and the entry is theirs
                                (entry?.email === localStorage.getItem('email')) &&
                                <div className={`flex flex-grow items-center ${showGetMoreClipsLoadingModal ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}>
                                    <GetMoreClipsButton
                                        onDurationClick={(duration) => handleGetMoreClipsDurationClicked(entryID, duration)}
                                    />
                                </div>
                            }                        

                            
                        </div>
                    }
                    <div className="w-full md:static mt-20 flex justify-center items-center">
                        <QueryInputContainer handleSubmit={handleSendQuery} entry={entry}/>
                    </div>
                </div>
            </CurrentlyViewingContext.Provider>
            {
                startListeningForRepurposingCompletion &&
                <QueryResultsCompletionListener entryID={entryID} newSnippetsReadyCallback={newSnippetsReadyListenerCallback} endpoint={ListeningEndpoints.NewClipsReady}/>
            }
            {
                startListeningForTrimmedVideoCompletion &&
                <QueryResultsCompletionListener entryID={entryID} videoTrimmedCompletionCallback={savedVideoReadyListenerCallback} endpoint={ListeningEndpoints.ClipTrimmed} videoID={videoSnippets[currentlyViewingSnippetIndex].videoID} />
            }
            {
                videosArePostProcessing &&
                <PostProcessedCompletionListener entryID={entryID} videoID={videoSnippets[currentlyViewingSnippetIndex].videoID} completionCallback={postProcessedVideoReadyListenerCallback} />
            }
            {
                showLoadingNotificationModal &&
                <LoadingNotificationModal
                    setIsOpen={setShowLoadingNotificationModal}
                    timeToCompletion={30}
                    message="Latte is exporting your clip now 💾"
                    isCompletedProp={loadingCompleted} />
            }
            {
                showQueryLoadingNotificationModal &&
                <LoadingNotificationModal
                    setIsOpen={setShowLoadingNotificationModal}
                    timeToCompletion={90}
                    message="Latte is extracting the most relevant clips 🪄"
                    isCompletedProp={loadingCompleted} />
            }
            {
                showGetMoreClipsLoadingModal &&
                <LoadingNotificationModal
                    setIsOpen={setShowGetMoreClipsLoadingModal}
                    timeToCompletion={getMoreClipsEstimatedTime}
                    message="Latte is finding more clips for you 🪄"
                    isCompletedProp={loadingCompleted} 
                    socket={socket}
                    taskID={entryID}
                />
            }
            {
                showRepurposingInstructionsSentModal &&
                <AutoCloseUpgradeModal
                    isOpen={showRepurposingInstructionsSentModal}
                    onClose={() => setShowRepurposingInstructionsSentModal(false)}
                    style={{ minHeight: "150px", height: "150px" }}
                    title="Hang tight! 🤖"
                    message="Latte is processing your videos now! 🙌" />
            }

            {
                videosArePostProcessing &&
                <LoadingNotificationModal
                    setIsOpen={setShowLoadingNotificationModal}
                    timeToCompletion={240}
                    // message="Hang tight, we're repurposing your video 🤖"
                    message={justFetchedPostProcessedVideo ? "Your edited video is ready! Click Versions below to view 🎬" : "Hang tight, Latte is processing your video 🤖"}
                    isCompletedProp={loadingCompleted} />
            }
            {
                showUpgradeModal &&
                <UpgradeModal isOpen={showUpgradeModal}
                    onClose={() => setShowUpgradeModal(false)}
                    message="Sorry, you've used up all your search requests for the month. Tap below to upgrade 🚀" />
            }
            
            <MagicSubtitlesModal />
            
            {
                showSignUpOrLoginModal &&
                <LoginOrSignUpModal setIsModalOpen={setShowSignUpOrLoginModal}/>
            }
                        
            <AutoCloseUpgradeModal
                isOpen={showVideoSentToBeRerenderedModal}
                onClose={() => setShowVideoSentToBeRerenderedModal(false)}
                title="Hang tight! 🤖"
                style={{ minHeight: "150px", height: "150px" }}
                message="Latte is re-rendering your video now! ⚡️" />
            
        </div>
    )
}

export default QueryResultsPage;