import React, { useState, useEffect } from 'react';
import axios from 'axios';

export interface ClipDurationSelectorProps {
    handleSelectedClipDuration: (duration: number) => void;
}

const ClipDurationSelector = ({ handleSelectedClipDuration }: ClipDurationSelectorProps) => {
    const clipDurations = ["30s", "60s", "90s", "3 mins", "5 mins"];
    const [selectedClipDuration, setSelectedClipDuration] = useState("90s");

    const handleClipDurationSelection = (duration: string) => {
        //trackMixpanelEvent("duration-selected");

        switch (duration) {
            case "30s":
                handleSelectedClipDuration(30);
                setSelectedClipDuration(duration);
                break;
            case "60s":
                handleSelectedClipDuration(60);
                setSelectedClipDuration(duration);
                break;
            case "90s":
                handleSelectedClipDuration(90);
                setSelectedClipDuration(duration);
                break;
            case "3 mins":
                handleSelectedClipDuration(180);
                setSelectedClipDuration(duration);
                break;
            case "5 mins":
                handleSelectedClipDuration(300);
                setSelectedClipDuration(duration);
                break;
        }
    }

    return (
        <div className="w-11/12 px-3">
            <h1 className="font-poppins font-medium text-center">
                If you'd like, select a preferred duration for your re-purposed videos ⏱
            </h1>
            <div className="flex flex-row space-x-4 mt-6 m-auto justify-center">
                {
                    clipDurations.map((duration, index) => {
                        let bgColor = "#fffbf9";
                        if (duration == selectedClipDuration) {
                            bgColor = "#ffffff";
                        }
                        return (
                            <ClipDurationButton key={index} duration={duration} bgColor={bgColor} handleClipDurationSelection={handleClipDurationSelection} />
                        )
                    })
                }
            </div>
            <h2 className="text-sm font-poppins font-light text-center mt-3">
                {`Your clips will be ${selectedClipDuration} long`}
            </h2>
        </div>
    )
}

interface ClipDurationButtonProps {
    duration: string;
    bgColor: string;
    handleClipDurationSelection: (duration: string) => void;
}

const ClipDurationButton = ({duration, bgColor, handleClipDurationSelection}: ClipDurationButtonProps) => {
    return (
        <div className="flex flex-col items-center justify-center w-1/4 h-12 md:w-32 md:h-16 rounded-xl border-2 border-[#ffeae5] hover:scale-90 cursor-pointer duration-150"
        onClick={(e) => {
            handleClipDurationSelection(duration);
        }}
        style={{"backgroundColor": bgColor}}>
            <h1 className="font-poppins text-sm md:text-base md:font-semibold text-[#545454]">
                {duration}
            </h1>
        </div>
    )
}

export default ClipDurationSelector;