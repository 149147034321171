import React from 'react';

const TopBanner = () => {
    return (
        <div className="w-full py-2 bg-green-500 text-white fixed flex justify-center items-center z-50">
            <h1 className="font-poppins">
                {"🎉 Latte V2 is here! Get ready for faster processing, better clips, and many many more exciting features." + " "}
                <span className="font-poppins underline cursor-pointer"
                onClick={() => window.open("https://app.latte.video")}>
                    {"Tap here"} 
                </span>
                {" " + "to get started"}
            </h1>
        </div>
    )
}

export default TopBanner;