import React, { useState, useEffect, FormEventHandler, MouseEventHandler } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

import { TbSend } from "react-icons/tb"
import { QueryBasedVideoEntry } from "../../Types/DataTypes";
import { NotificationModal } from "../../Modals/NotificationModal";

interface QueryInputContainerProps {
    handleSubmit: (inputText: string, selectedDuration: number) => void;
    entry: QueryBasedVideoEntry | null;
}

const QueryInputContainer = ({ handleSubmit, entry }: QueryInputContainerProps) => {
    const [selectedDuration, setSelectedDuration] = useState<number>(60);
    const [isNotificationModalOpen, setIsNotificationModalOpen] = useState<boolean>(false);

    const handleQuerySubmit = (queryText: string) => {
        if (entry?.isPublic && entry?.queriesLeft === 0) {
            setIsNotificationModalOpen(true);
            return;
        }

        handleSubmit(queryText, selectedDuration);
    }

    return (
        <div className="bg-transparent rounded-3xl w-10/12 md:w-6/12 flex flex-col space-y-2 justify-center items-center">
            <div className="flex w-full h-full flex-row space-x-3">
                <QueryInputField handleQuerySubmit={handleQuerySubmit} placeholderText={entry?.suggestedQuery}/>
                <ClipDurationButton setSelectedDuration={setSelectedDuration} />
            </div>
            <QueriesLeftLabel numberQueriesLeft={entry?.queriesLeft} />
            {
                isNotificationModalOpen &&
                <NotificationModal isOpen={isNotificationModalOpen} onClose={() => setIsNotificationModalOpen(false)} title="Oh no!" message="All video search requests have been used for this video - upgrade to unlock more 🔑"/>
            }
        </div>
    )
    
}

interface QueryInputFieldProps {
    handleQuerySubmit: (queryText: string) => void;
    placeholderText?: string;
}

const QueryInputField = ({ handleQuerySubmit, placeholderText }: QueryInputFieldProps) => {
    const [textValue, setTextValue]= useState<string>("");

    const handleSubmit = () => {
        handleQuerySubmit(textValue);
        setTextValue("");
    }

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTextValue(e.target.value);
    }

    const handleDoneButtonClick: FormEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        
        handleSubmit();
    }

    const handleEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            handleSubmit();
        }
    }

    useEffect(() => {
    }, [placeholderText])

    return (
        <div className="bg-white flex flex-row w-full h-10 rounded-2xl px-5 items-center justify-center shadow-sm hover:scale-105 duration-300">
            <input
                type="text"
                className="w-full h-full text-center font-poppins bg-transparent text-[12px] text-[#545454] rounded-xl outline-none"
                placeholder={"Search for any part of the video you want"}
                onChange={handleTextChange}
                onKeyUp={handleEnterKeyPress}
                value={textValue}>
            </input>
            <button className="w-6 h-6 bg-transparent"
                onClick={handleDoneButtonClick}>
                <TbSend />
            </button>
        </div>
    )
}

interface ClipDurationButtonProps {
    setSelectedDuration: (selectedDuration: number) => void;
}

const ClipDurationButton = ({ setSelectedDuration }: ClipDurationButtonProps) => {
    const [selectedDurationToDisplay, setSelectedDurationToDisplay] = useState<number>(60);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const handleClipDurationButtonClick: MouseEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();

        setIsMenuOpen(!isMenuOpen);
    }

    useEffect(() => {
        setSelectedDuration(selectedDurationToDisplay);
    }, [selectedDurationToDisplay])

    return (
        <div className={`relative bg-white rounded-2xl w-16 h-10 justify-center items-center flex cursor-pointer shadow-sm hover:scale-105 duration-300 ${isMenuOpen ? "z-50" : "z-10"}`}>
            <div
                onClick={handleClipDurationButtonClick}>
                {`${selectedDurationToDisplay}s`}
            </div>
            {
            isMenuOpen &&
            <ClipDurationSelectMenu setSelectedDuration={setSelectedDurationToDisplay} setIsOpen={setIsMenuOpen}/>
            }
        </div>
    )
}

interface ClipDurationSelectMenuProps {
    setSelectedDuration: (selectedDuration: number) => void;
    setIsOpen: (isOpen: boolean) => void;
}

const ClipDurationSelectMenu = ({ setSelectedDuration, setIsOpen }: ClipDurationSelectMenuProps) => {
    const durationOptions = ["30s", "60s", "90s", "3 mins", "5 mins"];

    const handleDurationOptionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, duration: string) => {
        e.preventDefault();

        // algorithm to strip out all non-alphanumeric characters and convert to seconds
        const multiplier = duration.includes("mins") ? 60 : 1; 
        const durationInSeconds = parseInt(duration.replace(/[^0-9]/g, ""))*multiplier;
        setSelectedDuration(durationInSeconds);
        setIsOpen(false);
    }

    const handleMouseLeave = () => {
        setIsOpen(false);
    }

    return (
        <div className="absolute w-28 rounded-xl bg-white z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-1 py-3 shadow-xl shadow-[#ffe7d5] border border-[#ffe7d5] hover:scale-105 duration-300"
        onMouseLeave={handleMouseLeave}>
            <div className="flex flex-col justify-between items-center space-y-2">
            {
                durationOptions.map((durationOption, index) => {
                    return (
                        <div
                        className="justify-center flex items-center"
                        key={index}
                        onClick={(e) => handleDurationOptionClick(e, durationOption)}>
                            <ClipDurationOption duration={durationOption}/>
                            {/* if not last option, add a divider */}
                        </div>
                    )
                })
            }
            </div>
        </div>
    )
}

interface ClipDurationOptionProps {
    duration: string;
}

const ClipDurationOption = ({ duration }: ClipDurationOptionProps) => {
    return (
        <div className="h-4 w-full font-poppins cursor-pointer text-sm hover:scale-105 duration-200">
            {duration}
        </div>
    )
}

interface QueriesLeftLabelProps {
    numberQueriesLeft?: number;
}

const QueriesLeftLabel = ({ numberQueriesLeft }: QueriesLeftLabelProps) => {
    const [messageToDisplay, setMessageToDisplay] = useState<string>("");

    const calculateMessageToDisplay = () => {
        if (numberQueriesLeft === undefined) {
            return;
        }

        if (numberQueriesLeft > 0) {
            setMessageToDisplay(`${numberQueriesLeft} search queries are left for this video`);
        } else {
            setMessageToDisplay("This video has no more search queries left");
        }
    }

    useEffect(() => {
        calculateMessageToDisplay();
    }, [numberQueriesLeft])

    return (
        <h1 className="font-poppins text-xs text-gray-400 font-light">
            {messageToDisplay}
        </h1>
    )
}

export default QueryInputContainer;