import React, { useEffect, useState, memo } from 'react';
import axios from 'axios';

interface PostProcessedCompletionListenerProps {
    entryID: string | null;
    videoID: string;
    completionCallback: (isFinal: boolean) => void;
}

const PostProcessedCompletionListener = ({ entryID, videoID, completionCallback }: PostProcessedCompletionListenerProps ) => {
    useEffect(() => {
        const es = new EventSource(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/post-processing-updates?updateType=listen&entryID=${entryID}&videoID=${videoID}`);

        console.log("Post Processed completion listener rendered")
        console.log("Opened SSE connection")

        es.onmessage = (event) => {
            console.log("Ping received:", event.data);
            // maybe refactor to check event data and skip initial if statement
            if (event.data.includes("completed")) {
                completionCallback(false);
            } else if (event.data.includes("final")) {
                completionCallback(true);
                es.close();
            }
        }

        const handleBeforeUnload = () => {
            // send disconnect signal to server
            es.close();
            axios.post('/disconnect');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            es.close();
            console.log("Closed SSE connection")
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [])

    return (
        <div>
        </div>
    )
}

export default PostProcessedCompletionListener;