import React, { useEffect, useState } from 'react';
import { TiTick } from "react-icons/ti"
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

import { LogInOrOutButton } from '../Components/LogInOrOutButton';

import { videoSubscriptionMonthlyPrices, videoSubscriptionYearlyPrices } from '../DataObjects/PricingData';

import { PriceInfo } from '../Types/DataTypes';

import UserManager from '../HelperClasses/Networking/UserManager';
import { setEmailLocalStorage, setUserIsLoggedInLocalStorage, setUserIsLoggedOutLocalStorage } from '../HelperClasses/loginHelperFunctions';
import UnsubscribeModal from '../Modals/UnsubscribeModal';
import MixpanelTracker from '../HelperClasses/Analytics/MixpanelTracker';

interface PriceOptionCardProps {
    priceInfo: PriceInfo;
}

interface SignUpPageProps {
    monthlyPrices: PriceInfo[],
    yearlyPrices: PriceInfo[],
    pageHeader: string,
    showTryFreeButton: boolean
}

interface PageHeaderProps {
    pageHeader: string,
}

const SignUpPage = ({monthlyPrices, yearlyPrices, pageHeader, showTryFreeButton}: SignUpPageProps) => {
    let [queryParams, setQueryParams] = useSearchParams();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const phoneNumber = queryParams.get("phoneNumber");
    const userEmail = localStorage.getItem('email')
    const [dataToDisplay, setDataToDisplay] = useState<PriceInfo[]>(monthlyPrices);
    const mixpanelTracker = new MixpanelTracker();

    console.log((window as any).Rewardful && (window as any).Rewardful.referral || ('checkout_'+(new Date).getTime()))

    const checkIfLoggedIn = () => {
        const isLoggedIn = localStorage.getItem('isLoggedIn') === "true";
        setIsLoggedIn(isLoggedIn);
    }

    useEffect(() => {
        axios.get(`https://latte-payment-server-qo2mn26dqq-ew.a.run.app/getStarted?phoneNumber=${phoneNumber}`)
        axios.get(`https://latte-payment-server-qo2mn26dqq-ew.a.run.app/mixpanel?email=${userEmail}&mixpanelEvent=SignUpPageView`)
        mixpanelTracker.track("👀 Sign Up Page View", userEmail)
    })

    return (
        <div className="bg-[#fffbf9] min-h-[2000px] relative">
            <MenuBar isLoggedIn={isLoggedIn}/>
            <div className="relative flex flex-col min-h-screen bg-[#fffbf9] m-auto p-6 items-center space-y-8 mt-10">
                <PageHeader pageHeader={pageHeader} />
                <SubscriptionDurationSelectionButtons setDataToDisplay={setDataToDisplay} />
                <PriceOptionsContainer priceOptions={dataToDisplay} />
                {showTryFreeButton && <TryFreeButton />}
                <EnterpriseSection />
                <div className="pt-16">
                    <TestimonialSection />
                </div>
            </div>
        </div>
    )
}

const PageHeader = ({pageHeader}: PageHeaderProps) => {
    return (
        <div className="flex flex-col justify-center space-y-2">
            <h1 className="font-medium font-poppins text-4xl text-center"> 
                {pageHeader}
            </h1>
            <h1 className="font-poppins font-light text-lg text-[#aea8ab] text-center">
                Choose the one that works best for you ☕️
            </h1>
        </div>
    )
}

interface SubscriptionDurationSelectionButtonsProps {
    setDataToDisplay: (dataToDisplay: PriceInfo[]) => void;
}

const SubscriptionDurationSelectionButtons = ({ setDataToDisplay }: SubscriptionDurationSelectionButtonsProps) => {
    const [selectedButton, setSelectedButton] = useState("monthly");

    return (
        <div className="flex flex-row justify-center space-x-4">
            <button className={`bg-[#ffebdc] rounded-[20px] h-[36px] w-[100px] font-poppins ${selectedButton === "monthly" ? "scale-110 border-2 border-black" : "scale-100 border-0"} font-medium text-[#545454] text-base duration-300`}
            // style={selectedButton === "yearly" ? {border: "2px solid black"} : {border: "none"}}
            onClick={() => {
                setDataToDisplay(videoSubscriptionMonthlyPrices)
                setSelectedButton("monthly")}}>
                Monthly
            </button>
            <button className={`bg-[#ffebdc] rounded-[20px] h-[36px] w-[100px] font-poppins ${selectedButton === "yearly" ? "scale-110 border-2 border-black" : "scale-100 border-0"} font-medium text-[#545454] text-base duration-300`}
            onClick={() => {
            setDataToDisplay(videoSubscriptionYearlyPrices)
            setSelectedButton("yearly")}}>
                Yearly
            </button>
        </div>
    )
}

const PriceOptionCard = ({ priceInfo }: PriceOptionCardProps) => {
    return (
        <div className="bg-white relative flex-col rounded-[20px] w-[90%] md:w-96 h-[600px] flex shadow-md shadow-[#ffebdc] p-10 lg:hover:scale-110 space-y-6 transition duration-300">
            <PriceOptionCardHeader priceInfo={priceInfo} />
            <div className="w-[90%] bg-[#f0f0f0] h-[1px] m-auto" />
            <PriceOptionBenefits priceInfo={priceInfo} />
            <PurchaseButton priceInfo={priceInfo} />
            {
                priceInfo.addMostPopular &&
                <MostPopularTag />
            }
        </div>
    )
}

const PriceOptionCardHeader = ({ priceInfo }: PriceOptionCardProps) => {
    const img = require(`../images/${priceInfo.img}.jpg`)
    const [hasCoupon, setHasCoupon] = useState(false);
    const [discountPrice, setDiscountPrice] = useState("");
    let [queryParams, setQueryParams] = useSearchParams();

    const calculateCouponPrice = () => {
        let coupon = queryParams.get("coupon");

        if (priceInfo.couponCode === "starter_first_month") {
            coupon = "starter_first_month"
        }

        if (coupon === "ysdwzNoO") {
            setHasCoupon(true)
            let price = priceInfo.price * 0.90
            let splitNumStr = price.toString().split(".");
            splitNumStr[1] = splitNumStr[1].substring(0, 2);
            let newPrice = parseFloat(splitNumStr.join("."));
            setDiscountPrice(newPrice.toString())
        } else if (coupon === "7fsjj2gV") {
            setHasCoupon(true)
            let price = priceInfo.price * 0.70
            let splitNumStr = price.toString().split(".");
            splitNumStr[1] = splitNumStr[1].substring(0, 2);
            let newPrice = parseFloat(splitNumStr.join("."));
            setDiscountPrice(newPrice.toString())
        } else if (coupon === "starter_first_month") {
            setHasCoupon(true)
            let price = priceInfo.price * 0.20
            setDiscountPrice(price.toString())
        } else {
            setHasCoupon(false)
        }
    }

    useEffect(() => {
        calculateCouponPrice()
    }, [priceInfo])

    return (
        <div className="flex flex-row space-x-6 items-center">
            <img src={img} className="w-16 h-16 rounded-md" />
            <div className="flex flex-col">
                <h1 className="font-poppins text-xl">
                    {priceInfo.name}
                </h1>
                <div className="flex flex-row space-x-2 items-end">
                    {
                        <h2 className="font-poppins font-semibold text-2xl">
                            {hasCoupon ? `$${discountPrice}` : `$${priceInfo.price}`}
                        </h2>
                    }
                    {
                        hasCoupon && !priceInfo.unReducedPrice &&
                        <h2 className="font-poppins font-medium text-lg line-through text-gray-500">
                            {`$${priceInfo.price}`}
                        </h2>
                    }
                    {
                        priceInfo.unReducedPrice &&
                        <h2 className="font-poppins font-medium text-lg line-through text-gray-500">
                            {`$${priceInfo.unReducedPrice}`}
                        </h2>
                    }
                </div>
            </div>
        </div>
    )
}

const PriceOptionBenefits = ({ priceInfo }: PriceOptionCardProps) => {
    return (

        <div className="flex flex-col space-y-1 overflow-y-scroll w-full scrollbar-custom h-80 max-h[80%]">
            {
                priceInfo.benefits.map((benefit) => {
                    return (
                        <h3 className="font-poppins">
                            <TiTick className="inline-block mr-2" color="#aea8ab"/>
                            {benefit}
                        </h3>
                    )
                })
            }
        </div>
    )
}

const PurchaseButton = ({ priceInfo }: PriceOptionCardProps) => {
    let [queryParams, setQueryParams] = useSearchParams();
    let coupon = queryParams.get("coupon");
    const mixpanelTracker = new MixpanelTracker();
    const email = localStorage.getItem('email');
    const [plan, setPlan] = useState("")

    if (priceInfo.couponCode === "starter_first_month") {
        coupon = "starter_first_month"
    }

    // Rewardful integration
    function getClientReferenceId() {
        return (window as any).Rewardful && (window as any).Rewardful.referral || ('checkout_'+(new Date).getTime())
      }

    console.log("COUPON: ", coupon)

    const requestBody = {
        priceID: priceInfo.id,
        email: email,
        coupon: coupon,
        cancelURL: window.location.href,
        clientReferenceId: getClientReferenceId()
    }

    // console.log("REQUEST TO SEND TO STRIPE: ", requestBody)

    useEffect(() => {
        const setUserPlan = async () => {
            const userManager = new UserManager();
            const userAccount = await userManager.getUserAccount(email)
            if (userAccount === undefined || userAccount === null) {
                return
            }
            const plan = userAccount.plan
            console.log(plan)
            setPlan(plan)
        }
        setUserPlan()
    }, [email])

    const StartFreeTrialButton = () => {
        return (
            <button
                className={`w-[70%] absolute bottom-6 self-center h-14 rounded-2xl ${priceInfo.addMostPopular === true ? "bg-new-gradient" : "bg-[#ffd4ca]"} text-black font-poppins font-semibold hover:animate-pulse transition duration-300`}
                onClick={() => {
                    axios.post(priceInfo.checkoutURL, requestBody)
                        .then((response) => {
                            console.log("response", response)
                            const urlToOpen = response.data.url
                            window.location.href = urlToOpen
                            mixpanelTracker.track("🤑 Checkout Started", email, {
                                "Price": priceInfo.price,
                            })
                        })
                        .catch((error) => {
                            console.log("error", error)
                        })
                }}
                style={{backgroundColor: priceInfo.addMostPopular === true ? "bg-new-gradient" : "#ffd4ca"}}>
                {priceInfo.buttonText}
            </button>
        )
    }

    const UpgradePlanButton = () => {
        const updatePlanEndpoint = "https://latte-payment-server-qo2mn26dqq-ew.a.run.app/update-plan"
        return (
            <button
                className={`w-[70%] absolute bottom-6 self-center h-14 rounded-2xl ${priceInfo.addMostPopular === true ? "bg-new-gradient" : "bg-[#ffd4ca]"} text-black font-poppins font-semibold hover:animate-pulse transition duration-300`}
                onClick={() => {
                    axios.post(updatePlanEndpoint, requestBody)
                        .then((response) => {
                            // if response.status === 200, then redirect to hasJustUpgraded
                            if (response.status === 200) {
                                window.location.href = "https://app.latte.social/video?hasJustUpgraded=true"
                                mixpanelTracker.track("🤑 User upgraded to a new plan", email, {
                                    "Price": priceInfo.price,
                                })
                            } else {
                                const urlToOpen = response.data.url
                                window.location.href = urlToOpen
                                mixpanelTracker.track("🤑 Checkout Started", email, {
                                    "Price": priceInfo.price,
                                })
                            }
                        })
                        .catch((error) => {
                            console.log("error", error)
                        })
                }}
                style={{backgroundColor: priceInfo.addMostPopular === true ? "bg-new-gradient" : "#ffd4ca"}}>
                Upgrade 
            </button>
        )        
    }

    if (plan === "free" || plan === "") {
        return <StartFreeTrialButton />
    } else {
        return <UpgradePlanButton />
    }

    // return (
        
        // <button
        //     className={`w-[70%] absolute bottom-6 self-center h-14 rounded-2xl ${priceInfo.addMostPopular === true ? "bg-new-gradient" : "bg-[#ffd4ca]"} text-black font-poppins font-semibold hover:animate-pulse transition duration-300`}
        //     onClick={() => {
        //         axios.post(priceInfo.checkoutURL, requestBody)
        //             .then((response) => {
        //                 console.log("response", response)
        //                 const urlToOpen = response.data.url
        //                 window.location.href = urlToOpen
        //                 mixpanelTracker.track("🤑 Checkout Started", email, {
        //                     "Price": priceInfo.price,
        //                 })
        //             })
        //             .catch((error) => {
        //                 console.log("error", error)
        //             })
        //     }}
        //     style={{backgroundColor: priceInfo.addMostPopular === true ? "bg-new-gradient" : "#ffd4ca"}}>
        //     {/* {priceInfo.buttonText} */}
        //     { email ? "Upgrade" : priceInfo.buttonText }
        // </button>
    // )
}

interface PriceOptionsContainerProps {
    priceOptions: PriceInfo[]
}

const PriceOptionsContainer = ({ priceOptions }: PriceOptionsContainerProps) => {
    return (
        <div className="flex flex-col w-full space-y-8 lg:flex-row lg:space-x-4 items-center justify-center lg:space-y-0">
            {priceOptions.map((priceOption) => {
                return (
                    <PriceOptionCard
                        priceInfo={priceOption}
                    />
                )
            }
            )}
        </div>
    )
}

const EnterpriseSection = () => {
    return (
        <div className="flex flex-col space-y-2">
            {/* <h1 className="font-poppins font-medium text-2xl text-center">
                Need more?
            </h1> */}
            <h2 className="font-poppins font-light text-md text-center">
                Need more? Drop us an email at hello@latte.social to discuss our Enterprise packages
            </h2>
        </div>
    )
}

const TestimonialSection = () => {
    return (
        <div className="flex flex-col space-y-6">
            <h1 className="font-poppins font-medium text-2xl text-center">
                Join 1000+ people supercharging their video content with Latte ⚡️
            </h1>
            <div className="flex-row flex space-x-10">
                <h2 className="font-poppins font-light text-sm text-center w-[360px]">
                    “Subtitling on these vertical clips is unreal for AI - never seen automatic subtitles that accurate before” - Sam, CEO of Kurogo
                </h2>
                <h2 className="font-poppins font-light text-sm text-center w-[360px]">
                    “I've tested out 10+ similar apps so far and you guys have had the best reformatting vertical sequences” - Brian, Podcast Producer
                </h2>
                <h2 className="font-poppins font-light text-sm text-center w-[360px]">
                    “I've talked to a bunch of your competitors, trying to find the best option - your clips are the most coherent” - Jeremie, creates 10,000+ short videos a month
                </h2>
            </div>
        </div>
    )
}

interface MenuBarProps {
    isLoggedIn: boolean
}

export const MenuBar = ({isLoggedIn}: MenuBarProps) => {
    const image = require(`../images/latte-logo-menu-bar.png`)
    return (
        <div className="pt-10 flex flex-row items-center m-auto px-1 md:w-[60%] pb-6">
            <img src={image} className="h-10 ml-8 hover:cursor-pointer"
                onClick={() => {
                    window.open("https://latte.social")
                }} />
            <div className="flex flex-row justify-center items-center space-x-12 ml-auto mr-8 text-[#777574]">
                <h1 className="font-poppins font-medium text-sm hover:cursor-pointer">
                    <a href="https://latte.social">Home</a>
                </h1>
                <h1 className="font-poppins font-medium text-sm hover:cursor-pointer">
                    <a href="https://app.latte.social/video/upgrade">Pricing</a>
                </h1>
                <h1 className="font-poppins font-medium text-sm hover:cursor-pointer">
                    <a href="https://app.latte.social/video">Video</a>
                </h1>
                <LogInOrOutButton loggedIn={isLoggedIn}/>
            </div>
        </div>
    )
}

const TryFreeButton = () => {
    const testLink = "https://buy.stripe.com/test_eVaaGNfMA33l4GQfYZ"
    const link = "https://wa.me/442070888063?text=Send%20a%20message%20to%20start!"
    
    return (
        <div className='w-40 h-12 bg-white rounded-3xl'>
            <button className='w-full h-full rounded-3xl bg-main-gradient'
            onClick={(e) => {
                e.preventDefault()
                window.open(link)
            }}>
                <h1 className='text-[#6b542e] font-poppins font-medium text-sm text-center'>Or, try for free</h1>
            </button>
        </div>
    )
}

const MostPopularTag = () => {
    return (
        <div className="absolute top-2 right-6 rounded-full bg-[#ffd4ca] px-2 py-1">
            <h1 className="font-poppins font-normal text-sm text-black">
                Most Popular
            </h1>
        </div>
    )
}

export const UnsubscribeButton = () => {
    const [showModal, setShowModal] = useState(false)
    const mixpanelTracker = new MixpanelTracker();

    const handleCLick = async () => {
        mixpanelTracker.track("😢 Unsubscribe Button Clicked", localStorage.getItem('email'), {
            "Page": "Account Page",
        })
        setShowModal(true)
    }

    return (
        <div className="flex flex-col space-y-2 ml-auto">
            <button className="font-poppins font-light text-sm text-gray-500 text-center"
                onClick={handleCLick}>
                Unsubscribe
            </button>
            {
                showModal === true &&
                <UnsubscribeModal setIsOpen={setShowModal} />
            }
        </div>
    )
}

export default SignUpPage