import React, { useState, useEffect } from 'react';
import { ProgressBar } from '../../Components/EssentialComponents';
import { UserAccount } from '../../Types/DataTypes';
import UnsubscribeModal from '../../Modals/UnsubscribeModal';
import MixpanelTracker from "../../HelperClasses/Analytics/MixpanelTracker";

interface AccountUsageViewProps {
    account: UserAccount;
}

const AccountUsageView = ({ account }: AccountUsageViewProps) => {
    const [clipsCreatedPercentage, setClipsCreatedPercentage] = useState(0)
    const [minutesUploadedPercentage, setMinutesUploadedPercentage] = useState(0)
    const [showClipsGenerated, setShowClipsGenerated] = useState(account.plan === "starter" ? true : false)
    const mixpanelTracker = new MixpanelTracker();
    // console.log("ACCOUNT", account)

    useEffect(() => {
        setClipsCreatedPercentage(Math.round((account.clipsGeneratedThisMonth / account.maxClipsGenerated) * 100))
        setMinutesUploadedPercentage(Math.round((account.minutesUploadedThisMonth / account.maxUploadMinutes) * 100))
    }, [account])

    const ClipsCreatedDisplayComponent = () => {
        return (
            <div className="w-full h-1/2 flex flex-col justify-left items-left font-poppins text-sm space-y-1">
                <h1>
                    Clips created this month: {account.clipsGeneratedThisMonth}
                </h1>
                <ProgressBar progressPercentage={clipsCreatedPercentage} showPercentage={true} />
            </div>
        )
    }

    const MinutesUploadedDisplayComponent = () => {
        return (
            <div className="w-full h-1/2 flex flex-col justify-center items-left font-poppins text-sm space-y-1">
                <h1>
                    Minutes uploaded this month: {account.minutesUploadedThisMonth.toPrecision(3)}
                </h1>
                <ProgressBar progressPercentage={minutesUploadedPercentage} showPercentage={true} />
            </div>
        )
    }

    return (
        <div className="w-full h-full flex flex-col justify-center items-center space-y-3">
            { showClipsGenerated && <ClipsCreatedDisplayComponent /> }
            <MinutesUploadedDisplayComponent />
            <h2 className="font-poppins text-sm text-center">
                {"Account email: " + account.email}
            </h2>
            <h2 className="font-poppins text-sm text-center">
                {"Current plan: " + account.plan.toLocaleUpperCase()}
            </h2>
            <button className="font-poppins text-sm font-light text-center text-gray-500"
                onClick={() => {
                    mixpanelTracker.track("🤔 Manage plan clicked", localStorage.getItem('email')); 
                    window.open("https://billing.stripe.com/p/login/4gw17BgpncIn3Cw3cc", "_blank")
                }}>
                Manage account
            </button>
            {
                account.plan !== "free" &&
                <UnsubscribeButton />
            }
        </div>
    )
}

export const UnsubscribeButton = () => {
    const [showModal, setShowModal] = useState(false)
    const mixpanelTracker = new MixpanelTracker();
    const handleCLick = async () => {
        mixpanelTracker.track("😭 Unsubscribe button clicked", localStorage.getItem('email')); 
        setShowModal(true)
    }

    return (
        <div className="flex flex-col space-y-2 justify-center items-center">
            <button className="font-poppins font-light text-sm text-gray-500 text-center"
                onClick={handleCLick}>
                Cancel plan
            </button>
            {
                showModal === true &&
                <UnsubscribeModal setIsOpen={setShowModal} />
            }
        </div>
    )
}

export default AccountUsageView;