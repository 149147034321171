import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";

import "./CustomSliderCSS.css"
import { QueryBasedVideoEntry } from "../../Types/DataTypes";
import CustomVideoPlayer from "../../CustomReactTags/CustomVideoPlayer/CustomVideoPlayer";
import { VideoOrSummaryContainerDisplay } from "../../Types/Enums";
import { Tooltip } from "../../Components/HelperComponents/ToolTip";
import { fetchVideoMetadata } from "../../HelperFunctions/VideoMetadataHelpers";
import BaseButton from "../../Components/EssentialComponents/BaseButton";
import CurrentlyViewingContext from "../../ContextManagers/CurrentlyViewingContext";

interface TrimVideoContainerProps {
    entry: QueryBasedVideoEntry | null;
    videoURL: string;
    shouldSendRequestToUpdateDuration: boolean;
    setShouldSendRequestToUpdateDuration: (shouldSendRequest: boolean) => void;
    updateContainerDisplay: (display: VideoOrSummaryContainerDisplay) => void;
    setShouldRefreshSnippets: (shouldRefresh: boolean) => void;
}

export const TrimVideoContainer = ({ entry, videoURL, shouldSendRequestToUpdateDuration, setShouldSendRequestToUpdateDuration, updateContainerDisplay, setShouldRefreshSnippets }: TrimVideoContainerProps) => {
    const [startTime, setStartTime] = useState("0");
    const [endTime, setEndTime] = useState("100");
    const [originalStartTime, setOriginalStartTime] = useState("0");
    const [originalEndTime, setOriginalEndTime] = useState("100");
    const [currentTime, setCurrentTime] = useState(0);
    const [videoURLState, setVideoURL] = useState("");
    const [isPlayingProp, setIsPlayingProp] = useState(false);
    const [settingsButtonPosition, setSettingsButtonPosition] = useState(20);
    const [showSensitivityModal, setShowSensitivityModal] = useState(false);
    const [sensitivityMultiplier, setSensitivityMultiplier] = useState(1);
    const [isDraggingTrimSlider, setIsDraggingTrimSlider] = useState(false);
    const [videoDuration, setVideoDuration] = useState(0);
    const { 
        setCurrentlyViewingVariantType,
        setShowVideoSentToBeRerenderedModal,
    } = useContext(CurrentlyViewingContext);

    const fetchVideoDuration = (url: string) => {
        const videoElement = document.createElement('video');
        videoElement.src = url;
        videoElement.preload = 'metadata';
    
        videoElement.onloadedmetadata = () => {
            setVideoDuration(videoElement.duration);
            videoElement.remove();
        };
    
        videoElement.onerror = () => {
            console.error('Error fetching video duration');
            videoElement.remove();
        };
    };
    

    const createVideoObjectURL = async (videoURL: string) => {
        console.log("running")
        const videoData = await axios.get(videoURL, {
            headers: {
                "Content-Type": "video/mp4",
            },
            responseType: "blob",
        })

        console.log("running")

        const blob = new Blob([videoData.data], { type: "video/mp4" });
        const url = window.URL.createObjectURL(blob);
        console.log("createVideoObjectURL ran: ", url)
        setVideoURL(url);

    }

    const incrementStartTime = (incrementInSeconds: number) => {
        console.log(`Incrementing start time by ${incrementInSeconds} seconds`)
        const newStartTime = Math.max(Number(originalStartTime) - incrementInSeconds, 0);
        console.log(`✨ Original start time: ${originalStartTime}`)
        console.log(`👀 New start time: ${newStartTime}`)
        setIsPlayingProp(true);
        setCurrentTime(0);
        return setStartTime(newStartTime.toString());
    }

    const incrementEndTime = (incrementInSeconds: number) => {
        const newEndTime = Math.min(Number(originalEndTime) + incrementInSeconds, videoDuration);
        console.log(`✨ Original end time: ${originalEndTime}`)
        console.log(`👀 New end time: ${newEndTime}`)
        setIsPlayingProp(true);
        const newCurrentTime = Math.min(newEndTime - 1, videoDuration - 1);
        console.log(`🚀 New current time: ${newCurrentTime}`)
        setCurrentTime(newCurrentTime);
        return setEndTime(newEndTime.toString());
    }
    

    useEffect(() => {
        console.log("Video URL: ", videoURL)
        setVideoURL(videoURL);
        fetchVideoDuration(videoURL); // Add this line
        setStartTime(entry?.startTime.toString() || "0");
        setOriginalStartTime(entry?.startTime.toString() || "0");
        setEndTime(entry?.endTime.toString() || "60");
        setOriginalEndTime(entry?.endTime.toString() || "60");
        console.log("TrimVideoContainer useEffect ran")
    }, [])

    useEffect(() => {
        console.log(`Start time: ${startTime}, End time: ${endTime}, Current time: ${currentTime}`)
    }, [startTime, endTime, currentTime])

    const sendRequestToUpdateDuration = async () => {
        if (!entry) return;
        // Fetch existing startTime and endTime first, and compare 
        // const videoMetadata = await fetchVideoMetadata(videoID, [subtitleSequenceFieldName]);
        const result = await fetchVideoMetadata(entry.videoID, ["startTime", "endTime"]);
        const existingStartTime = result?.startTime;
        const existingEndTime = result?.endTime;
        console.log(`Existing start time: ${existingStartTime}, Existing end time: ${existingEndTime}`)

        if (existingStartTime === startTime && existingEndTime === endTime) {
            console.log("Start and end times are the same, no need to update")
        } else {
            const requestBody = {
                videoID: entry?.videoID,
                startTime: startTime,
                endTime: endTime,
            }

            const response = await axios.post("https://latte-video-server-dwr7snkjfq-ew.a.run.app/update-clip-times", requestBody);
            // console.log("Response: ", response)
        }   

        updateContainerDisplay(VideoOrSummaryContainerDisplay.Video);
        setShowVideoSentToBeRerenderedModal(true);
        setShouldRefreshSnippets(true);
        setCurrentlyViewingVariantType(["original"]);
        // setShowVideoSentToBeRerenderedModal(true);
    }

    const SaveButton = () => {
        return (
            <BaseButton 
                onClickFunction={sendRequestToUpdateDuration} 
                text={{
                    default: "Save",
                    clicked: "Saving...",
                    completed: "Saved!"
                }}
            />
        );
    }

    // useEffect(() => {
    //     if (shouldSendRequestToUpdateDuration) {
    //         console.log("Sending request to update duration")
    //         sendRequestToUpdateDuration();
    //         setShouldSendRequestToUpdateDuration(false);
    //     }
    // }, [shouldSendRequestToUpdateDuration])

    return (
        <div className="flex flex-col h-96 justify-center items-center">
            <CustomVideoPlayer 
                key={`${videoURL}-${startTime}-${endTime}`} 
                src={videoURL} 
                startTimeString={startTime} 
                endTimeString={endTime} 
                isPlayingProp={isPlayingProp}
                currentTimeProp={currentTime}
                />
            <div
                style={{ width: "100%" }}
                className="flex flex-row justify-between items-center"
            >

                <div className="flex items-center justify-end mb-4 p-2 w-1/4">
                    {/* DivFor spacing only */}
                </div>
                <div className="flex items-center justify-center w-3/4 mx-auto h-full">
                    <div className="flex items-center justify-center mb-4 rounded-md p-2 w-3/4  mx-auto">
                        <TrimSlider
                            incrementStartTime={incrementStartTime}
                            incrementEndTime={incrementEndTime}
                            sensitivityMultiplier={sensitivityMultiplier}
                            onSettingsButtonPositionChange={setSettingsButtonPosition}
                            setIsDraggingTrimSlider={setIsDraggingTrimSlider}
                        />
                    </div>
                </div>

                <div className="flex items-center justify-center w-1/4  mx-auto">
                    <div className="flex items-center justify-center w-1/4 ">
                        <AdjustSensitivityButton
                        currentSensitivityMultiplier={sensitivityMultiplier}
                        onClick={(newSensitivityMultiplier) => {
                            setSensitivityMultiplier(newSensitivityMultiplier);
                        }}
                        style={{
                            transform: `translateX(${settingsButtonPosition / 1.5}px)`,
                            opacity: isDraggingTrimSlider ? 0 : 1,
                            pointerEvents: isDraggingTrimSlider ? "none" : "auto",
                            paddingTop: "18px",
                        }}
                        />
                    </div>
                </div>

            </div>
            <SaveButton />
        </div>
    )   
}

interface TrimSliderProps {
    incrementStartTime: (incrementInSeconds: number) => void;
    incrementEndTime: (incrementInSeconds: number) => void;
    sensitivityMultiplier: number;
    onSettingsButtonPositionChange: (position: number) => void;
    setIsDraggingTrimSlider: (isDragging: boolean) => void;
}

export const TrimSlider = ({ 
    incrementStartTime, 
    incrementEndTime, 
    sensitivityMultiplier, 
    onSettingsButtonPositionChange,
    setIsDraggingTrimSlider,
}: TrimSliderProps) => {
    const [slider1Value, setSlider1Value] = useState(50);
    const [slider2Value, setSlider2Value] = useState(50);
    const slider2WrapperRef = useRef<HTMLDivElement>(null);

    // to do - prevent video from flickering when changing times
    // to do - be able to set a width for the slider
    // ideal to do - make it so the slider handles stay at a fixed position relative to the slider - not essential though at all pre launch - deprioritise

    const sliderChangeInSeconds = (sliderValue: number) => {
        // let's say, we allow changes += 30 seconds
        const baseChangeInSeconds = 15;
        const maxChangeInSeconds = baseChangeInSeconds * sensitivityMultiplier;
        const changeInSeconds = (sliderValue - 50) / 50 * maxChangeInSeconds;
        return changeInSeconds;
    }

    const handleSlider1Change = (value: number) => {
        setSlider1Value(value);
    }

    const handleSlider2Change = (value: number) => {
        setSlider2Value(value);
        const newPosition = (value / 100) * slider2WrapperRef.current!.offsetWidth;
        onSettingsButtonPositionChange(newPosition);
    }; 

    return (
        <div className="sliderContainer z-100">
            <div className="slider1Wrapper">
                <div className="slider"
                    style={{ width: `${slider1Value}%` }}>
                    <input className="rangeSlider" type="range" min="0" max="100" step={0.001} value={slider1Value} onChange={(e) => handleSlider1Change(Number(e.target.value))}
                    // to do - add on mouse up event to set start time
                    onMouseUp={() => {
                        incrementStartTime(sliderChangeInSeconds(slider1Value));
                        setIsDraggingTrimSlider(false);
                    }}
                    onMouseDown={() => setIsDraggingTrimSlider(true)}
                    />
                </div>
            </div>
            <div className="slider2Wrapper">
                <div className="slider"
                    ref={slider2WrapperRef}
                    style={{ width: `${slider2Value}%` }}>
                    <input className="rangeSlider" type="range" min="0" max="100" step={0.001} value={slider2Value} onChange={(e) => handleSlider2Change(Number(e.target.value))}
                    onMouseUp={() => {
                        incrementEndTime(sliderChangeInSeconds(slider2Value));
                        setIsDraggingTrimSlider(false);
                    }}
                    onMouseDown={() => setIsDraggingTrimSlider(true)}
                    />
                </div>
            </div>
        </div>
    )
}

interface adjustSensitivityButtonProps {
    currentSensitivityMultiplier: number;
    style?: React.CSSProperties;
    onClick: (sensitivityMultiplier: number) => void;
  }

  const AdjustSensitivityButton: React.FC<adjustSensitivityButtonProps> = ({ 
    currentSensitivityMultiplier,
    style,
    onClick }) => {
    const [showModal, setShowModal] = useState(false);
    const [isOptionSelected, setIsOptionSelected] = useState(false);

    const options = [0.25, 0.5, 1, 2, 4];
    const defaultOption = 1;
    const handleClick = (sensitivityMultiplier: number) => {
        onClick(sensitivityMultiplier);
        setShowModal(false);
        setIsOptionSelected(true);
    };

    const displayButtonText = (option: number) => {
        let text;
        if (option === Math.min(...options)) {
            text = "(even finer control)";
        } else if (option === Math.max(...options)) {
            text = "(jump even further)";
        } else if (option < defaultOption) {
            text = "(finer control)";
        } else if (option === defaultOption) {
            text = "(default)";
        } else if (option > defaultOption) {
            text = "(jump further)";
        }
        return (
          <div>
            {option}x {text}
          </div>
        );
      };

    useEffect(() => {
        if (isOptionSelected) {
            setShowModal(false);
        }
    }, [isOptionSelected])
  
    return (
        <div className="flex items-center justify-end mb-4 rounded-md p-2 w-1/4 z-10000" style={style}>

            <div
                // className="relative bg-gray-100 rounded-2xl w-10 h-10 justify-center items-center flex cursor-pointer shadow-sm hover:scale-105 duration-300"
                className="relative w-10 h-10 justify-center items-center flex cursor-pointer hover:scale-105 duration-300"
                onClick={() => setShowModal(!showModal)}
                onMouseLeave={() => setShowModal(false)}
            >
                <div className="text-gray-700 text-xl">⚙️</div>
                {showModal && (
                    <div
                    className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-60 rounded-xl shadow-lg bg-white z-1000 p-2 max-h-80 overflow-y-auto"
                    >
                        <div className="py-1 text-center">
                        <div className="text-sm font-bold text-center py-2">Adjust sensitivity of slider ⚙️</div>
                        <div className="text-sm text-center py-2">Current sensitivity: {currentSensitivityMultiplier}x</div>
                        {options.map((option) => (
                            <button
                            key={option}
                            className="w-full text-center block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 z-1000"
                            onClick={() => handleClick(option)}
                            >
                            {displayButtonText(option)}
                            </button>
                        ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
  };
  