import React, { useState, useEffect } from "react"
import { LogInOrOutButton } from "../LogInOrOutButton"

interface MenuBarProps {
    isLoggedIn: boolean
}

export const MenuBar = ({ isLoggedIn }: MenuBarProps) => {
    const [loggedIn, setLoggedIn] = useState<boolean | null>(localStorage.getItem('isLoggedIn') === "true");
    // Note for above: use context managers in the future

    useEffect(() => {
        setLoggedIn(isLoggedIn)
    }, [isLoggedIn])

    // useEffect(() => {
    //     console.log(`🔑 LOGGED IN: ${loggedIn}`)

    //     console.log(`IS LOGGED IN: ${isLoggedIn}`)
    //     setLoggedIn(isLoggedIn)
    //     console.log(`🔑 LOGGED IN: ${loggedIn}`)
    // }, [])

    const image = require('../../images/latte-logo-menu-bar.png')
    return (
        <div className="pt-10 flex flex-row items-center m-auto px-1 md:w-[60%] pb-6">
            <img src={image} className="h-10 ml-8 hover:cursor-pointer"
                onClick={() => {
                    window.location.href = "https://app.latte.social"
                }} />
            {
                loggedIn !== null && (
                    <div className="flex flex-row justify-center items-center space-x-12 ml-auto mr-8 text-[#777574]">
                        <h1 className="font-poppins font-medium text-sm hover:cursor-pointer">
                            <a href="https://app.latte.social">Home</a>
                        </h1>
                        <h1 className="font-poppins font-medium text-sm hover:cursor-pointer">
                            <a href="https://forms.gle/e4vrSk6FzN4ikQmB9" target="_blank">Support</a>
                        </h1>
                        {
                            (loggedIn !== true) &&
                            <>
                                {/* <h1 className="font-poppins font-medium text-sm hover:cursor-pointer">
                                    <a href="https://app.latte.social/video/upgrade">Upgrade</a>
                                </h1> */}
                                <h1 className="font-poppins font-medium text-sm hover:cursor-pointer">
                                    <a href="https://app.latte.social/video">Video</a>
                                </h1>
                            </>
                        }
                        <LogInOrOutButton loggedIn={loggedIn} />
                    </div>
                )
            }
        </div>
    )
}

export default MenuBar