export const setEmailLocalStorage = (email: string) => {
    localStorage.setItem('email', email);
}

export const unsetEmailLocalStorage = () => {
    localStorage.setItem('email', '');
}

export const setUserIsLoggedInLocalStorage = () => {
    localStorage.setItem('isLoggedIn', "true");
}

export const setUserIsLoggedOutLocalStorage = () => {
    localStorage.setItem('isLoggedIn', 'false');
}

export const getUserIsLoggedInLocalStorage = () => {
    return localStorage.getItem('isLoggedIn') === "true";
}