import React, { useEffect } from "react";
import { GeneralUpgradeModal, GeneralUpgradeModalProps } from "./GeneralUpgradeModal";

interface AutoCloseUpgradeModalProps extends GeneralUpgradeModalProps {
  autoCloseTimeout?: number;
}

export const AutoCloseUpgradeModal: React.FC<AutoCloseUpgradeModalProps> = ({
  autoCloseTimeout = 3000,
  ...props
}) => {
  useEffect(() => {
    if (props.isOpen) {
      const timer = setTimeout(() => {
        props.onClose();
      }, autoCloseTimeout);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [props.isOpen, props.onClose, autoCloseTimeout]);

  return <GeneralUpgradeModal {...props} />;
};
