import axios from 'axios';
import React, { useState, useEffect } from 'react';

import { LogInModal } from './LogInModal';

interface LogInOrOutButtonProps {
    loggedIn: boolean;
}

export const LogInOrOutButton = ({loggedIn}: LogInOrOutButtonProps) => {
    const [isLoggedIn, setIsLoggedIn] = useState(loggedIn);
    const [showLoggedInModal, setShowLoggedInModal] = useState(false);

    const checkIfLoggedIn = () => {
        const isLoggedIn = localStorage.getItem('isLoggedIn') === "true";
        setIsLoggedIn(isLoggedIn);
    }

    const logIn = async () => {
        setShowLoggedInModal(true);
    }

    const logOut = async () => {
        localStorage.setItem('email', '');
        localStorage.setItem('isLoggedIn', 'false');
        localStorage.setItem('token', '');
        setIsLoggedIn(false);
        window.open("https://app.latte.social/video", "_self");
    }

    useEffect(() => {
        checkIfLoggedIn();
    }, [loggedIn])


    return (
        <div>
            {
                isLoggedIn ? <LogoutButton logOut={logOut}/> : <LoginButton logIn={logIn}/>
            }
            {
                showLoggedInModal &&
                <LogInModal isOpen={showLoggedInModal} onClose={() => setShowLoggedInModal(false)}/>
            }
        </div>
    )
}

interface LoginButtonProps {
    logIn: () => void;
}

const LoginButton = ({logIn}: LoginButtonProps) => {
    return (
        <div>
            <button className="font-poppins font-semibold px-4 py-2 text-xs md:text-sm text-center bg-cover bg-new-gradient rounded-full hover:scale-90 duration-200"
            onClick={logIn}>
                <h1 className="text-white">
                    Log in
                </h1>
            </button>
        </div>
    )
}

interface LogoutButtonProps {
    logOut: () => void;
}

const LogoutButton = ({logOut}: LogoutButtonProps) => {
    return (
        <div>
            <button className="font-poppins font-semibold px-4 py-2 text-xs md:text-sm text-center bg-black rounded-full hover:scale-90 duration-200"
            onClick={logOut}>
                <h1 className="text-white">
                    Log out
                </h1>
            </button>
        </div>
    )
}