import React, { useState, useEffect } from 'react';
import axios from 'axios';

class VideoPostProcessor {
    constructor (

    ) {}

    public sendVideoToPostProcessor = async (entryID: string | null, videoID: string, repurposingInstructions: string[]) => {
        const requestData = {
            entryID: entryID,
            videoID: videoID,
            repurposingInstructions: repurposingInstructions
        }

        const response = await axios.post(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/post-processor`, requestData)

        console.log(`Sent video to post processor: ${videoID}, response: ${response.data}`)
    }
}

export default VideoPostProcessor;