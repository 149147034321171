import React, { useEffect, useState } from "react";
import { UserAccount } from "../../Types/DataTypes";
import AccountUsageView from "./AccountUsage";
import PreviousUploadsView from "./PreviousUploads";
import { UnsubscribeButton } from "../../pages/SignUp";

interface AccountContainerProps {
    userAccount: UserAccount;
}

const AccountContainer = ({ userAccount }: AccountContainerProps) => {
    const [showUploads, setShowUploads] = useState(true);
    const [showUsage, setShowUsage] = useState(false);

    const showUsageClicked = () => {
        setShowUsage(true);
        setShowUploads(false);
    }

    const showUploadsClicked = () => {
        setShowUploads(true);
        setShowUsage(false);
    }

    return (
        <div className="flex flex-col space-y-3 w-full">
            <AccountSectionButtonCollection
                showUsageClicked={showUsageClicked}
                showUploadsClicked={showUploadsClicked} />
            <div>
                {
                    showUploads &&
                    <PreviousUploadsView videoHistoryCollection={userAccount.videoHistory} />
                }
                {
                    showUsage &&
                    <AccountUsageView account={userAccount} />
                }
            </div>
        </div>
    )
}

interface AccountSectionButtonCollectionProps {
    showUploadsClicked: () => void;
    showUsageClicked: () => void;
}

const AccountSectionButtonCollection = ({ showUploadsClicked, showUsageClicked }: AccountSectionButtonCollectionProps) => {
    const [showUploadsSelected, setShowUploadsSelected] = useState(true);
    const [showUsageSelected, setShowUsageSelected] = useState(false);

    const showUploadsButtonClicked = () => {
        showUploadsClicked();
        setShowUsageSelected(false);
        setShowUploadsSelected(true);
    }

    const showUsageButtonClicked = () => {
        showUsageClicked();
        setShowUsageSelected(true);
        setShowUploadsSelected(false);
    }

    return (
        <div className="flex flex-row space-x-4 w-[70%] justify-center m-auto">
            <AccountSectionButton handleClick={showUploadsButtonClicked} isSelected={showUploadsSelected} title="Previous Uploads 🗂" />
            <AccountSectionButton handleClick={showUsageButtonClicked} isSelected={showUsageSelected} title="Account 💻" />
        </div>
    )
}

interface AccountSectionButtonsProps {
    handleClick: () => void;
    isSelected: boolean;
    title: string;
}

const AccountSectionButton = ({ handleClick, isSelected, title }: AccountSectionButtonsProps) => {
    const [isSelectedState, setIsSelectedState] = useState(isSelected);

    useEffect(() => {
        setIsSelectedState(isSelected);
    }, [isSelected])

    return (
        <button className="font-poppins font-semibold px-2 text-xs md:text-sm text-center border-2 border-gray-400 rounded-full w-1/2 h-12 hover:scale-90 duration-200"
            onClick={() => {
                handleClick()
            }}
            style={{
                backgroundColor: isSelectedState ? "#fed391" : "#FFFFFF",
                borderWidth: isSelectedState ? "0px" : "2px"
            }}>
            {title}
        </button>
    )
}

export default AccountContainer;