import React, { useRef, useEffect, useState, ChangeEventHandler, FormEventHandler } from "react"
import axios from "axios"

export interface RepurposingCompletionListenerProps {
    handleCompletion: () => void;
    entryID: string;
}

export const RepurposingCompletionListener = ({ handleCompletion, entryID }: RepurposingCompletionListenerProps) => {
    console.log("ENTRY ID IN COMPLETION LISTENER:", entryID)
    let [completionProgressText, setCompletionProgressText] = useState<string>("")
    const [showCompletionLink, setShowCompletionLink] = useState(false)

    console.log("COMPLETION LISTENER RENDERED, COMPLETION PROGRESS TEXT:", completionProgressText, "SHOW COMPLETION LINK:", showCompletionLink)

    const intervalRef = useRef<NodeJS.Timeout>()

    const displayInferenceProgress = (updateUIWithProgess: (progress: string) => void) => {
        let inferenceTime = 0
        let suffix = "..."
    
        intervalRef.current = setInterval(() => {
            console.log("inferenceTime:", inferenceTime)
    
            inferenceTime += 1
    
            if (inferenceTime % 6 === 0) {
                suffix = ""
            } else if (inferenceTime % 5 === 0) {
                suffix = "."
            } else if (inferenceTime % 4 === 0) {
                suffix = ".."
            } else if (inferenceTime % 3 === 0) {
                suffix = "..."
            } else if (inferenceTime % 2 === 0) {
                suffix = ".."
            } else if (inferenceTime % 1 === 0) {
                suffix = "."
            }
    
            if (inferenceTime >= 0 && inferenceTime < 60) {
                updateUIWithProgess(`Mobilising our AI baristas${suffix} 🤖`)
                console.log("suffix:", suffix)
            } else if (inferenceTime >= 60 && inferenceTime < 180) {
                updateUIWithProgess(`Renting a GPU server from the local coffee shop${suffix} 📠`)
                console.log("suffix:", suffix)
            } else if (inferenceTime >= 180 && inferenceTime < 240) {
                updateUIWithProgess(`Meticulously analysing the speech${suffix} 🔎`)
            } else if (inferenceTime >= 240 && inferenceTime < 300) {
                updateUIWithProgess(`Taking a quick coffee break${suffix} ☕️`)
            } else if (inferenceTime >= 300 && inferenceTime < 360) {
                updateUIWithProgess(`Speaking to the Oracle${suffix} 🔮`)
            } else if (inferenceTime >= 360 && inferenceTime < 420) {
                updateUIWithProgess(`Hand-pouring the videos${suffix} 🫗`)
            } else if (inferenceTime === 420) {
                updateUIWithProgess(`Frothing the content${suffix} 🫧`)
                clearInterval(intervalRef.current)
            }
        }, 1000)
    }

    useEffect(() => {
        console.log("ENTRY ID IN COMPLETION LISTENER:", entryID)

        displayInferenceProgress(setCompletionProgressText)

        const es = new EventSource(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/real-time-updates?updateType=start-listening&entryID=${entryID}&isWebClient=true`);

        es.onmessage = (event) => {
            console.log("real time event:", event.data);
            if (event.data.includes("video-repurposing-completed") || event.data.includes("video-post-processing-completed")) {
                handleCompletion();
                clearInterval(intervalRef.current)
                setShowCompletionLink(true)
                setCompletionProgressText("Your videos are ready 🎉")
                es.close();
            }
        }

        return () => {
            es.close();
            clearInterval(intervalRef.current)
        };
    }, [])

    return (
        <div className="flex flex-col justify-center">
            <h1 className="font-poppins">{completionProgressText}</h1>
            {
                showCompletionLink &&
                <a href={`https://app.latte.social/video-snippets?entryID=${entryID}`}
                    className="text-center text-sm underline font-poppins text-[#545454]"
                    target="_blank"
                    onClick={() => {
                        axios.get(`https://latte-analytics-server-dwr7snkjfq-ew.a.run.app/latte-video?entryID=${entryID}&event=results-link-clicked`)
                    }}>
                    Click here to view
                </a>
            }
        </div>
    )
}