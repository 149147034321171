import axios from "axios";
import { QueryBasedVideoEntry } from "../../Types/DataTypes";

export default class VideoDownloader {
    public getVideoPath = async (videoID: string) => {
        const videoMetadataEndpoint = `https://latte-video-server-dwr7snkjfq-ew.a.run.app/get-video-metadata`
        const params = {
            videoID: videoID
        }
        const response = await axios.get(videoMetadataEndpoint, { params: params })
        const videoPath = response.data.videoPath || null
        console.log(`Video path for videoID ${videoID}: ${videoPath}`)
        return videoPath
    }

    public getSignedURLFromVideoPath = async (videoPath: string) => {
        const response = await axios.post(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/internal-video-download?videoName=${videoPath}`)

        return response.data.url[0];
    }

}