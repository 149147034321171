import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { VideoOrSummaryContainerDisplay } from "../Types/Enums";
import BaseButton from "../Components/EssentialComponents/BaseButton";

interface ISrtComponent {
    videoID: string;
    orientation: string;
    setVideoContainerDisplayMode: (displayMode: VideoOrSummaryContainerDisplay) => void;
    shouldSendRequestToUpdateSubtitles: boolean;
    setShouldSendRequestToUpdateSubtitles: (shouldSendRequest: boolean) => void;
    setShouldRefreshSnippets: (shouldRefresh: boolean) => void;
    setVideosArePostProcessing: (videosArPostProcessing: boolean) => void;
}

export const SrtComponent = ({ videoID, orientation, setVideoContainerDisplayMode, shouldSendRequestToUpdateSubtitles, setShouldSendRequestToUpdateSubtitles, setShouldRefreshSnippets, setVideosArePostProcessing }: ISrtComponent) => {
    const [srtObjectArray, setSrtObjectArray] = useState<any>(null);

    const getVideoMetadata = async (videoID: string) => {
        const response = await axios.get(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/get-video-metadata?videoID=${videoID}`);
        // console.log(`Fetching video metadata for videoID: ${videoID}`);
        return response.data;
    }

    const parseSrtStringToSrtObjectArray = (srtString: string) => {
        const srtArray = srtString.split("\n\n").filter((srtLine: string) => srtLine !== "");
        const srtObjectArray = srtArray.map((srtLine: string) => {
            const srtLineArray = srtLine.split("\n");
            const srtObject = {
                index: srtLineArray[0],
                time: srtLineArray[1],
                text: srtLineArray[2],
            }
            return srtObject;
        });
        return srtObjectArray;
    }

    const getSrtArray = async (videoID: string, orientation: string) => {
        const videoMetadata = await getVideoMetadata(videoID);

        console.log(`videoMetadata: ${videoMetadata}`);

        let srtArray = null;

        if (orientation === "portrait") {
            srtArray = videoMetadata.srtStringPortrait;
        } else {
            srtArray = videoMetadata.srtStringLandscape;
        }

        if (srtArray === undefined || srtArray === null) {
            setVideoContainerDisplayMode(VideoOrSummaryContainerDisplay.Error);
        }

        console.log(`Existing srtArray: ${srtArray.slice(0, 100)}`);

        return parseSrtStringToSrtObjectArray(srtArray);
    }

    const handleSrtTextChange = (index: string, text: string) => {
        // find the srtObject in srtObjectArray with the matching index
        // update the text property of that srtObject
        // update the srtObjectArray
        console.log(index, text);
        const newSrtObjectArray = srtObjectArray.map((srtObject: any) => {
            if (srtObject.index === index) {
                srtObject.text = text;
            }
            return srtObject;
        });
        setSrtObjectArray(newSrtObjectArray);
    }

    const srtInputBox = (srtObject: any) => {
        // Display box for each srtObject in srtObjectArray
        return (
            <div key={srtObject.index}>
                {/* <h1>{srtObject.index}</h1> */}
                <input
                    className="flex font-poppins text-center w-full outline-none p-1 rounded-md focus:border-[1.5px] border-pink-200"
                    onChange={(e) => handleSrtTextChange(srtObject.index, e.target.value)}
                    type="text" value={srtObject.text} />
            </div>
        )
    }

    const reconstructSrtLineFromSrtObject = (srtObject: any) => {
        // Returns empty string if srtObject.text is empty
        if (srtObject.text === "") {
            return "";
        }
        return `${srtObject.index}\n${srtObject.time}\n${srtObject.text}`;
    }

    // Saves new srt text to videoMetadata collection
    const handleSaveButtonClick = async () => {
        const updateVideoMetadataEndpoint = "https://latte-video-server-dwr7snkjfq-ew.a.run.app/update-video-metadata"
        const field = orientation === "portrait" ? "srtStringPortrait" : "srtStringLandscape";
        const newSrtString = srtObjectArray.map((srtObject: any) => 
            reconstructSrtLineFromSrtObject(srtObject))
            .filter((srtLine: string) => srtLine !== "")
            .join("\n\n");
        const payload = {
            videoID: videoID,
            field: field,
            value: newSrtString,
        }
        console.log(`New srt string: ${newSrtString.slice(0, 100)}`);

        const res = await axios.post(updateVideoMetadataEndpoint, payload)

        console.log(`Response from update-video-metadata: ${res.data}`)

        setVideoContainerDisplayMode(VideoOrSummaryContainerDisplay.Video)

        setShouldRefreshSnippets(true);
        setVideosArePostProcessing(true);
    }

    const SaveButton = () => {

        return (
            <BaseButton 
                onClickFunction={handleSaveButtonClick} 
                text={{
                    default: "Save",
                    clicked: "Saving...",
                    completed: "Saved!"
                }}
            />
        );
    }


    useEffect(() => {
        if (videoID && orientation) {
            getSrtArray(videoID, orientation).then((srtArray) => {
                setSrtObjectArray(srtArray);
            });
        }
      }, [videoID]);

    useEffect(() => {
        console.log("should send request to update subtitles: Last checkpoint")
        if (shouldSendRequestToUpdateSubtitles) {
            console.log("Sending request to update subtitles")
            handleSaveButtonClick();
            setShouldSendRequestToUpdateSubtitles(false);
        }
    }, [shouldSendRequestToUpdateSubtitles])

    useEffect(() => {
        return () => {
            console.log("Unmounting SrtComponent");
        }
    }, [])

    return (
        <div className="flex flex-col justify-center items-center w-full space-y-3 overflow-clip">
            <div className="flex flex-col w-full overflow-y-scroll">
            {srtObjectArray &&
                srtObjectArray.map((srtObject: any) => srtInputBox(srtObject))
            }
            </div>
            <SaveButton />
        </div>
    )
}

// const SrtEditPage = () => {
//     const [queryParams, setQueryParams] = useSearchParams();
//     const [videoID, setVideoID] = useState<string>(queryParams.get("videoID") || "");
//     const [orientation, setOrientation] = useState<string>(queryParams.get("orientation") || "");

//     return (
//         <div>
//             <h1>Video ID: {videoID}</h1>
//             <h1>Orientation: {orientation}</h1>
//             <SrtComponent videoID={videoID} orientation={orientation} />
//         </div>
//     )
// }

// export default SrtEditPage;
  
  