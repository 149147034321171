import React, { useState, useEffect, memo } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

import AccountContainer from './AccountArea/AccountContainer';
import { ListeningEndpoints, UserAccount } from '../Types/DataTypes';
import FileAndLinkUploadContainer from './UploadOptions/FileAndLinkUploadContainer';
import SubmitUploadContainer from './UploadOptions/SubmitUploadContainer';
import { ProgressBar, PageHeader } from '../Components/EssentialComponents';
import MenuBar from '../Components/EssentialComponents/MenuBar';
import ClipDurationSelector from './UploadOptions/ClipDurationSelector';
import { NotificationModal } from '../Modals/NotificationModal';
import { UpgradeModal } from '../Modals/UpgradeModal';
import { GeneralUpgradeModal } from '../Modals/GeneralUpgradeModal';
import ModeSelectionButton from './UploadOptions/ModeSelectionButton';
import LoadingNotificationModal from '../Components/EssentialComponents/LoadingNotificationModal';
import QueryBasedFlowCompletionListener from '../ResultsPages/QueryResultsPage/QueryResultsCompletionListener';
import AutopilotCompletionListener from '../Components/EssentialComponents/AutopilotCompletionListener';
import LoginOrSignUpModal from '../Modals/LoginOrSignUpModal';
import MixpanelTracker from '../HelperClasses/Analytics/MixpanelTracker';
import socket from '../socket';
import { AutoCloseUpgradeModal } from '../Modals/AutoCloseModal';
import SomethingWrongButton from '../Components/EssentialComponents/SomethingWrongButton';
import TopBanner from '../Components/TopBanner';


import EntryUploader from '../HelperClasses/Networking/EntryUploader';
import UserManager from '../HelperClasses/Networking/UserManager';

import { setEmailLocalStorage, unsetEmailLocalStorage, setUserIsLoggedInLocalStorage, setUserIsLoggedOutLocalStorage, getUserIsLoggedInLocalStorage } from '../HelperClasses/loginHelperFunctions';

import { VideoUploadMode } from '../Types/Enums';

interface VideoHomePageProps {
    uploadMode: VideoUploadMode 
}

interface IEntrySubmissionProps {
    entryID: string,
    desiredDuration: number,
    email: string,
    link: string | undefined
}

const VideoHomePage = ({ uploadMode }: VideoHomePageProps) => {
    const [userAccount, setUserAccount] = useState<UserAccount>();
    const [showUploadOptions, setShowUploadOptions] = useState(false);
    const [showSubmitUpload, setShowSubmitUpload] = useState(false);
    const [fileToUpload, setFileToUpload] = useState<File | null>(null)
    const [linkToUpload, setLinkToUpload] = useState<string | undefined>(undefined)
    const [mixpanelSessionID, setMixpanelSessionID] = useState<string>("")
    const [isLoggedIn, setIsLoggedIn] = useState(getUserIsLoggedInLocalStorage())
    const [email, setEmail] = useState<string | null>(localStorage.getItem('email'));
    const [showPendingVideosProcessingModal, setShowPendingVideosProcessingModal] = useState(false);
    const [showLoggedInModal, setShowLoggedInModal] = useState(false);
    const [queryParams, setQueryParams] = useSearchParams();
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [userRequiresUpgrade, setUserRequiresUpgrade] = useState(false);
    const [validFileSupplied, setValidFileSupplied] = useState(false)
    const [infoLabelText, setInfoLabelText] = useState("Now, enter your email. We'll send over a link to your edited videos 📧")
    const [desiredClipDuration, setDesiredClipDuration] = useState(90)
    const [hasSubmitted, setHasSubmitted] = useState(false)
    const [fileUploadProgress, setFileUploadProgress] = useState(0)
    const [showPaidUpgradeModal, setShowPaidUpgradeModal] = useState(false)
    const [uploadModeState, setUploadMode] = useState(VideoUploadMode.Autopilot)
    const [queryBasedVideosAreProcessing, setQueryBasedVideosProcessing] = useState(false)
    const [autopilotVideosAreProcessing, setAutopilotVideosAreProcessing] = useState(false)
    const [showLoadingNotificationModal, setShowLoadingNotificationModal] = useState(false)
    const [entryID, setEntryID] = useState("")
    const [loadingCompleted, setLoadingCompleted] = useState(false)
    const [showWarningModal, setShowWarningModal] = useState(false)
    const [showTrialUpgradeModal, setShowTrialUpgradeModal] = useState(false)
    const [isAuthorized, setIsAuthorized] = useState(false)
    const [showSignUpOrLoginModal, setShowSignUpOrLoginModal] = useState(false)
    const [subscriptionStatus, setSubscriptionStatus] = useState<string | null>(null);
    const [entrySubmissionReady, setEntrySubmissionReady] = useState(false);
    const [entrySubmissionProps, setEntrySubmissionProps] = useState<IEntrySubmissionProps | null>(null);
    const [showV2MigrationModal, setShowV2MigrationModal] = useState<boolean>(false)
    
    const mixpanelTracker = new MixpanelTracker();

    // Token can either be a query param or a local storage item
    const token = queryParams.get("token") || localStorage.getItem("token");
    const hasJustUpgraded = queryParams.get("hasJustUpgraded");

    const userManager = new UserManager()

    // Use Effect for entry submission 

    useEffect(() => {
        const submitEntry = async (entrySubmissionProps: IEntrySubmissionProps) => {
            const { entryID, desiredDuration, email, link } = entrySubmissionProps;
            setShowLoadingNotificationModal(true)
            setAutopilotVideosAreProcessing(true)
            setHasSubmitted(true)
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            })
            await entryUploader.startAutopilotFlow(entryID, desiredDuration, email, link);
            console.log("⚡️ Entry submitted")
        };
    
        if (!entrySubmissionReady || !entrySubmissionProps) {
            return;
        }
    
        if (linkToUpload) {
            submitEntry(entrySubmissionProps);
        } else if (fileUploadProgress === 100) {
            submitEntry(entrySubmissionProps);
        }
    
    }, [entrySubmissionProps, entrySubmissionReady, fileUploadProgress, linkToUpload]);
    

    const handleSubmitAttempt = (isSuccessful: boolean, message: string) => {
        if (isSuccessful) {
            setHasSubmitted(true)
        } else {
            setHasSubmitted(false)
        }
    }

    const handleLoginAttempt = async (isSuccessful: boolean, email: string) => {
        if (isSuccessful) {
            setEmailLocalStorage(email)
            setUserIsLoggedInLocalStorage()
            setIsLoggedIn(true)

            mixpanelTracker.track("🪵 User Logged In", email)

            const userAccount = await userManager.getUserAccount(email)

            if (userAccount.pendingVideosToProcess && userAccount.pendingVideosToProcess.length > 0) {
                // console.log("pending videos to process")
                setShowPendingVideosProcessingModal(true)
            } else {
                setShowLoggedInModal(true)
            }

            setUserAccount(userAccount)
            setUserRequiresUpgrade(userAccount.requiresUpgrade)
        } else {
            unsetEmailLocalStorage()
            setUserIsLoggedOutLocalStorage()
            setIsLoggedIn(false)
        }
    }

    const handleAdvancedFlowProcessingCompleted = () => {
        setQueryBasedVideosProcessing(false)
        setLoadingCompleted(true)
        console.log("ADVANCED FLOW PROCESSING COMPLETED")
    }

    const handleAutopilotFlowProcessingCompleted = () => {
        setAutopilotVideosAreProcessing(false)
        setLoadingCompleted(true)
        console.log("AUTOPILOT FLOW PROCESSING COMPLETED")
    }

    // refactor this into state and put at top of functional component - much cleaner!
    const entryUploader = new EntryUploader(
        setShowUpgradeModal,
        handleSubmitAttempt
    )

    const setupPage = async () => {
        // console.log(`Email: ${email}`);
        
        if (token) {
            // unset email
            unsetEmailLocalStorage();
            localStorage.setItem("token", token);
            const authorized = await userManager.authenticateUser(
                localStorage.getItem("token") || "",
                handleLoginAttempt
            );
            setIsLoggedIn(authorized);
        } else {
            // console.log(`❌ No token found`);
            unsetEmailLocalStorage();
            setUserIsLoggedOutLocalStorage();
            setIsLoggedIn(false);
        }
      
        if (hasJustUpgraded !== null) {
          setShowPaidUpgradeModal(true);
        }
      
        if (isLoggedIn) {
            let userEmail = localStorage.getItem('email')
            console.log(`Email: ${userEmail}`);
            setEmail(userEmail || "");
        //   console.log(`Fetching user account for ${userEmail}`);
            const user = await userManager.getUserAccount(userEmail || "");
            setUserAccount(user);
            setUserRequiresUpgrade(user.requiresUpgrade);
        }
      
        setUploadMode(uploadMode);
      };
      

    useEffect(() => {
        console.log(`Email set to ${localStorage.getItem('email')}`)
        setEmail(localStorage.getItem('email'))
    }, [isLoggedIn])

    useEffect(() => {
        userManager.getUserAccount(email).then((userAccount) => {
            setUserAccount(userAccount)
        })
        
        userManager.userSubmissionAllowed(email).then((allowed) => {
            setIsAuthorized(allowed)
        })

    }, [email])

    useEffect(() => {
        setupPage()
    }, [uploadMode])

    const handleFileSupplied = (isValid: boolean, file?: File | null) => {
        if (!file || !isValid) return;
        console.log(`File size: ${file.size}`)
        if (!isLoggedIn) {
            setShowSignUpOrLoginModal(true)
            return
        }

        if (email) {
            setShowV2MigrationModal(true)
        }
        
        if (file) {
            setFileToUpload(file)
            setLinkToUpload(undefined)
            setValidFileSupplied(isValid)
            // setFileUploadProgress(0)

        }
        // Generate new entryID here
        const newEntryID = generateEntryID(email!)
        setEntryID(newEntryID)
    }

    // Use Effect to handle file uploads on valid file supplied
    useEffect(() => {   
        console.log(`Entry ID: ${entryID}`)
        console.log(`File to upload: ${fileToUpload}`)
        console.log(`Valid file supplied: ${validFileSupplied}`)
        console.log(`Email: ${email}`)
        if (validFileSupplied && email && entryID && fileToUpload) {
            console.log("⚡️ Valid file supplied")
            entryUploader.uploadFile(email!, entryID, fileToUpload!, setFileUploadProgress)
        }
    }, [validFileSupplied, fileToUpload])

    // useEffect(() => {
    //     console.log("this rannnnn")
    //     if (email) {
    //         setShowV2MigrationModal(true)
    //     }
    // }, [email])

    const handleLinkSupplied = (isValid: boolean, link?: string) => {
        if (!isLoggedIn) {
            setShowSignUpOrLoginModal(true)

            return
        }

        if (email) {
            setShowV2MigrationModal(true)
        }

        setValidFileSupplied(isValid)

        if (link) {
            setLinkToUpload(link)
            setFileToUpload(null)
        }
        
        // Generate new entryID here
        const newEntryID = generateEntryID(email!)
        setEntryID(newEntryID)
    }

    const submitEntry = async (userEmail: string) => {
        // Check if user is on trial
        // if yes, check if they have submitted at least 1 video request.
        // if yes, show upgrade modal
        const userSubmissionAllowed = await userManager.userSubmissionAllowed(userEmail)

        if (!userSubmissionAllowed) {
            userManager.getSubscriptionStatus(email).then((status) => {
                setSubscriptionStatus(status);
                // subscription status is null if no subscription exists
            });
            // There should be a modal saying that they need to upgrade to use the service
            setShowTrialUpgradeModal(true)
            setIsAuthorized(false)
            console.log("User is not authorized to submit videos")
            console.log(`🚨 Is Authorized: ${isAuthorized}`)
            return
        }

        setIsAuthorized(true)

        setInfoLabelText(`Hold on a second while we upload your video 🚀`)

        if (!isLoggedIn) {
            setShowSignUpOrLoginModal(true)
            setShowLoadingNotificationModal(false)

            const requestBody = {
                email: userEmail,
            }

            axios.post("https://latte-video-task-manager-dwr7snkjfq-ew.a.run.app/waitlist-entries", requestBody)

            return
        }

        if (hasSubmitted || !fileToUpload && !linkToUpload) {
            return
        }
        
        const newEntryID = entryID ? entryID : generateEntryID(userEmail)

        if (fileToUpload) {
            // await entryUploader.uploadFile(userEmail, newEntryID, fileToUpload, setFileUploadProgress)
        } else if (linkToUpload) {
            await entryUploader.uploadLink(newEntryID, linkToUpload)
        }

        mixpanelTracker.track("✨ User Submitted Entry", userEmail, {
            "entryID": newEntryID,
            "desiredDuration": desiredClipDuration,
            "uploadedFile": fileToUpload ? true : false,
        })

        const entrySubmissionArgs = {
            entryID: newEntryID,
            desiredDuration: desiredClipDuration,
            email: userEmail,
            link: linkToUpload,
        }
        setEntrySubmissionProps(entrySubmissionArgs)
        setEntrySubmissionReady(true)
        
        // await entryUploader.startAutopilotFlow(newEntryID, desiredClipDuration, userEmail, linkToUpload)
    }

    const TrialUpgradeModal = (userHasSubscription: boolean) => {
        console.log("User has subscription: ", userHasSubscription);
        const title = "Upgrade Now 🚀";
        const buttonText = "Upgrade Now";
        let message;
        let buttonClickFunction;
        if (userHasSubscription) {
            message = "To continue using Latte, click the button below to end your free trial now! ⚡️"
            buttonClickFunction = async () => {
                const userTrialEndResponse = await userManager.endUserTrial(email);
                if (userTrialEndResponse) {
                    setShowTrialUpgradeModal(false)    
                }
            }

        } else {
            message = "To continue using Latte, click the button below to upgrade to a paid plan! ✨"
            buttonClickFunction = async () => {
                // direct user to payment plan page
                window.open("https://app.latte.social/video/upgrade", "_self");
                setShowTrialUpgradeModal(false)    
            }
        }
        return (
            <GeneralUpgradeModal
                isOpen={showTrialUpgradeModal}
                onClose={() => {
                    setShowTrialUpgradeModal(false)
                }}
                title={title}
                message={message}
                buttonText={buttonText}
                buttonClickFunction={buttonClickFunction}
            />
        )
    }

    interface UploadMessageProps {
        isUploadComplete: boolean;
    }
    
    const UploadMessage: React.FC<UploadMessageProps> = ({ isUploadComplete }) => {
        const message = isUploadComplete
            ? 'Upload complete! You can safely leave the page after clicking submit. ✅'
            : 'Please keep this window open until your video is fully uploaded. This may take a few minutes. ⚡️';
    
        return (
            <div className='text-center text-sm text-gray-500 mt-2 font-poppins'>
                {message}
            </div>
        );
    };

    return (
        <div className="bg-[#fffbf9] pb-10 min-h-[2000px]">
            <TopBanner />
            <MenuBar isLoggedIn={isLoggedIn}/>
            <div className="relative flex flex-col justify-center items-center bg-[#fffbf9] space-y-8 px-3">
                <PageHeader isLoggedIn={isLoggedIn} />
                <FileAndLinkUploadContainer
                handleFileSupplied={handleFileSupplied} handleLinkSupplied={handleLinkSupplied} />
                {
                    fileUploadProgress > 0 && fileToUpload && (
                        <div className="w-3/4 md:w-1/3">
                            {fileUploadProgress < 100 &&
                            <ProgressBar progressPercentage={fileUploadProgress} showPercentage={true} />}
                            
                            {
                                validFileSupplied && <UploadMessage isUploadComplete={fileUploadProgress === 100} />
                            }
                        </div>
                    )
                }
                {
                    uploadModeState === VideoUploadMode.Autopilot && validFileSupplied &&
                    <ClipDurationSelector handleSelectedClipDuration={setDesiredClipDuration} />
                }
                {
                    validFileSupplied &&
                    <SubmitUploadContainer 
                        key={`${entryID}-${hasSubmitted}`}
                        submitEntry={submitEntry} 
                        isLoggedIn={isLoggedIn} 
                        isAuthorized={isAuthorized} 
                        hasSubmitted={hasSubmitted}
                    />
                }
            </div>
            {
                queryBasedVideosAreProcessing &&
                <QueryBasedFlowCompletionListener entryID={entryID} initialProcessingCompletedCallback={handleAdvancedFlowProcessingCompleted} endpoint={ListeningEndpoints.InitialProcessing} />
            }
            {
                autopilotVideosAreProcessing &&
                <AutopilotCompletionListener entryID={entryID} completionCallback={handleAutopilotFlowProcessingCompleted} />
            }
            {
                showLoadingNotificationModal && !userRequiresUpgrade &&
                <LoadingNotificationModal
                    setIsOpen={setShowLoadingNotificationModal}
                    timeToCompletion={1200}
                    message="Hang tight, we're processing your video 🪄"
                    doneButtonLink={`https://app.latte.social/video-advanced?entryID=${entryID}`}
                    isCompletedProp={loadingCompleted} 
                    socket={socket}
                    taskID={entryID}
                />
            }
            {
                userAccount &&
                <div className="md:w-1/3 w-full m-auto mt-20">
                    <AccountContainer userAccount={userAccount} />
                    <SomethingWrongButton />
                </div>
            }
            {/* {
                showPendingVideosProcessingModal &&
                <NotificationModal isOpen={showPendingVideosProcessingModal} onClose={() => setShowPendingVideosProcessingModal(false)} title="Thank you! ✨" message="You're now logged in to Latte, and your videos are being created. We'll send you an email when they arrive 📨"/>
            } */}
            {
                showLoggedInModal &&
                // <NotificationModal isOpen={showLoggedInModal} onClose={() => setShowLoggedInModal(false)} title="Welcome back!" message="We hope you're having a great day ✨"/>
                <AutoCloseUpgradeModal 
                    isOpen={showLoggedInModal} 
                    onClose={() => setShowLoggedInModal(false)} 
                    title="Welcome back!" 
                    message="We hope you're having a great day ✨"
                    className="fixed z-20 mx-auto mt-4 inset-0 h-28 p-4 shadow-lg w-11/12 md:w-[500px] rounded-2xl bg-white border-0 border-[#ffd9c1]"
                    />
            }
            {
                showPaidUpgradeModal &&
                <NotificationModal isOpen={showPaidUpgradeModal} onClose={() => setShowPaidUpgradeModal(false)} title="Thank you so much! ❤️" message="Thanks for signing up for a paid Latte account - your support really means the world to us 🌎"/>
            }
            {
                showSignUpOrLoginModal &&
                <LoginOrSignUpModal setIsModalOpen={setShowSignUpOrLoginModal} />
            }
            {
                showUpgradeModal &&
                <UpgradeModal isOpen={showUpgradeModal}
                onClose={() => {
                    setShowUpgradeModal(false)
                    setValidFileSupplied(false)
                }} />
            }
            {
                showTrialUpgradeModal &&
                TrialUpgradeModal(subscriptionStatus !== null)
            }
            {
                showV2MigrationModal &&
                <CompleteMigrationModal email={email} />
            }

        </div>
    )
}

const generateRandomString = (length: number) => {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    let result = ""
    for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)]
    return result
}

const generateEntryID = (userEmail: string) => {
    console.log(`Generating entry ID for user ${userEmail}`)
    return userEmail + "_" + generateRandomString(6)
}

export default VideoHomePage

interface CompleteMigrationModalProps {
    email: string | null
}

const CompleteMigrationModal = (props: CompleteMigrationModalProps) => {

    if (!props.email) return null

    return (
        <div className="fixed w-full h-full inset-0 z-50 bg-black bg-opacity-50 box-border">
            <div className="absolute inset-0 mx-auto my-auto max-h-fit w-[420px] bg-white justify-center flex flex-col py-6 px-10 space-y-4 items-center rounded-2xl shadow-lg z-50">
                <h1 className="font-poppins font-semibold text-center">
                    We've moved to a brand new version! Latte V2 is faster, more powerful, and has many more features. Click below to continue.
                </h1>
                <button className="w-[300px] py-7 rounded-full bg-[#333333] font-poppins font-semibold text-white"
                onClick={() => {
                    window.open(`https://platform.latte.social/create?v1Email=${props.email}`)
                }}>
                    Continue to V2 🚀
                </button>
            </div>
        </div>
    )
}
