import React, { useState, useEffect, FormEventHandler } from 'react';
import EmailForm  from '../../Components/EssentialComponents/EmailForm';
import { NotificationModal } from '../../Modals/NotificationModal';
import { RepurposingCompletionListener } from '../../HelperClasses/RepurposingCompletionListener';
import axios from 'axios';

interface SubmitUploadContainerProps {
    submitEntry: (userEmail: string) => void;
    isLoggedIn: boolean;
    isAuthorized?: boolean;
    hasSubmitted: boolean;
}

const SubmitUploadContainer = ({ submitEntry, isLoggedIn, isAuthorized, hasSubmitted }: SubmitUploadContainerProps) => {
    return (
        <div className="flex flex-col space-y-6 w-full items-center justify-center">
            {
                isLoggedIn ?
                    <SubmitButton submitEntry={submitEntry} isAuthorized={isAuthorized} hasSubmitted={hasSubmitted} />
                    :
                    <EmailSubmitForm uploadFile={submitEntry} infoLabelText="Enter your email to upload your video" />
            }
        </div>
    )
}

interface SubmitButtonProps {
    submitEntry: (userEmail: string) => void;
    isAuthorized?: boolean;
    hasSubmitted: boolean;
}

export const SubmitButton = ({ submitEntry, isAuthorized, hasSubmitted }: SubmitButtonProps) => {
    const email = localStorage.getItem('email') || "";
    const [progressButtonText, setProgressButtonText] = useState(hasSubmitted ? "✅" : "Submit 🚀");
    // console.log(`IS AUTHORIZED: ${isAuthorized}`)
    const progressButtonEmojis = (updateUIWithProgess: (progress: string) => void) => {
        let i = 0;
        const interval = setInterval(() => {
            i++;
            updateUIWithProgess("🚀".repeat(i));
            if (i === 5) {
                clearInterval(interval);
                updateUIWithProgess("✅");
            }
        }, 1000)
    }

    const handleClick = () => {
        submitEntry(email);
        if (!hasSubmitted) {
            setProgressButtonText("Please wait... ☕️")
        } else {
            progressButtonEmojis(setProgressButtonText);
        }
        
    }

    return (
        <div className="flex flex-col space-y-6 w-full items-center justify-center">
            <div className='w-64 h-20 bg-white rounded-[30px] m-auto text-white hover:text-black hover:scale-90 duration-300'>
                <button className='w-full h-full rounded-[30px] bg-main-gradient bg-cover'
                    onClick={handleClick}>
                    <h1 className='font-poppins font-semibold text-center'>{progressButtonText}</h1>
                </button>
            </div>
        </div>
    )
}

interface EmailSubmitFormProps {
    uploadFile: (userEmail: string) => void;
    infoLabelText: string;
}

const EmailSubmitForm = ({ uploadFile, infoLabelText }: EmailSubmitFormProps) => {
    const onEmailSubmit = async (email: string) => {
        uploadFile(email);

        // const magicLinkResponse = await axios.get(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/send-magic-link?email=${email}`)

        // console.log("MAGIC LINK RESPONSE:", magicLinkResponse.data)

        // window.scrollTo({
        //     top: 0,
        //     left: 0,
        //     behavior: 'smooth'
        // })
    }

    return (
        <div className="flex flex-col space-y-3 items-center justify-center w-96">
            <EmailForm onEmailSubmit={onEmailSubmit} />
            <h1 className="text-center text-sm font-poppins text-[#545454]">
                {infoLabelText}
            </h1>
        </div>
    )
}

export default SubmitUploadContainer