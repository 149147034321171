export enum VideoOrSummaryContainerDisplay {
    Video,
    Summary,
    None,
    Vertical,
    Subtitles,
    VerticalAndSubtitles,
    Transcript,
    EditDuration,
    EditSubtitles,
    Error
}

export enum VideoUploadMode {
    Autopilot,
    Advanced,
    None
}

export enum OriginalVideoDisplayMode {
    Video,
    Summary,
    Transcript,
}