import React, { useRef, useEffect, useState, ChangeEventHandler, FormEventHandler } from "react"

interface NotificationModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    message: string;
}

export const NotificationModal = ({ isOpen, onClose, title, message }: NotificationModalProps) => {
    return (
        <div className="fixed z-20 mx-auto mt-4 inset-0 h-28 p-4 shadow-lg w-11/12 md:w-[500px] rounded-2xl bg-white border-0 border-[#ffd9c1]"
            style={
                isOpen ? { display: "flex" } : { display: "none" }
            }>
            <div className="flex flex-col space-y-3 items-center justify-center h-full w-full bg-white">
                <h1 className="font-poppins font-semibold text-2xl text-center">{title}</h1>
                <p className="font-poppins font-normal text-sm text-center">{message}</p>
            </div>
            <button className="absolute top-0 right-0 m-4 font-poppins"
                onClick={onClose}>
                Close
            </button>
        </div>
    )
}