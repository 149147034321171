import React, { useEffect, useState } from 'react';
import LoadingBar from './LoadingBar';

interface TimedProgressBarProps {
  progress: number;
  timeToCompletion: number;
  setParentProgress?: (progress: number) => void;
}

export const TimedProgressBar = ({ progress, timeToCompletion, setParentProgress}: TimedProgressBarProps) => {
  const [adjustedProgress, setProgress] = useState(progress * 100);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    
    if (progress < 1) {
        interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 99) {
                    clearInterval(interval);
                    return prevProgress;
                } else {
                    const increment = 100 / (timeToCompletion * 1000 / 100);
                    const newProgress = prevProgress + increment;
                    return Math.min(newProgress, 99);
                }
            });
        }, 100);
    }
    return () => {
        clearInterval(interval);
    };

  }, [progress, adjustedProgress, timeToCompletion]);

  useEffect(() => {
    setParentProgress && setParentProgress(adjustedProgress / 100);
  }, [adjustedProgress, setParentProgress]);

  return <LoadingBar progress={adjustedProgress} />;
};

export default TimedProgressBar;
