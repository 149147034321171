import React, { ChangeEventHandler, FormEventHandler, useEffect, useState } from "react";
import axios from "axios";
import { RxCross2 } from "react-icons/rx"; 

import EmailForm from "../Components/EssentialComponents/EmailForm";
import { LogInModal } from "../Components/LogInModal";
import UserManager from '../HelperClasses/Networking/UserManager';

interface LoginOrSignUpModalProps {
    setIsModalOpen: (isModalOpen: boolean) => void;
}

const LoginOrSignUpModal = ({ setIsModalOpen }: LoginOrSignUpModalProps) => {
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [magicLinkResponse, setMagicLinkResponse] = useState<string | null>(null);
    const [emailExists, setEmailExists] = useState<boolean>(false);
    const userManager = new UserManager()

    const handleEmailSubmit = async (email: string) => {
        const accountExists = await userManager.getUserAccount(email);
        if (!accountExists) {
            setMagicLinkResponse("We couldn't find an account with that email 🤔 Click here to start trial");
            return;
        }

        console.log(`💌 Email submitted: ${email}`)
        const magicLinkResponse = await axios.get(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/send-magic-link?email=${email}`)

        let errorMessage = magicLinkResponse.data.message;

        if (errorMessage) {
            // should never be triggered, realistically
            setMagicLinkResponse("Woops, something went wrong, we've dispatched a team of AI baristas to fix it 🤖")
        } else {
            console.log("MAGIC LINK RESPONSE:", magicLinkResponse.data)
            setMagicLinkResponse("Thanks! Check your email for a magic link 🪄")
            setEmailExists(true);
        }
    }

    const handleNoAccountButtonClick = () => {
        window.location.href = "/video/upgrade"
        setIsModalOpen(false);
    }

    return (
        <div className="fixed z-50 mx-auto my-auto inset-0 flex-col justify-center space-y-3 items-center bg-white min-h-28 max-h-52 flex py-2 px-4 w-[500px] rounded-2xl shadow-md">
            {
                !magicLinkResponse && 
                <h1 className="font-poppins font-medium text-center">
                    Oops! You're not logged in. Login or start a free trial to create clips from this video 🔑
                </h1>
            }
            <div className="absolute top-0 right-2">
                <button className="font-poppins"
                    onClick={() => setIsModalOpen(false)}>
                    <RxCross2 className="text-2xl" />
                </button>
            </div>
            {
                isLoggingIn &&
                // <EmailForm onEmailSubmit={handleEmailSubmit} />
                <div className="bg-white flex flex-row w-full h-10 border-0 border-[#f3efef] shadow-sm focus-within:border-2 focus-within:shadow-none rounded-2xl px-5 items-center justify-center">

                    <LogInModal isOpen={true} onClose={() => {
                        setIsLoggingIn(false);
                        setIsModalOpen(false);
                    }} />
                </div>
            }
            {
                !isLoggingIn &&
                <LoginOrSignUpButtons setIsLoggingIn={setIsLoggingIn} />
            }
            {
                magicLinkResponse && !emailExists &&
                <button className="text-center text-sm font-poppins text-[#545454]"
                onClick={handleNoAccountButtonClick}>
                    {magicLinkResponse}
                </button>
            }
            {
                emailExists &&
                <h1 className="text-center text-sm font-poppins text-[#545454]">
                    {magicLinkResponse}
                </h1>
            }

        </div>
    )

}

interface LoginOrSignUpButtonsProps {
    setIsLoggingIn: (isLoggingIn: boolean) => void;
}

const LoginOrSignUpButtons = ({ setIsLoggingIn }: LoginOrSignUpButtonsProps) => {

    const handleLoginClick = () => {
        setIsLoggingIn(true);
    }

    const handleStartFreeTrialClick = () => {
        window.location.href = "/video/upgrade"
    }

    return (
        <div className="flex flex-col space-y-3">
            <button className="font-poppins bg-new-gradient font-medium text-white rounded-full px-8 py-3"
            onClick={handleStartFreeTrialClick}>
                SIGN UP
            </button>
            <button className="font-poppins text-gray-400 text-xs"
            onClick={handleLoginClick}>
                Already got an account? Log in
            </button>
        </div>
    )
}

export default LoginOrSignUpModal;