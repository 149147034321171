import axios from 'axios';

class MixpanelTracker {
    constructor() {
      // Initialize any necessary class properties if needed
    }
  
    track(event, email, properties = {}) {
      // Execute the sendTrackingRequest method asynchronously
      setTimeout(() => this.sendTrackingRequest(event, email, properties), 0);
    }
  
    sendTrackingRequest(event, email, properties) {
      const payload = {
        event,
        email,
        properties,
      };
      // console.log(`📈 Sending tracking request for event: ${event}`);
  
      axios.post('https://latte-analytics-server-dwr7snkjfq-ew.a.run.app/track', payload)
        .then(response => {
          if (response.status === 200) {
            // console.log('Event tracked:', event);
          } else {
            // console.error('Error tracking event:', event);
          }
        })
        .catch(error => {
          // console.error('Error tracking event:', event, error);
        });
    }
}

export default MixpanelTracker;