import React, { useState, useEffect } from 'react';

interface IBaseButtonProps {
    onClickFunction: () => void | Promise<void>;
    text?: {
        default: string;
        clicked: string;
        completed: string;
    };
    className?: string;
    style?: React.CSSProperties;
    [key: string]: any; // Allows any additional event handlers or props
}

const BaseButton: React.FC<IBaseButtonProps> = ({
    onClickFunction,
    text = {
        save: 'Save',
        saving: 'Saving...',
        saved: 'Saved',
    },
    className,
    style,
    ...otherProps // Captures any other props passed to the component
}) => {
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isSaved, setIsSaved] = useState<boolean>(false);

    useEffect(() => {
        if (isSaved) {
            const timer = setTimeout(() => {
                setIsSaved(false);
            }, 3000); // Change text back to default after 3000ms (3 seconds)

            return () => clearTimeout(timer);
        }
    }, [isSaved]);

    const buttonClickAction = async () => {
        setIsSaving(true);
        setIsSaved(false);

        await onClickFunction();

        setIsSaving(false);
        setIsSaved(true);
    };

    const buttonText = isSaving ? text.clicked : isSaved ? text.completed : text.default;

    return (
        <button
            className={`font-poppins bg-main-gradient bg-cover rounded-2xl w-20 relative py-2 hover:scale-110 duration-300 ${className} text-sm`}
            onClick={buttonClickAction}
            disabled={isSaving}
            style={style}
            {...otherProps} // Spreads any additional props (e.g., event handlers) onto the button
        >
            {buttonText}
        </button>
    );
};

export default BaseButton;
