import { createContext } from 'react';
import { IVideoMetadataEntry } from '../Types/DataTypes';

interface CurrentlyViewingContextType {
    currentlyViewingVideoID: string | null;
    setCurrentlyViewingVideoID: (id: string | null) => void;
    currentlyViewingVariantType: string[] | null;
    setCurrentlyViewingVariantType: (type: string[] | null) => void;
    currentlyViewingVideoMetadataEntry: IVideoMetadataEntry | null;
    setCurrentlyViewingVideoMetadataEntry: (entry: IVideoMetadataEntry | null) => void;
    setShowRepurposingInstructionsSentModal: (show: boolean) => void;
    setPostProcessedVideoURL: (url: string | null) => void;
    setShowVideoSentToBeRerenderedModal: (show: boolean) => void;
  }

const CurrentlyViewingContext = createContext<CurrentlyViewingContextType>({
    currentlyViewingVideoID: null,
    setCurrentlyViewingVideoID: (id: string | null) => {},
    currentlyViewingVariantType: null,
    setCurrentlyViewingVariantType: (type: string[] | null) => {},
    currentlyViewingVideoMetadataEntry: null,
    setCurrentlyViewingVideoMetadataEntry: (entry: IVideoMetadataEntry | null) => {},
    setShowRepurposingInstructionsSentModal: (show: boolean) => {},
    setPostProcessedVideoURL: (url: string | null) => {},
    setShowVideoSentToBeRerenderedModal: (show: boolean) => {},
});

export default CurrentlyViewingContext;