import React, { useState, useEffect, useContext } from "react";
import { fetchVideoMetadata, notifySubtitlesEdited, updateVideoMetadata } from "../HelperFunctions/VideoMetadataHelpers";
import BaseButton from "../Components/EssentialComponents/BaseButton";
import CurrentlyViewingContext from "../ContextManagers/CurrentlyViewingContext";


interface ISubtitleSequenceEditComponent {
    saveButtonCallback?: () => void;
}

interface ISubtitleSequence {
    idx: number;
    lines: string[][];
    frames: number[][][];
    start_frame: number;
    end_frame: number;
    emoji: string;
}

export const SubtitleSequenceEditComponent = ({
    saveButtonCallback,
}: ISubtitleSequenceEditComponent) => {
    const [subtitleSequenceArray, setSubtitleSequenceArray] = useState<ISubtitleSequence[] | null>(null);
    const [videoID, setVideoID] = useState<string | null>(null);
    const {
        currentlyViewingVideoID,
        currentlyViewingVariantType,
        currentlyViewingVideoMetadataEntry,
    } = useContext(CurrentlyViewingContext);
    const [subtitleSequenceFieldName, setSubtitleSequenceFieldName] = useState<string>("");

    useEffect(() => {
        if (currentlyViewingVariantType?.length == 1 && currentlyViewingVariantType.includes("subtitles")) {
            setSubtitleSequenceFieldName("subtitleSequencesLandscape");
            return;
        } 

        if (currentlyViewingVariantType?.length == 2 && currentlyViewingVariantType.includes("vertical") && currentlyViewingVariantType.includes("subtitles")) {
            setSubtitleSequenceFieldName("subtitleSequencesPortrait");
            return;
        }

        if (currentlyViewingVariantType?.length == 2 && currentlyViewingVariantType.includes("magic-subtitles") && currentlyViewingVariantType.includes("vertical")) {
            setSubtitleSequenceFieldName("subtitleSequencesPortraitMagic");
            return;
        }

        if (videoID !== currentlyViewingVideoID) {
            setVideoID(currentlyViewingVideoID);
        }

    }, [currentlyViewingVariantType, currentlyViewingVideoMetadataEntry, currentlyViewingVideoID])

    useEffect(() => {
        if (currentlyViewingVideoID) {
            setVideoID(currentlyViewingVideoID);
            console.log(`⚡️ Set videoID to ${currentlyViewingVideoID}`);
        }
    }, [])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, subtitleSequenceIdx: number, lineIdx: number, wordIdx: number, isEmoji: boolean = false) => {
        const value = event.target.value;
        setSubtitleSequenceArray(prevState => {
            if (!prevState) {
                return null;
            }
            const updatedSubtitleSequenceArray = [...prevState];
            const updatedSubtitleSequence = { ...updatedSubtitleSequenceArray[subtitleSequenceIdx] };
            if (isEmoji) {
                updatedSubtitleSequence.emoji = value;
            } else {
                updatedSubtitleSequence.lines[lineIdx][wordIdx] = value;
            }
            updatedSubtitleSequenceArray[subtitleSequenceIdx] = updatedSubtitleSequence;
            return updatedSubtitleSequenceArray;
        });
    };

    const subtitleSequenceLineDisplay = (subtitleSequence: ISubtitleSequence) => {
        const { idx, lines, start_frame, end_frame, emoji } = subtitleSequence;

        // return columns of idx and lines
        return (
            <div key={idx} className="flex flex-col items-center mb-4 pt-4">

                {lines.map((line, lineNumber) => (
                    <div key={`${idx}-${lineNumber}`} className="flex justify-center flex-wrap items-center">
                        {line.map((word, wordIndex) => {
                        // Remove all non-alphanumeric characters from the word
                        const filteredWord = word.replace(/[^a-zA-Z0-9]/g, '');
                        return (
                            <div key={`${idx}-${lineNumber}-${wordIndex}`} className="inline-block">
                                <input
                                    className="font-poppins text-center outline-none rounded-md border border-transparent focus:border-[1.5px] hover:border hover:border-pink-200 min-w-[2rem]"
                                    style={{ width: `${filteredWord.length * 0.9}rem` }}
                                    type="text"
                                    value={word}
                                    onChange={(event) => handleInputChange(event, idx, lineNumber, wordIndex)}
                                />
                            </div>
                        );
                        })}
                    </div>))
                }
                {
                    (emoji !== null) && (
                        <div key={`${idx}-emoji`} className="flex justify-center items-center">
                            <input
                                className="font-poppins text-center outline-none rounded-md border border-transparent focus:border-[1.5px] hover:border hover:border-pink-200 min-w-[2rem]"
                                style={{ width: `${emoji.length * 0.9}rem` }}
                                type="text"
                                value={emoji}
                                onChange={(event) => handleInputChange(event, idx, 0, 0, true)}
                            />
                        </div>
                    )
                }
            </div>
        );
    }

    const SaveButton = () => {
        const triggerSaveAction = async () => {
            if (subtitleSequenceArray) {    
                await updateVideoMetadata(videoID!, subtitleSequenceFieldName, subtitleSequenceArray);
                saveButtonCallback && saveButtonCallback();
                await notifySubtitlesEdited(videoID!, currentlyViewingVariantType!)
            }
        }

        return (
            <BaseButton 
                onClickFunction={triggerSaveAction} 
                text={{
                    default: "Save",
                    clicked: "Saving...",
                    completed: "Saved!"
                }}
            />
        );
    }

    useEffect(() => {
        if (!videoID) {
            return;
        }

        const getVideoMetadataAndSetSubtitleSequence = async (videoID: string) => {
            const videoMetadata = await fetchVideoMetadata(videoID, [subtitleSequenceFieldName]);
            // console.log(`Retrieved video metadata for ${videoID}: ${JSON.stringify(videoMetadata)}`);
            const subtitleSequenceArray = videoMetadata[subtitleSequenceFieldName];
            setSubtitleSequenceArray(subtitleSequenceArray);
        }
        getVideoMetadataAndSetSubtitleSequence(videoID);
      }, [videoID]);


    return (

        <div className="flex flex-col justify-center items-center w-full space-y-3 max-h-full">
            <div className="overflow-y-scroll font-poppins text-sm scrollbar-custom h-full max-h-80">

                {subtitleSequenceArray && subtitleSequenceArray.map((subtitleSequence: ISubtitleSequence, index: number) => (
                    <React.Fragment key={`subtitleSequence-${index}`}>
                        {subtitleSequenceLineDisplay(subtitleSequence)}
                        {index !== subtitleSequenceArray.length - 1 && (
                            <hr className="my-2 border-pink-200 w-1/2 mx-auto" />
                        )}
                    </React.Fragment>
                ))}
            </div>
            {
                videoID &&
                <SaveButton />
            }
            
        </div>
    )
}

  