import React, { useState, useEffect } from 'react';
import { VideoHistoryObject } from '../../Types/DataTypes';
import axios from 'axios';

interface VideoHistoryProps {
    videoHistoryCollection: VideoHistoryObject[];
}

const PreviousUploadsView = ({videoHistoryCollection}: VideoHistoryProps) => {
    if (!videoHistoryCollection || videoHistoryCollection.length === 0) {
        return (
            <div className="flex flex-col space-y-3 items-center justify-center mt-10">
                <h1 className="font-poppins font-semibold text-center">You haven't uploaded any videos yet 📹</h1>
                <h1 className="font-poppins text-sm text-center">Upload a video to get started!</h1>
            </div>
        )
    }

    return (
        <div className="flex flex-col space-y-6 w-full items-center justify-center">
            <div className="flex flex-col w-full h-96 overflow-y-scroll p-3 pb-5">
                <div className="space-y-5">
                    {
                        videoHistoryCollection.map((entry) => {
                            return (
                                <div>
                                    <VideoHistoryItem entry={entry} />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

interface VideoHistoryItemProps {
    entry: VideoHistoryObject;
}

const VideoHistoryItem = ({ entry }: VideoHistoryItemProps) => {
    const [thumbnailURL, setThumbnailURL] = useState("");

    const formattedDate = new Date(entry.creationTimestamp * 1000).toDateString();

    const setThumbnail = async () => {
        const response = await axios.get(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/get-video-thumbnails?thumbnailFilename=${entry.thumbnailFilename}`)

        setThumbnailURL(response.data.url[0]);
    }

    const handleClick: React.MouseEventHandler<HTMLDivElement> = () => {
        // if flowType exists && flowType == "advanced" && 
        // (entry.repurposedVideos not exists || entry.repurposedVideos.length == 0)
        // then direct to video-query page, else direct to video-snippets page
        const videoSnippetsPage = "https://app.latte.social/video-snippets?entryID="
        const videoQueryPage = "https://app.latte.social/video-advanced?entryID="
        console.log(entry)

        if (entry.creationTimestamp < 1679250757) {
            window.open(`${videoSnippetsPage}${entry.entryID}`, "_self");
        } else {
            window.open(`${videoQueryPage}${entry.entryID}`, "_self");
        }

        // if (entry.repurposedVideosUncut !== undefined && entry.repurposedVideosUncut.length > 0) {
        //     window.open(`${videoQueryPage}${entry.entryID}`, "_self");
        // } else {
        //     window.open(`${videoSnippetsPage}${entry.entryID}`, "_self");
        // }
    }

    useEffect(() => {
        setThumbnail()
    }, [])

    return (
        <div className="flex flex-row shadow-lg shadow-[#f9e2d0b7] rounded-full w-full h-24 bg-white cursor-pointer items-center hover:scale-90 duration-300 pr-6"
            onClick={handleClick}>
            <div className="flex rounded-xl w-14 h-14 ml-10">
                <img className="rounded-xl h-full w-full" src={thumbnailURL} alt="Video Thumbnail"
                style={{objectFit: "cover"}}/>
            </div>
            <div className="flex flex-col space-y-0 ml-6">
                <h1 className="font-poppins text-base font-medium">{entry.title}</h1>
                <h2 className="font-poppins text-sm text-[#aaaaaa] font-normal">{formattedDate}</h2>
            </div>

        </div>
    )
}

export default PreviousUploadsView;