import React from "react";

const V2MigrationPage = () => {
    const email = localStorage.getItem("email")

    if (!email) {
        alert("Hmm, we couldn't find an email associated with your account. Make sure you're logged in, and try again")
        return <></>
    }

    const encodedEmail = encodeURI(email)

    const handleClick = () => {
        const migrationLink = "https://app.latte.video/projects?v1Email=" + encodedEmail

        window.open(migrationLink, "_blank")
    }

    return (
        <div className="w-screen h-screen bg-[#333333] flex justify-center items-center">
            <button className="w-72 h-24 bg-white text-[#333333] font-poppins font-semibold rounded-full hover:scale-150 transition-all duration-500"
            onClick={handleClick}>
                Migrate to V2
            </button>
        </div>
    )
}

export default V2MigrationPage