import axios from "axios";
import { QueryBasedVideoEntry } from "../../Types/DataTypes";

export default class QueryBasedResultsDownloader {
    constructor(
        shouldAttachCompletionListener: (shouldAttach: boolean) => void,
        originalVideoFetchCompletionCallback?: (isSuccessful: boolean, videoURL: string) => void,
        snippetsFetchCompletionCallback?: (isSuccessful: boolean, snippets: QueryBasedVideoEntry[]) => void,
        snippetsSaveCompletionCallback?: (isSuccessful: boolean, message: string, videoID: string) => void) {
        this.shouldAttachCompletionListener = shouldAttachCompletionListener;
        this.originalVideoFetchCompletionCallback = originalVideoFetchCompletionCallback;
        this.snippetsFetchCompletionCallback = snippetsFetchCompletionCallback;
        this.snippetsSaveCompletionCallback = snippetsSaveCompletionCallback;
    }

    originalVideoFetchCompletionCallback?: (isSuccessful: boolean, videoURL: string) => void;
    shouldAttachCompletionListener: (shouldAttach: boolean) => void;
    snippetsFetchCompletionCallback?: (isSuccessful: boolean, snippets: QueryBasedVideoEntry[]) => void;
    snippetsSaveCompletionCallback?: (isSuccessful: boolean, message: string, videoID: string) => void;

    public fetchOriginalVideoURL = async (entryID: string) => {
        const response = await axios.get(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/video-clips?entryID=${entryID}`);
        // console.log("🔥 Response from fetchOriginalVideoURL:", response.data)
        let videoPath = response.data.videoPath;

        const isYTLink = response.data.link !== null; 

        if (isYTLink === false) {
            videoPath = videoPath.replace("_video.mp4", "")
        }

        const videoURL = await this.downloadVideoFromSignedUrl(videoPath)

        this.originalVideoFetchCompletionCallback?.(true, videoURL)
    }

    public createSnippets = async (entryID: string, queryText: string, selectedDuration: number) => {
        const requestBody = {
            entryID: entryID,
            query: queryText,
            desiredDuration: selectedDuration,
            numClipsToReturn: 2
        }

        const response = await axios.post("https://latte-video-nl-query-manager-uhhxc4xeaq-ew.a.run.app", requestBody);

        if (response.status === 200) {
            //console.log("Succesfully sent clips to be created:", response.data)
            this.shouldAttachCompletionListener(true);
        }
    }

    public fetchEntry = async (entryID: string) => {
        const response = await axios.get(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/video-clips?entryID=${entryID}`);

        if (response.status === 200) {
            //console.log("Successfully fetched entry:", response.data)
            return response.data
        }
    }

    public fetchSnippets = async (entryID: string) => {
        const response = await axios.get(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/video-clips?entryID=${entryID}`);

        if (response.status === 200) {
            //console.log("Successfully fetched snippets:", response.data)
            this.snippetsFetchCompletionCallback?.(true, response.data.repurposedVideosUncut)
        }
    }

    public sendRequestToSaveAndTrimSnippet = async (entryID: string, snippet: QueryBasedVideoEntry) => {
        const requestBody = {
            entryID: entryID,
            videoID: snippet.videoID,
        }
        
        console.log(`Sending request to save and trim snippet: ${requestBody.videoID}`)

        const response = await axios.post("https://latte-video-ffmpeg-nl-query-uhhxc4xeaq-ew.a.run.app/trimVideos", requestBody);

        if (response.status === 200) {
            //console.log("Successfully saved and trimmed snippet:", response.data)
            this.snippetsSaveCompletionCallback?.(true, "Successfully saved snippet", snippet.videoID)
        }
    }

    public downloadVideoFromSignedUrl = async (videoID: string) => {
        const response = await axios.post(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/internal-video-download?videoName=${videoID}`)
    
        return response.data.url[0];
    }

    public downloadVideoFromVideoID = async (videoID: string) => {
        const filename = videoID + ".mp4"

        //console.log("Downloading video from videoID:", filename)

        const response = await axios.post(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/internal-video-download?videoName=${filename}`)

        return response.data.url[0];
    }
}