import React from "react";

const SomethingWrongButton = () => {
    return (
        <div className="w-full text-center justify-center items-center mt-20">
            <a
            className="font-poppins text-sm text-gray-500 hover:text-gray-700 hover:underline"
            href="https://forms.gle/e4vrSk6FzN4ikQmB9"
            target="_blank">Something Wrong? Tap for support</a>
        </div>
    )
}

export default SomethingWrongButton;