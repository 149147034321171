import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { MenuBar } from './SignUp';
import ReactPlayer from 'react-player';
import { BsFillArrowRightCircleFill } from "react-icons/bs"

import { RepurposingCompletionListener } from '../HelperClasses/RepurposingCompletionListener';
import { PostProcessedVideoModal } from '../Modals/PostProcessedVideoModal';
import { ProgressBar } from '../Components/EssentialComponents';
import { UpgradeModal } from '../Modals/UpgradeModal';
import { UserAccount } from '../Types/DataTypes';

import UserManager from '../HelperClasses/Networking/UserManager';

let videosToBeMadeVertical: PostProcessingRequest[] = [];
let videosToAddSubtitlesTo: PostProcessingRequest[] = [];

const ResultsPage = () => {
    let [queryParams, setQueryParams] = useSearchParams();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userRequiresUpgrade, setUserRequiresUpgrade] = useState<boolean>(false);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);

    const checkIfLoggedIn = () => {
        const isLoggedIn = localStorage.getItem('isLoggedIn') === "true";
        setIsLoggedIn(isLoggedIn);
    }

    const checkIfUserRequiresUpgrade = async () => {
        const entryID = queryParams.get("entryID") ?? "";
        const email = localStorage.getItem('email') || parseEmail(entryID);
        const requiresUpgrade = await axios.get(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/get-upgrade-status?email=${email}`)
        console.log(requiresUpgrade.data.requiresUpgrade)
        setUserRequiresUpgrade(requiresUpgrade.data.requiresUpgrade);
    }

    useEffect(() => {
        checkIfUserRequiresUpgrade();
    }, [])

    useEffect(() => {
        const entryID = queryParams.get("entryID");
        axios.get(`https://latte-analytics-server-dwr7snkjfq-ew.a.run.app/latte-video?entryID=${entryID}&event=page-view&page=Video-Results-Page`)
        checkIfLoggedIn();
    }, [])

    return (
        <div className="bg-[#FFFBF9]">
            <MenuBar isLoggedIn={isLoggedIn}/>
            <div className="relative flex flex-col min-h-screen bg-[#fffbf9] m-auto p-2 md:p-6 items-center space-y-10 mt-20">
                <PageHeader />
                <ResultsCollection userRequiresUpgrade={userRequiresUpgrade}/>
                <FeedbackSection />
                {/* <StartRepurposeButton/> */}
            </div>
        </div>
    )
}

const PageHeader = () => {
    return (
        <div className="flex flex-col justify-center space-y-2">
            <h1 className="font-medium font-poppins text-4xl text-center"> 
                Latte's Magic Clips 🪄
            </h1>
        </div>
    )
}

interface VideoEntry {
    videoURL: string;
    caption: string;
    videoID: string;
    thumbnailURL?: string;
    postProcessedVideos?: PostProcessedVideoEntry[];
}

interface PostProcessedVideoEntry {
    videoURL: string;
    postProcessingApplied: string[];
    thumbnailURL?: string;
}

interface AllVideosForOriginalEntry {
    repurposedVideos: VideoEntry[];
    postProcessedVideos?: PostProcessedVideoEntry[];
}

interface ResultsCollectionProps {
    videoEntries?: VideoEntry[];
    userRequiresUpgrade: boolean;
}

interface PostProcessingRequest {
    clipID: string;
    taskSent: boolean;
}

const ResultsCollection = ({ userRequiresUpgrade }: ResultsCollectionProps) => {
    console.log("ResultsCollection rendered")
    let [videoEntries, setVideoEntries] = useState<VideoEntry[]>();
    let [queryParams, setQueryParams] = useSearchParams();
    let [percentLoaded, setPercentLoaded] = useState(0);
    let [numberOfVideosDownloaded, setNumberOfVideosDownloaded] = useState(0);
    let [originalVideoURL, setOriginalVideoURL] = useState<string>();
    let [originalVideoTitle, setOriginalVideoTitle] = useState<string>();
    let [showScrollArrow, setShowScrollArrow] = useState(false);
    let [showStartRepurposeButton, setShowStartRepurposeButton] = useState(false);

    const updatePercentLoaded = (percent: number) => {
        setPercentLoaded(percent);
    }

    const updateNumberVideosDownloaded = (number: number) => {
        setNumberOfVideosDownloaded(number);
    }

    const showStartRepurposeButtonHandler = (shouldShow: boolean) => {
        setShowStartRepurposeButton(shouldShow);
    }

    async function getVideoEntries() {
        const videoEntryID = queryParams.get("entryID");
        if (videoEntryID) {
            await downloadVideos(videoEntryID, updatePercentLoaded, updateNumberVideosDownloaded)
                .then((videoEntries) => {
                    setVideoEntries(videoEntries);
                    if (videoEntries.length > 3) {
                        setShowScrollArrow(true);
                    }
                    if (videoEntries.length === 0) {
                        setOriginalVideoTitle("We're sorry, there was an error processing your videos. We're working to scale our systems asap, hang tight!")
                    }
                })
        }
    }

    async function getOriginalVideoURL() {
        const videoEntryID = queryParams.get("entryID");
        if (videoEntryID) {
            const response = await axios.get(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/video-clips?entryID=${videoEntryID}`);
            if (response.data.link) {
                console.log("Original video url: ", response.data.link)
                setOriginalVideoURL(response.data.link);
                setOriginalVideoTitle("Original video")
            } else {
                downloadVideoFromSignedUrl(videoEntryID)
                    .then((videoURL) => {
                        setOriginalVideoURL(videoURL);
                        setOriginalVideoTitle("Original video")
                    })
                    .catch((error) => {
                        setOriginalVideoTitle("Oops, error fetching original video url")
                    })
            }
        }
    }

    useEffect(() => {
        getVideoEntries();
        getOriginalVideoURL();
    }, []);

    // This handles the initial render when the videoEntries state is undefined
    if (!videoEntries) {
        return (
            <div className="flex flex-col justify-center h-80 w-full space-y-4">
                <h1 className="font-medium font-poppins text-4xl text-center">
                    {`Loading video ${numberOfVideosDownloaded}`}
                </h1>
                <div className="w-1/2 flex m-auto">
                    <ProgressBar progressPercentage={percentLoaded} />
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col w-full md:w-4/5 p-1 md:p-4 justify-center m-auto items-center space-y-4">
            <div className="flex flex-col md:flex-row md:space-x-3 m-auto w-full justify-center items-center"
                onMouseEnter={
                    (e) => {
                        setShowScrollArrow(false);
                    }}
                onMouseLeave={
                    (e) => {
                        if (videoEntries && videoEntries.length > 2) {
                            setShowScrollArrow(true);
                        }
                    }}>
                <div className="flex m-auto justify-center w-full items-center p-4 md:items-start flex-col md:flex-row md:justify-start space-y-4 md:space-y-0 md:space-x-6 overflow-y-scroll md:overflow-x-scroll">
                    {
                        videoEntries.map((entry) => {
                            return (
                                <ResultsCard videoEntry={entry} displayStartRepurposeButton={showStartRepurposeButtonHandler} userRequiresUpgrade={userRequiresUpgrade}/>
                            )
                        })
                    }
                </div>
                <div>
                    {
                        showScrollArrow &&
                        <BsFillArrowRightCircleFill className="text-[#777574] text-2xl animate-bounce invisible md:visible" />
                    }
                </div>
            </div>
            <div className="flex justify-center w-full">
                <a className="font-poppins text-[#777574] text-xs underline text-center"
                    href={originalVideoURL}
                    target="_blank">
                    {originalVideoTitle}
                </a>
            </div>
            <StartRepurposeButton shouldShow={showStartRepurposeButton}/>
        </div>
    )
}

interface ResultsCardProps {
    videoEntry: VideoEntry;
    displayStartRepurposeButton: (shouldShow: boolean) => void;
    userRequiresUpgrade: boolean;
}

const ResultsCard = ({videoEntry, displayStartRepurposeButton, userRequiresUpgrade}: ResultsCardProps) => {

    let videoIsPostProcessed = false
    if (videoEntry.postProcessedVideos) {
        videoIsPostProcessed = true;
    }

    return (
        <div className="flex md:flex-shrink-0 flex-col justify-center w-full md:w-96 rounded-3xl space-y-4 bg-white p-4 shadow-lg shadow-[#ffeddfb7]">
            {
                videoIsPostProcessed ?
                    <RepurposedResultsCollection videoEntry={videoEntry} displayStartRepurposeButton={displayStartRepurposeButton} userRequiresUpgrade={userRequiresUpgrade} /> :
                    <OriginalResultsCard videoEntry={videoEntry} displayStartRepurposeButton={displayStartRepurposeButton} userRequiresUpgrade={userRequiresUpgrade}/>
            }
            <ReformatButtons displayStartRepurposeButton={displayStartRepurposeButton} video={videoEntry} />
            <DownloadButton downloadURL={videoEntry.videoURL} userRequiresUpgrade={userRequiresUpgrade}/>
        </div>
    )
}

const OriginalResultsCard = ({ videoEntry, displayStartRepurposeButton }: ResultsCardProps) => {
    return (
        <div className="h-96 flex flex-col space-y-2">
            <div className="h-1/2 w-full overflow-y-scroll rounded-2xl p-3">
                <h1 className='text-black text-sm text-left font-poppins w-full h-36'>
                    {videoEntry.caption}
                </h1>
            </div>
            <div className="h-1/2 w-full rounded-2xl flex justify-center">
                <video className='rounded-2xl bg-white'
                onContextMenu={ (e) => e.preventDefault()} controls={true} playsInline={true} autoPlay={false} preload="metadata" controlsList="nodownload">
                    <source src={videoEntry ? videoEntry.videoURL : ""} type="video/mp4" />
                </video>
            </div>
        </div>
    )
}

const RepurposedResultsCollection = ({ videoEntry, displayStartRepurposeButton, userRequiresUpgrade }: ResultsCardProps) => {
    let [showPostProcessedVideoModal, setShowPostProcessedVideoModal] = useState(false);
    let [postProcessedVideoToShowOnModal, setPostProcessedVideoToShowOnModal] = useState("");

    const originalVideoAsRepurposedVideo: PostProcessedVideoEntry = {
        videoURL: videoEntry.videoURL,
        postProcessingApplied: [],
    }

    const handleShowPostProcessedVideoModal = (videoURL: string) => {
        console.log("handleShowPostProcessedVideoModal URL is: " + videoURL)
        setPostProcessedVideoToShowOnModal(videoURL);
        setShowPostProcessedVideoModal(true);
    }

    return (
        <div className="h-96 w-full flex flex-col overflow-y-scroll">
            <div className="space-y-3">
                {
                    videoEntry.postProcessedVideos?.map((video) => {
                        return (
                            <RepurposedResultCard videoEntry={video} handleShowPostProcessedVideoModal={handleShowPostProcessedVideoModal} />
                        )
                    })
                }
                <OriginalResultsCard videoEntry={videoEntry} displayStartRepurposeButton={displayStartRepurposeButton} userRequiresUpgrade={userRequiresUpgrade}/>
            </div>
            {showPostProcessedVideoModal &&
                <PostProcessedVideoModal videoURL={postProcessedVideoToShowOnModal} isOpen={showPostProcessedVideoModal} onClose={() => setShowPostProcessedVideoModal(false)} userRequiresUpgrade={userRequiresUpgrade}/>
            }
        </div>
    )
}

interface RepurposedResultCardProps {
    videoEntry: PostProcessedVideoEntry;
    handleShowPostProcessedVideoModal: (videoURL: string) => void;
}

const RepurposedResultCard = ({ videoEntry, handleShowPostProcessedVideoModal }: RepurposedResultCardProps) => {
    let title = "";

    if (videoEntry.postProcessingApplied.includes("vertical")) {
        title = "Vertical";
    }

    if (videoEntry.postProcessingApplied.includes("subtitles")) {
        title = "Subtitles";
    }

    if (videoEntry.postProcessingApplied.includes("vertical") && videoEntry.postProcessingApplied.includes("subtitles")) {
        title = "Vertical + Subtitles";
    }

    if (videoEntry.postProcessingApplied.length === 0) {
        title = "Original";
    }

    return (
        <div className="h-52 w-full rounded-2xl flex flex-shrink-0 relative bg-white">
            <div className="absolute z-10 top-4 left-4 bg-main-gradient bg-cover rounded-full px-3 py-1 justify-center items-center shadow-sm hover:scale-110 duration-300 hover:underline"
                onClick={() => {
                    handleShowPostProcessedVideoModal(videoEntry.videoURL);
                }}>
                <h1 className="text-black text-sm font-poppins text-center cursor-pointer">
                    {title}
                </h1>
            </div>
            <div className="w-full rounded-2xl flex bg-white">
                <video
                className='w-full rounded-2xl bg-black'
                controls={true}
                playsInline={true}
                autoPlay={false}
                preload="metadata"
                controlsList="nodownload"
                onContextMenu={(e) => e.preventDefault()}>
                    <source src={`${videoEntry.videoURL}`} type="video/mp4" />
                </video>
            </div>
        </div>
    )
}


const FeedbackSection = () => {
    let [userEmail, setUserEmail] = useState<string>();
    let [queryParams, setQueryParams] = useSearchParams();
    let [feedback, setFeedback] = useState<string>();
    let [text, setText] = useState<string>("");
    let [title, setTitle] = useState("What did you think of the videos? Type below and press enter to send some feedback! ❤️");

    const feedbackInputRef = useRef<HTMLTextAreaElement>(null);

    const entryID = queryParams.get("entryID") ?? "";

    useEffect(() => {
        setUserEmail(parseEmail(entryID));
    })

    const sendFeedbackToServer = async () => {
        const requestBody = {
            entryID: entryID,
            feedback: feedback,
        }

        const response = await axios.post("https://latte-video-server-dwr7snkjfq-ew.a.run.app/video-feedback", requestBody);
        console.log(response);
    }

    const handleSubmit = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            sendFeedbackToServer()
            feedbackInputRef.current?.blur();
            setTitle("Thanks for the feedback! Feel free to send more at any time, or drop your phone number below if you'd be happy to have a call with the founders ☕️");
            setText("");
        }
    }

    return (
        <div className="flex flex-col justify-center space-y-2 w-11/12 md:w-1/2">
            <h1 className="font-medium font-poppins text-sm text-center">
                {title}
            </h1>
            <textarea
            className="m-auto p-4 w-full md:w-1/2 h-32 text-left font-poppins bg-white text-[12px] text-[#545454] rounded-xl outline-none resize-none"
            value={text}
            rows={5}
            onChange={(e) => {
                setFeedback(e.target.value);
                setText(e.target.value);
            }}
            onKeyDown={handleSubmit}
            ref={feedbackInputRef}>
            </textarea>
        </div>
    )
}

interface DownloadButtonProps {
    downloadURL: string;
    userRequiresUpgrade: boolean;
}

export const DownloadButton = ({ downloadURL, userRequiresUpgrade }: DownloadButtonProps) => {
    const fileName = "Latte Video - " + new Date().toISOString() + ".mp4";
    const [userRequiresUpgradeState, setUserRequiresUpgradeState] = useState(false);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);

    useEffect(() => {
        setUserRequiresUpgradeState(userRequiresUpgrade);
    }, [userRequiresUpgrade])

    return (
        <div className="w-full">
            <div className="relative flex justify-center items-center w-full rounded-xl bg-black cursor-pointer p-4 h-10 bg-cover hover:bg-main-gradient text-white hover:text-black hover:animate-pulse duration-300">
                {
                    userRequiresUpgradeState ?
                        <div className="font-medium font-poppins text-sm text-center w-full h-full absolute inset-0 justify-center items-center flex"
                            onClick={() => setShowUpgradeModal(true)}>
                            <h1 className="text-white text-sm font-poppins text-center cursor-pointer">
                                Download ⚡️
                            </h1>
                        </div>
                        :
                        <a className="font-medium font-poppins text-sm text-center w-full h-full absolute inset-0 justify-center items-center flex"
                            href={downloadURL}
                            target="_blank"
                            download={fileName}>
                            <h1 className="text-white text-sm font-poppins text-center cursor-pointer">
                                Download ⚡️
                            </h1>
                        </a>
                }
            </div>
            {
                showUpgradeModal &&
                <UpgradeModal isOpen={showUpgradeModal} onClose={() => setShowUpgradeModal(false)} message="You'll need to be on a paid plan to download your video snippets. Tap below to upgrade 🚀"/>
            }
        </div>
    )
}

interface ReformatButtonsProps {
    displayStartRepurposeButton: (shouldShow: boolean) => void;
    video: VideoEntry;
}

const ReformatButtons = ({displayStartRepurposeButton, video}: ReformatButtonsProps) => {
    let [makeVerticalButtonColor, setMakeVerticalButtonColor] = useState("white");
    let [makeVerticalBorderWidth, setMakeVerticalBorderWidth] = useState("2px");
    let [addSubtitlesButtonColor, setAddSubtitlesButtonColor] = useState("white");
    let [addSubtitlesBorderWidth, setAddSubtitlesBorderWidth] = useState("2px");

    const makeVerticalButtonTapped = (video: VideoEntry) => {
        if (videosToBeMadeVertical.some((v) => v.clipID === video.videoID)) {
            console.log("removing video from vertical array")
            videosToBeMadeVertical = videosToBeMadeVertical.filter((v) => v.clipID !== video.videoID);
            setMakeVerticalButtonColor("white");
            setMakeVerticalBorderWidth("2px");
        } else {
            console.log("adding video to vertical array")
            const postProcessingRequest: PostProcessingRequest = {
                clipID: video.videoID,
                taskSent: false,
            }
            videosToBeMadeVertical.push(postProcessingRequest);
            setMakeVerticalButtonColor("#fed391");
            setMakeVerticalBorderWidth("0px");
        }

        if (videosToBeMadeVertical.length > 0 || videosToAddSubtitlesTo.length > 0) {
            displayStartRepurposeButton(true);
        } else {
            displayStartRepurposeButton(false);
        }
    }

    const addSubtitlesButtonTapped = (video: VideoEntry) => {
        if (videosToAddSubtitlesTo.some((v) => v.clipID === video.videoID)) {
            console.log("removing video from subtitles array")
            videosToAddSubtitlesTo = videosToAddSubtitlesTo.filter((v) => v.clipID !== video.videoID);
            setAddSubtitlesButtonColor("white");
            setAddSubtitlesBorderWidth("2px");
        } else {
            console.log("adding video to subtitles array")
            const postProcessingRequest: PostProcessingRequest = {
                clipID: video.videoID,
                taskSent: false,
            }
            videosToAddSubtitlesTo.push(postProcessingRequest);
            setAddSubtitlesButtonColor("#ffd4ca");
            setAddSubtitlesBorderWidth("0px");
        }

        if (videosToBeMadeVertical.length > 0 || videosToAddSubtitlesTo.length > 0) {
            displayStartRepurposeButton(true);
        } else {
            displayStartRepurposeButton(false);
        }
    }

    return (
        <div className="flex flex-row space-x-2 justify-center w-full">
            <div className={`flex justify-center items-center w-1/2 rounded-full font-poppins font-medium text-xs md:text-sm cursor-pointer p-4 h-10 border-gray-400 hover:scale-90 duration-200`}
                style={
                    {
                        "backgroundColor": makeVerticalButtonColor,
                        "borderWidth": makeVerticalBorderWidth
                    }}
                    onClick={() => {
                        makeVerticalButtonTapped(video);
                    }}>
                 Make vertical 📱
            </div>
            <div className={`flex justify-center items-center w-1/2 rounded-full font-poppins font-medium text-xs md:text-sm cursor-pointer p-4 h-10 border-gray-400 hover:scale-90 duration-200`}
            style={
                {
                    "backgroundColor": addSubtitlesButtonColor,
                    "borderWidth": addSubtitlesBorderWidth
                }}
                onClick={() => {
                    addSubtitlesButtonTapped(video);
                }}>
               Add subtitles 📝
            </div>
        </div>
    )
}

interface StartRepurposeButtonProps {
    shouldShow: boolean;
}

const StartRepurposeButton = ({ shouldShow }: StartRepurposeButtonProps) => {
    console.log("Start Repurpose Mounted:");
    let [queryParams, setQueryParams] = useSearchParams();
    let [completionListenerShouldMount, setCompletionListenerShouldMount] = useState(false);

    const entryID = queryParams.get("entryID") ?? "";

    const sendClipsToBeRepurposed = async () => {
        setCompletionListenerShouldMount(true);

        console.log(videosToBeMadeVertical, videosToAddSubtitlesTo);

        const requestData = {
            "entryID": entryID,
            "videosToBeMadeVertical": videosToBeMadeVertical,
            "videosToAddSubtitlesTo": videosToAddSubtitlesTo
        }

        const response = await axios.post("https://latte-video-task-manager-dwr7snkjfq-ew.a.run.app/post-processing-queue", requestData);

        console.log("response:", response.status);
    }

    const handleListenerCompletion = () => {
        window.location.reload();
    }

    return (
        <div>
            {shouldShow &&
                <div className="flex flex-col space-y-2 justify-center items-center">
                    <div className={`transition-all duration-600 ${shouldShow ? "opacity-100" : "opacity-0"} w-56 flex justify-center rounded-full shadow-lg bg-main-gradient bg-cover cursor-pointer p-4`}>
                        <button className="font-medium font-poppins text-sm text-center text-white hover:text-black"
                            onClick={() => {
                                sendClipsToBeRepurposed();
                            }}>
                            Click to Repurpose 🎥
                        </button>
                    </div>
                    {completionListenerShouldMount &&
                        <RepurposingCompletionListener entryID={entryID} handleCompletion={handleListenerCompletion} />
                    }
                </div>
            }
        </div>
    )
}

const parseEmail = (stringToParse: string) => {
    const emailRegex = /[\w-\.]+@([\w-]+\.)+[a-zA-Z0-9 .-]{2,10}/g;
    const email = stringToParse.match(emailRegex);
    if (email) {
        return email[0];
    }
}

const downloadVideos = async (entryID: string, setPercentLoaded: (percent: number) => void, updateNumberVideosDownloaded: (number: number) => void) => {
    const response = await axios.get(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/video-clips?entryID=${entryID}`);
    const videosArray = response.data.repurposedVideos;
    let totalVideosDownloaded = 0;

    let snippets: VideoEntry[] = [];
    for (let i = 0; i < videosArray.length; i++) {
        totalVideosDownloaded++;
        setPercentLoaded((totalVideosDownloaded / videosArray.length) * 100);

        updateNumberVideosDownloaded(i+1);
      
        const video = videosArray[i];
        let videoEntry: VideoEntry = video

        const videoID = video.filename;
        const videoURL = await downloadVideoFromSignedUrl(videoID);
        videoEntry.videoURL = videoURL;
        videoEntry.videoID = videoID;

        if (video.postProcessed) {
            let postProcessedVideos: PostProcessedVideoEntry[] = [];
            for (let j = 0; j < video.postProcessed.length; j++) {
                const postProcessedVideo = video.postProcessed[j];
                const postProcessedVideoID = postProcessedVideo.filename;
                const postProcessedVideoURL = await downloadVideoFromSignedUrl(postProcessedVideoID);
                const postProcessingApplied = postProcessedVideo.postProcessingApplied;
                
                const postProcessedVideoEntry: PostProcessedVideoEntry = {
                    videoURL: postProcessedVideoURL,
                    postProcessingApplied: postProcessingApplied,
                }

                postProcessedVideos.push(postProcessedVideoEntry);
            }

            videoEntry.postProcessedVideos = postProcessedVideos;
        }
        
        snippets.push(videoEntry);
    }

    for (let x = 0; x < snippets.length; x++) {

    }

    return snippets;
}

const downloadVideoFromSignedUrl = async (videoID: string) => {
    const response = await axios.post(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/internal-video-download?videoName=${videoID}`)

    return response.data.url[0];
}

const generateRandomString = (length: number) => {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    let result = ""
    for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)]
    return result
}

export default ResultsPage;

// const downloadVideoFromSignedUrl = async (videoID: string, setPercentLoaded?: (percent: number) => void) => {
//     const response = await axios.post(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/internal-video-download?videoName=${videoID}`)

//     // const videoData = await axios.get(response.data.url[0], {
//     //     headers: {
//     //         "Content-Type": "video/mp4",
//     //     },
//     //     responseType: "blob",
//     //     onDownloadProgress(progressEvent) {
//     //         const progress = progressEvent.loaded / (progressEvent.total as number);
//     //         const progressPercentage = Math.round(progress * 100)
//     //         if (setPercentLoaded) {
//     //             setPercentLoaded(progressPercentage);
//     //         }
//     //     },
//     // })

//     // const blob = new Blob([videoData.data], { type: "video/mp4" });
//     // const url = window.URL.createObjectURL(blob);
//     return response.data.url[0];
// }