import React, { useState, useEffect, useRef, ChangeEventHandler, FormEventHandler } from 'react';
import { doesFileExceedSizeLimit, getVideoDuration } from '../../HelperFunctions/VideoFunctions';
import { isVideoFileTooShort } from '../../HelperFunctions/VideoFunctions';

const playButton = require("../../images/play-button.png")
const greenTick = require("../../images/checkmark.png")

interface FileAndLinkUploadContainerProps {
    handleFileSupplied: (isValid: boolean, file?: File | null) => void
    handleLinkSupplied: (isValid: boolean, link?: string) => void
}

const FileAndLinkUploadContainer = ({ handleFileSupplied, handleLinkSupplied }: FileAndLinkUploadContainerProps) => {
    const hiddenFileInput = useRef<HTMLInputElement>(null)

    const [dragActive, setDragActive] = useState(false);
    const [fileOrLinkUploaded, setFileOrLinkUploaded] = useState(false);
    const [uploadLabelText, setUploadLabelText] = useState("Drag and drop/click to upload (mp4 only)");
    const [linkText, setLinkText] = useState("")
    const [file, setFile] = useState<File>()

    useEffect(() => {
        if (fileOrLinkUploaded) {
            setUploadLabelText("Thanks!")
            handleFileSupplied(true, file)
        }
    }, [fileOrLinkUploaded, file])
            

    const processFile = async (files: FileList) => {
        if (files.length === 0) return;
        console.log(`✅ Process file called with ${files.length} files`)
        console.dir(`FILES: ${JSON.stringify(files)}`)
        if (files.length !== 1) {
            console.log(`❌ Process file called with ${files.length} files`)
            
            setUploadLabelText("Oops, make sure you only upload one file 🙏")
            return
        }

        const file = files[0]

        const fileExceedsSizeLimit = doesFileExceedSizeLimit(file)

        if (fileExceedsSizeLimit) {
            setUploadLabelText("Oops, your video is too big. Try again with a file less than 6gb 🙏")

            setFileOrLinkUploaded(false);

            handleFileSupplied(false, null)
            return
        }

        const videoDuration = await getVideoDuration(file)
        console.log(`VIDEO DURATION: ${videoDuration}`)
        if (videoDuration < 60) {
            setUploadLabelText("Oops, your video is too short. Try again with a file longer than 60 seconds 🙏")

            setFileOrLinkUploaded(false);

            handleFileSupplied(false, null)
            return
        }

        // isVideoFileTooShort(file, (duration: number) => {
        //     if (duration < 60) {
        //         setUploadLabelText("Oops, your video is too short. Try again with a file longer than 60 seconds 🙏")

        //         setFileOrLinkUploaded(false);
                
        //         handleFileSupplied(false, null)
        //         return;
        //     }
        // })

        if (file.type.includes("video/mp4") === false) {
            setUploadLabelText("Oops, make sure it's an mp4 file 🙏")

            setFileOrLinkUploaded(false);

            handleFileSupplied(false, null)
            return
        }

        setFileOrLinkUploaded(true);
        setFile(file);
        // setUploadLabelText("Thanks!")
        // handleFileSupplied(true, file)
    }

    const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDroppedFile = async (e: React.DragEvent<HTMLDivElement>) => {
        console.log(`📂 Dropped file`)
        e.preventDefault();
        e.stopPropagation();

        setDragActive(false);

        const files = e.dataTransfer.files;
        
        await processFile(files)
    };

    const handleFileSubmittedThroughLibrary: ChangeEventHandler<HTMLInputElement> = async (e) => {
        console.log(`📂 File submitted through library`)
        const files = e.target.files;

        if (files === null) {
            return
        }

        await processFile(files)
    }

    const handleLinkTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newText = e.target.value

        setLinkText(newText);

        if (newText.includes("youtube.com") || newText.includes("youtu.be")) {
            setFileOrLinkUploaded(true);

            setUploadLabelText("Thanks!");

            const queryParamsStrippedText = newText.split("&")[0]

            handleLinkSupplied(true, queryParamsStrippedText);
        } else {
            setUploadLabelText("Oops, make sure it's a YouTube link ▶️")
            setFileOrLinkUploaded(false);
            handleLinkSupplied(false)
        }
    }

    return (
        <div className="flex flex-col items-center justify-center w-96 h-64 bg-main-gradient bg-cover rounded-3xl space-y-4 shadow-2xl shadow-[#ffdcc1]">
            <div className="flex items-center justify-center w-72 h-40 rounded-2xl border-2 border-dashed border-white">
                <div className="flex flex-col items-center justify-center bg-[#fffbf9] bg-cover w-[95%] h-[90%] rounded-2xl space-y-2 hover:scale-90 duration-300"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onClick={() => {
                    hiddenFileInput.current?.click();
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                    onChange={handleFileSubmittedThroughLibrary}
                    onDrop={handleDroppedFile}>
                    <input type="file"
                        ref={hiddenFileInput}
                        style={{ display: "none" }}
                        accept="video/mp4" />
                    {
                        fileOrLinkUploaded ?
                            <img src={greenTick} className="w-10 h-10">
                            </img>
                            :
                            <img src={playButton} className="w-10 h-10">
                            </img>
                    }
                    <h1 className="text-xs font-poppins text-[#545454] w-2/3 text-center">
                        {uploadLabelText}
                    </h1>
                </div>
            </div>
            <div className="bg-[#fffbf9] bg-cover flex items-center justify-center w-64 h-9 rounded-xl">
                <form className="w-full h-full items-center justify-center flex px-3">
                    <input type="text" className="w-full h-full text-center font-poppins bg-transparent text-[12px] text-[#545454] placeholder:text-[#545454] rounded-xl outline-none"
                        placeholder="Or, paste a YouTube link here 🪄"
                        onChange={handleLinkTextChanged}
                        value={linkText} />
                </form>
            </div>
        </div>
    )
}

export default FileAndLinkUploadContainer