import React, { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

import { CiSaveDown2 } from "react-icons/ci";

import { QueryBasedVideoEntry } from "../../Types/DataTypes";
import QueryBasedResultsDownloader from "../../HelperClasses/Networking/QueryBasedResultsDownloader";
import { VideoOrSummaryContainerDisplay } from "../../Types/Enums";
import CustomVideoPlayer from "../../CustomReactTags/CustomVideoPlayer/CustomVideoPlayer";
import { TrimVideoContainer } from "../../CustomReactTags/CustomSlider/CustomSlider";
import { SrtComponent } from "../../pages/SrtEditPage";
import MixpanelTracker from "../../HelperClasses/Analytics/MixpanelTracker";
import UserManager from '../../HelperClasses/Networking/UserManager';
import { GeneralUpgradeModal } from '../../Modals/GeneralUpgradeModal';
import VideoDownloader from "../../HelperClasses/Networking/VideoDownloader";
import CurrentlyViewingContext from "../../ContextManagers/CurrentlyViewingContext";
import { SubtitleSequenceEditComponent } from "../../pages/SubtitleSequenceEditPage";
import { AutoCloseUpgradeModal } from "../../Modals/AutoCloseModal";

interface SnippetVideoAndSummaryContainerProps {
    originalVideoURL: string;
    postProcessedVideoURL: string | null;
    snippets: QueryBasedVideoEntry[];
    currentlyViewingSnippetIndex: number;
    videoContainerDisplay: VideoOrSummaryContainerDisplay;
    handleSaveSnippet: (snippet: QueryBasedVideoEntry) => void;
    setVideoContainerDisplay: (display: VideoOrSummaryContainerDisplay) => void;
    shouldSendRequestToUpdateDuration: boolean;
    shouldSendRequestToUpdateSubtitles: boolean;
    setShouldSendRequestToUpdateDuration: (shouldSend: boolean) => void;
    setShouldSendRequestToUpdateSubtitles: (shouldSend: boolean) => void;
    currentlyViewingVideoOrientation: string;
    setShouldRefreshSnippets: (shouldRefresh: boolean) => void;
    setVideosArePostProcessing: (arePostProcessing: boolean) => void;
}

const SnippetVideoAndSummaryContainer = ({
    originalVideoURL,
    postProcessedVideoURL,
    snippets,
    currentlyViewingSnippetIndex,
    videoContainerDisplay,
    handleSaveSnippet,
    setVideoContainerDisplay,
    shouldSendRequestToUpdateDuration,
    shouldSendRequestToUpdateSubtitles,
    setShouldSendRequestToUpdateDuration,
    setShouldSendRequestToUpdateSubtitles,
    currentlyViewingVideoOrientation,
    setShouldRefreshSnippets,
    setVideosArePostProcessing }: SnippetVideoAndSummaryContainerProps) => {
    const [currentlyViewingSnippet, setCurrentlyViewingSnippet] = useState<QueryBasedVideoEntry | undefined>(undefined);
    const [videoContainerDisplayState, setContainerDisplay] = useState<VideoOrSummaryContainerDisplay>(VideoOrSummaryContainerDisplay.Video);
    const [showTrialUpgradeModal, setShowTrialUpgradeModal] = useState(false)
    const [subscriptionStatus, setSubscriptionStatus] = useState<string | null>(null);
    const mixpanelTracker = new MixpanelTracker();
    const userManager = new UserManager()
    const email = localStorage.getItem("email") || "";
    const { 
        currentlyViewingVideoMetadataEntry, 
        currentlyViewingVariantType,
        setCurrentlyViewingVariantType,
        setPostProcessedVideoURL,
        setShowVideoSentToBeRerenderedModal
    } = useContext(CurrentlyViewingContext);


    const SubtitleEditComponentRouter = () => {
        // Check if any of the following fields exist on currentlyViewingVideoMetadataEntry:
        // 1. subtitleSequencesLandscape
        // 2. subtitleSequencesPortrait
        // 3. subtitleSequencesPortraitMagic
        // If NO, then use old Srt Component
        // If YES, then use new SubtitleSequenceEditComponent
        if (currentlyViewingVideoMetadataEntry) {
            if (currentlyViewingVideoMetadataEntry.subtitleSequencesLandscape ||
                currentlyViewingVideoMetadataEntry.subtitleSequencesPortrait ||
                currentlyViewingVideoMetadataEntry.subtitleSequencesPortraitMagic) {

                return <SubtitleSequenceEditComponent 
                        saveButtonCallback={() => {
                            console.log('saveButtonCallback called');
                            setPostProcessedVideoURL(null);
                            setCurrentlyViewingVariantType(["original"]);
                            setVideoContainerDisplay(VideoOrSummaryContainerDisplay.Video);
                            setShowVideoSentToBeRerenderedModal(true);
                        }}
                />
            }
        } 
        return <SrtComponent
                    videoID={currentlyViewingSnippet!.videoID}
                    orientation={currentlyViewingVideoOrientation}
                    setVideoContainerDisplayMode={setVideoContainerDisplay} 
                    shouldSendRequestToUpdateSubtitles={shouldSendRequestToUpdateSubtitles} 
                    setShouldSendRequestToUpdateSubtitles={setShouldSendRequestToUpdateSubtitles} 
                    setShouldRefreshSnippets={setShouldRefreshSnippets} 
                    setVideosArePostProcessing={setVideosArePostProcessing}
                    />
    }
    

    const downloadVideo = (url: string) => {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      
        // if (isIOS) {
        //   const filename = `${new Date()}.mp4`;
        //   const instructions = `
        //     To download the video:
        //     1. Press and hold the View button below.
        //     2. Select 'Download Linked File' from the context menu.
        //   `;
      
        //   const popup = window.open("", "popup", "width=300,height=200");
        //   if (popup) {
        //     popup.document.write(`
        //       <!DOCTYPE html>
        //       <html>
        //         <head>
        //           <title>Download Video</title>
        //         </head>
        //         <body>
        //           <p>${instructions}</p>
        //           <a href="${url}" target="_blank">View</a>
        //         </body>
        //       </html>
        //     `);
        //   } else {
        //     alert("Please allow popups for this site");
        //   }
      
        // } else {
        const hiddenDownloadLink = document.createElement("a");
        hiddenDownloadLink.href = url;
        hiddenDownloadLink.download = `${new Date()}.mp4`;
        document.body.appendChild(hiddenDownloadLink);
        hiddenDownloadLink.click();
        document.body.removeChild(hiddenDownloadLink);
        // }
      };      

    const saveButtonClicked = async () => {
        // if subscription status is null, then user does not have a subscription, which means
        // direct them to the payment plan 
        // else if subscription is "trialing"
        // then show the trial upgrade modal 
        // else continue as usual

        const userIsOnTrial = await userManager.userIsOnTrial(email);
        if (userIsOnTrial) {
            userManager.getSubscriptionStatus(email).then((status) => {
                setSubscriptionStatus(status);
                // subscription status is null if no subscription exists
            });
            setShowTrialUpgradeModal(true);
            return;
        }
        const videoID = currentlyViewingSnippet!.videoID;
        console.log(`💾 Save button clicked for videoID: ${videoID}`);

        if (currentlyViewingSnippet && postProcessedVideoURL === null) {
            // This means the video is not a post-processed version
            const videoDownloader = new VideoDownloader();

            const videoPath = await videoDownloader.getVideoPath(videoID);
            if (videoPath) {
                const signedURL = await videoDownloader.getSignedURLFromVideoPath(videoPath);
                signedURL && downloadVideo(signedURL);
            } else {
                handleSaveSnippet(currentlyViewingSnippet);
            }
            
        } else if (currentlyViewingSnippet && postProcessedVideoURL !== null) {
            downloadVideo(postProcessedVideoURL);
        }
        

        
        mixpanelTracker.track("🎉 Video Downloaded", email, {
            "videoID": videoID,
        });
    }

    const TrialUpgradeModal = (userHasSubscription: boolean) => {
        console.log("User has subscription: ", userHasSubscription);
        const title = "Upgrade Now To Download";
        const buttonText = "Upgrade Now";
        let message;
        let buttonClickFunction;
        if (userHasSubscription) {
            message = "To download the videos Latte created for you, click the button below to end your free trial now! ✨"
            buttonClickFunction = async () => {
                const userTrialEndResponse = await userManager.endUserTrial(email);
                if (userTrialEndResponse) {
                    setShowTrialUpgradeModal(false)    
                }
            }

        } else {
            message = "To download the videos Latte created for you, click the button below to upgrade to a paid plan! ✨";
            buttonClickFunction = async () => {
                // direct user to payment plan page
                window.open("https://app.latte.social/video/upgrade", "_self");
                setShowTrialUpgradeModal(false)    
            }
        }
        return (
            <GeneralUpgradeModal
                isOpen={showTrialUpgradeModal}
                onClose={() => {
                    setShowTrialUpgradeModal(false)
                }}
                title={title}
                message={message}
                buttonText={buttonText}
                buttonClickFunction={buttonClickFunction}
            />
        )
    }

    useEffect(() => {
        if (snippets && currentlyViewingSnippetIndex < snippets.length && currentlyViewingSnippetIndex >= 0) {
            const currentSnippet = snippets[currentlyViewingSnippetIndex];
            setCurrentlyViewingSnippet(currentSnippet);
        }

        // console.log("Post processed video URL: ", postProcessedVideoURL)

        setContainerDisplay(videoContainerDisplay);
    }, [originalVideoURL, postProcessedVideoURL, snippets, currentlyViewingSnippetIndex, videoContainerDisplay]);

    // useEffect(() => {
    //     // console.log("Checkpoint 3")
    // }, [shouldSendRequestToUpdateSubtitles])

    return (
        <div 
            className="flex relative flex-col space-y-3 bg-white rounded-3xl w-full px-6 py-4 shadow-sm h-full"
            key={`${currentlyViewingVariantType}-${currentlyViewingSnippetIndex}`}>
            <h1 className="font-poppins font-semibold text-lg h-auto max-w-[70%]">
                { currentlyViewingSnippet?.title }
            </h1>
            <div className="absolute top-2 right-6 flex flex-row space-x-3 w-auto h-auto">
                <h1 className="font-poppins">
                    {`${currentlyViewingSnippetIndex + 1}/${snippets.length}`}
                </h1>
                <button
                    className="hover:scale-95 duration-200"
                    onClick={saveButtonClicked}>
                    <CiSaveDown2 size={24} />
                </button>
            </div>
            {
                videoContainerDisplayState === VideoOrSummaryContainerDisplay.Video && currentlyViewingSnippet && !postProcessedVideoURL &&
                <div className={`${videoContainerDisplayState === VideoOrSummaryContainerDisplay.Video ? "opacity-100" : "opacity-0"} duration-500`}>
                    <UncutVideoContainer snippet={currentlyViewingSnippet} originalVideoURL={originalVideoURL} />
                </div>
            }
            {
                videoContainerDisplayState === VideoOrSummaryContainerDisplay.Video && currentlyViewingSnippet && postProcessedVideoURL &&
                <div className="">
                    <PostProcessedVideoContainer snippet={currentlyViewingSnippet} originalVideoURL={postProcessedVideoURL} />
                </div>
            }
            {
                videoContainerDisplayState === VideoOrSummaryContainerDisplay.Summary && currentlyViewingSnippet &&
                <SummaryContainer snippet={currentlyViewingSnippet} />
            }
            {
                videoContainerDisplayState === VideoOrSummaryContainerDisplay.None &&
                <NoVideosYetContainer />
            }
            {
                videoContainerDisplayState === VideoOrSummaryContainerDisplay.EditDuration && currentlyViewingSnippet &&
                <TrimVideoContainer entry={currentlyViewingSnippet} videoURL={originalVideoURL} shouldSendRequestToUpdateDuration={shouldSendRequestToUpdateDuration} setShouldSendRequestToUpdateDuration={setShouldSendRequestToUpdateDuration} updateContainerDisplay={setVideoContainerDisplay} setShouldRefreshSnippets={setShouldRefreshSnippets} />
            }
            {
                videoContainerDisplay === VideoOrSummaryContainerDisplay.EditSubtitles && currentlyViewingSnippet &&
                <SubtitleEditComponentRouter />
            }
            {
                videoContainerDisplay === VideoOrSummaryContainerDisplay.Error && currentlyViewingSnippet &&
                <ErrorContainer />
            }
            {
                showTrialUpgradeModal &&
                TrialUpgradeModal(subscriptionStatus !== null)
            }
        </div>
    )
}

interface VideoContainerProps {
    originalVideoURL: string;
    snippet: QueryBasedVideoEntry | null;
}

const UncutVideoContainer = ({ snippet, originalVideoURL }: VideoContainerProps) => {
    const [currentlyViewingSnippet, setCurrentlyViewingSnippet] = useState<QueryBasedVideoEntry | null>(null);
    const [videoURL, setVideoURL] = useState<string>("");

    useEffect(() => {
        if (snippet) {
            setCurrentlyViewingSnippet(snippet);
            //console.log(`Currently Viewing Snippet: ${currentlyViewingSnippet?.videoID} - ${currentlyViewingSnippet?.startTime} - ${currentlyViewingSnippet?.endTime}`)
        }
        setVideoURL(originalVideoURL);
    }, [snippet, originalVideoURL]);
    
    return (
        <div className="flex flex-col space-y-2 pb-2 w-auto h-auto">
            <p className="overflow-y-scroll font-poppins text-sm h-20 max-h[20%] scrollbar-custom">
                {currentlyViewingSnippet?.caption}
            </p>
            <br />
            {currentlyViewingSnippet &&
                <div className="w-full max-h-[80%]">
                    <CustomVideoPlayer
                        key={`${currentlyViewingSnippet?.videoID}-${currentlyViewingSnippet?.startTime}-${currentlyViewingSnippet?.endTime}`}
                        src={originalVideoURL}
                        startTimeString={currentlyViewingSnippet?.startTime}
                        endTimeString={currentlyViewingSnippet?.endTime}
                    />
                </div>
            }
        </div>
    )
}

const PostProcessedVideoContainer = ({ snippet, originalVideoURL }: VideoContainerProps) => {
    const [currentlyViewingSnippet, setCurrentlyViewingSnippet] = useState<QueryBasedVideoEntry | null>(null);
    const [videoURL, setVideoURL] = useState<string>("");

    useEffect(() => {
        if (snippet) {
            setCurrentlyViewingSnippet(snippet);
            //console.log(`Currently Viewing Snippet: ${currentlyViewingSnippet?.videoID} - ${currentlyViewingSnippet?.startTime} - ${currentlyViewingSnippet?.endTime}`)
        }
        setVideoURL(originalVideoURL);
    }, [snippet, originalVideoURL]);

    return (
        <div className="flex w-full flex-col space-y-2 pb-2 mt-2">
            {currentlyViewingSnippet &&
                <div className="h-72 flex justify-center items-center">
                        <video
                            key={videoURL}
                            className='h-full bg-white rounded-xl'
                            controls={true}
                            playsInline={true}
                            autoPlay={false}
                            preload="metadata"
                            controlsList="nodownload"
                            onContextMenu={(e) => e.preventDefault()}>
                            <source src={videoURL} type="video/mp4" />
                        </video>
                </div>
            }
        </div>
    )
}

interface SummaryContainerProps {
    snippet: QueryBasedVideoEntry | null;
}

const SummaryContainer = ({ snippet }: SummaryContainerProps) => {
    const [currentlyViewingSnippet, setCurrentlyViewingSnippet] = useState<QueryBasedVideoEntry | null>(null);

    useEffect(() => {
        if (snippet) {
            setCurrentlyViewingSnippet(snippet);
        }
    }, [snippet]);

    return (
        <div className="flex w-full overflow-y-scroll">
            <p className="w-full font-poppins">
                {currentlyViewingSnippet?.summary}
            </p>
        </div>
    )
}

const NoVideosYetContainer = () => {
    return (
        <div className="flex flex-col space-y-3 bg-white rounded-3xl h-4/5 w-full font-poppins">
            <h1 className="font-semibold text-lg">
                No videos yet!
            </h1>
            <p className="overflow-y-scroll text-sm">
                No videos have been created yet. Type a query in the text box below to create some instantly ⚡️
            </p>
        </div>
    )
}

const ErrorContainer = () => {
    return (
        <div className="flex flex-col space-y-3 bg-white rounded-3xl h-4/5 w-full font-poppins">
            <h1 className="font-semibold text-lg">
                Oops!
            </h1>
            <p className="overflow-y-scroll text-sm">
                You haven't generated any subtitles for this video orientation yet 🤷
            </p>
        </div>
    )
}

export default SnippetVideoAndSummaryContainer;