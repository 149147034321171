import React, { useRef, useEffect, useState, ChangeEventHandler, FormEventHandler } from "react"
import { LogInOrOutButton } from "./LogInOrOutButton";

interface ProgressBarProps {
    progressPercentage: number;
    showPercentage?: boolean;
}

export const ProgressBar = ({progressPercentage, showPercentage}: ProgressBarProps) => {
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        setProgress(progressPercentage)
    }, [progressPercentage])

    return (
        <div className="w-full h-6 bg-gray-200 rounded-full">
            <div
                className="h-full text-center text-xs text-white bg-main-gradient bg-cover rounded-full flex items-center px-1"
                style={{
                    width: `${progress}%`,
                    
                }}>
                {
                    showPercentage &&
                    <h1 className="h-full text-center text-xs text-white m-auto flex items-center">
                        {progress}%
                    </h1>
                }
            </div>
        </div>
    )
}

interface MenuBarProps {
    isLoggedIn: boolean
}

export const MenuBar = ({isLoggedIn}: MenuBarProps) => {
    const image = require(`../images/latte-logo-menu-bar.png`)
    return (
        <div className="pt-10 flex flex-row items-center m-auto px-1 md:w-[60%] pb-6">
            <img src={image} className="h-10 ml-8 hover:cursor-pointer"
                onClick={() => {
                    window.open("https://latte.social")
                }} />
            <div className="flex flex-row justify-center items-center space-x-12 ml-auto mr-8 text-[#777574]">
                <h1 className="font-poppins font-medium text-sm hover:cursor-pointer">
                    <a href="https://latte.social">Home</a>
                </h1>
                {/* <h1 className="font-poppins font-medium text-sm hover:cursor-pointer">
                    <a href="https://app.latte.social/video/upgrade">Pricing</a>
                </h1> */}
                <h1 className="font-poppins font-medium text-sm hover:cursor-pointer">
                    <a href="https://app.latte.social/video">Video</a>
                </h1>
                <LogInOrOutButton loggedIn={isLoggedIn}/>
            </div>
        </div>
    )
}

interface PageHeaderProps {
    isLoggedIn: boolean
}

export const PageHeader = ({ isLoggedIn }: PageHeaderProps) => {
    useEffect(() => {
        // console.log("PAGE HEADER RENDERED")
    }, [isLoggedIn])

    return (
        <div className="mt-36 max-w-[80%] md:max-w-[40%]">
            {
                !isLoggedIn &&
                <div className="space-y-4">
                    <h1 className="text-4xl font-poppins font-semibold text-center">Latte Video</h1>
                    <h2 className="text-sm md:text-base font-poppins text-[#545454] font-light text-center">
                        Create short social-first clips from one long video in a single click ⚡️
                    </h2>
                </div>
            }
            {
                isLoggedIn &&
                <div>
                    <h1 className="text-2xl font-poppins font-semibold text-center">
                        Welcome back 👋
                    </h1>
                </div>
            }
        </div>
    )
}