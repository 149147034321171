import axios from 'axios';

const progressUpdateEndpoint = "https://latte-real-time-updates-dwr7snkjfq-ew.a.run.app/progress-update"

export const fetchVideoMetadata = async (videoID: string, fields: string[] = []) => {
    const videoMetadataEndpoint = `https://latte-video-server-dwr7snkjfq-ew.a.run.app/get-video-metadata`
    const projection = Object.fromEntries(fields.map(field => [field, 1]));

    const params = {
        videoID: videoID,
        projection: encodeURIComponent(JSON.stringify(projection)),
    }
    const response = await axios.get(videoMetadataEndpoint, { params: params })
    return response.data;
}

export const emitProgressUpdate = async (taskID: string, progress: number, override: boolean = false) => {
    // only emit progress update if fetched progress is not null
    const fetchedProgress = await fetchProgressFromServer(taskID);
    if (fetchedProgress === null && !override) {
        return null;
    }

    const payload = {
        entryID: taskID,
        progress: progress
    }
    // console.log(`Emitting progress update: ${JSON.stringify(payload)}`)
    const response = await axios.post(progressUpdateEndpoint, payload)
    return response.data;
}

export const fetchProgressFromServer = async (taskID: string) => {
    const response = await fetch(
        `https://latte-real-time-updates-dwr7snkjfq-ew.a.run.app/progress/${taskID}`
      );
    const data = await response.json();
    const progress = data.progress;
    return progress;
}