import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { setUserIsLoggedOutLocalStorage, setEmailLocalStorage } from '../HelperClasses/loginHelperFunctions';

interface UnsubscribeModalModalProps {
    setIsOpen: (isOpen: boolean) => void;
}

const UnsubscribeModal = ({ setIsOpen }: UnsubscribeModalModalProps) => {
    const [hasJustUnsubscribed, setHasJustUnsubscribed] = useState(false);

    const handleClose = () => {
        setIsOpen(false);
    }

    const handleConfirmUnsubscribe = async () => {
        let userEmail = localStorage.getItem('email')
        
        const res = await axios.get(`https://latte-payment-server-qo2mn26dqq-ew.a.run.app/unsubscribe?email=${userEmail}`)

        if (res.status === 200) {
            setHasJustUnsubscribed(true)
            setUserIsLoggedOutLocalStorage()
            setEmailLocalStorage("")
            window.location.href = "https://latte.social"
        }
    }

    return (
        <div className="fixed z-20 mx-auto mt-4 inset-0 max-h-36 p-4 shadow-lg w-11/12 md:w-[500px] justify-center items-center flex rounded-2xl bg-white border-0 border-[#ffd9c1]">
            <div className="flex flex-col space-y-2 items-center justify-center w-full bg-white">
                <h1 className="font-poppins text-sm text-center">
                    Are you sure you want to unsubscribe? You will lose all your saved videos.
                </h1>
                <button
                onClick={handleConfirmUnsubscribe}
                className="font-poppins text-gray-400 text-center">
                    Yes
                </button>
                <button
                onClick={handleClose}
                className="font-poppins font-semibold text-center">
                    Nope, I'm good for now
                </button>
            </div>
        </div>
    )
}

export default UnsubscribeModal;