import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

import { QueryBasedVideoEntry } from "../../Types/DataTypes";
import { OriginalVideoDisplayMode } from "../../Types/Enums";

interface OriginalVideoContainerProps {
    originalVideoURL: string;
    entry: QueryBasedVideoEntry | null;
}

const OriginalVideoContainer = ({ originalVideoURL, entry }: OriginalVideoContainerProps) => {
    const [videoURL, setVideoURL] = useState<string>("");
    const [displayMode, setDisplayMode] = useState<OriginalVideoDisplayMode>(OriginalVideoDisplayMode.Video);

    useEffect(() => {
        setVideoURL(originalVideoURL);

        //console.log("Original video URL:", originalVideoURL);
    }, [originalVideoURL]);

    return (
        <div 
            className="bg-white rounded-3xl h-full w-full md:w-7/12 flex items-center justify-center shadow-sm flex-col space-y-4 max-h-[100%] min-w-[40vh]"
            >
            <h1 className="font-poppins text-2xl font-semibold w-8/12 text-center">
                {entry?.title}
            </h1>
            <div className="w-full h-[60%] flex bg-white">
                {
                    displayMode === OriginalVideoDisplayMode.Video &&
                    <video
                        key={videoURL}
                        className='w-full bg-white rounded-none'
                        controls={true}
                        playsInline={true}
                        autoPlay={false}
                        preload="metadata"
                        controlsList="nodownload"
                        onContextMenu={(e) => e.preventDefault()}>
                        <source src={`${videoURL}`} type="video/mp4" />
                    </video>
                }
                {
                    displayMode === OriginalVideoDisplayMode.Summary &&
                    <SummaryOrTranscriptContainer text={entry?.summary} />
                }
                {
                    displayMode === OriginalVideoDisplayMode.Transcript &&
                    <SummaryOrTranscriptContainer text={entry?.transcript.text} />
                }
            </div>
            <OptionsButtonsContainer displayMode={displayMode} setDisplayMode={setDisplayMode} />
        </div>
    )
}

interface SummaryOrTranscriptContainerProps {
    text: string | undefined;
}

const SummaryOrTranscriptContainer = ({ text }: SummaryOrTranscriptContainerProps) => {
    return (
        <div className="px-6 overflow-y-scroll min-h-full">
            <p className="font-poppins">
                {text}
            </p>
        </div>
    )
}

interface OptionsButtonsContainerProps {
    displayMode: OriginalVideoDisplayMode;
    setDisplayMode: (displayMode: OriginalVideoDisplayMode) => void;
}

const OptionsButtonsContainer = ({ displayMode, setDisplayMode }: OptionsButtonsContainerProps) => {
    const summaryButtonClicked = () => {
        displayMode === OriginalVideoDisplayMode.Summary ?
            setDisplayMode(OriginalVideoDisplayMode.Video) :
            setDisplayMode(OriginalVideoDisplayMode.Summary);
    }

    const transcriptButtonClicked = () => {
        displayMode === OriginalVideoDisplayMode.Transcript ?
            setDisplayMode(OriginalVideoDisplayMode.Video) :
            setDisplayMode(OriginalVideoDisplayMode.Transcript);
    }

    return (
        <div className="flex flex-row space-x-4">
            <button className="font-poppins bg-[#f2efee] text-center text-xs font-medium text-[#333333] rounded-2xl w-28 h-10 hover:scale-105 duration-300"
            onClick={summaryButtonClicked}>
                { displayMode === OriginalVideoDisplayMode.Summary ? "VIDEO" : "SUMMARY" }
            </button>
            <button className="font-poppins bg-[#f2efee] text-center text-xs font-medium text-[#333333] rounded-2xl w-28 h-10 hover:scale-105 duration-300"
            onClick={transcriptButtonClicked}>
                { displayMode === OriginalVideoDisplayMode.Transcript ? "VIDEO" : "TRANSCRIPT"}
            </button>
        </div>
    )
}

export default OriginalVideoContainer;