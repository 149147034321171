import axios from "axios";
import { getUserIsLoggedInLocalStorage } from "../loginHelperFunctions";

import { VideoUploadMode } from "../../Types/Enums";

export default class EntryUploader {
    constructor(
        setShowUpgradeModal: (requiresUpgrade: boolean) => void,
        submissionCompletionCallback: (isSuccessful: boolean, message: string) => void) {
        this.setShowUpgradeModal = setShowUpgradeModal;
        this.submissionCompletionCallback = submissionCompletionCallback;
    }

    submissionCompletionCallback: (isSuccessful: boolean, message: string) => void;
    setShowUpgradeModal: (shouldShow: boolean) => void;

    public uploadLink = async (entryID: string, link: string) => {
        //axios.get(`https://latte-analytics-server-dwr7snkjfq-ew.a.run.app/latte-video?entryID=${userEmail}&event=youtube-link-added`)

        const requestBody = {
            link: link as string,
            entryID: entryID
        }

        console.log("Request Body in upload link:", requestBody)

        const response = await axios.post("https://latte-video-server-dwr7snkjfq-ew.a.run.app/beta-link-upload", requestBody)

        if (response.status === 200) {
            this.submissionCompletionCallback(true, "Thanks!")
        }
    }

    public uploadFile = async (userEmail: string, entryID: string, file: File, setFileUploadProgress: (progress: number) => void) => {
        axios.get(`https://latte-analytics-server-dwr7snkjfq-ew.a.run.app/latte-video?entryID=${userEmail}&event=media-file-uploaded`)

        const requestBody = {
            "fileName": entryID,
            "mimeType": "video/mp4"
        }

        console.log("Request Body in upload file:", requestBody)

        const signedURLResponse = await axios.post(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/beta-video-upload`, requestBody)
        console.log("SIGNED URL RESPONSE:", signedURLResponse)

        if (signedURLResponse.status === 200) {
            const signedURL = signedURLResponse.data.url
            console.log(`🚀 Signed URL: ${signedURL}`)
            const uploadedFileResponse = await this.uploadFileWithSignedUrl(file, signedURLResponse.data.url[0], setFileUploadProgress)

            console.log("Uploaded File Response:", uploadedFileResponse)
        } else {
            this.submissionCompletionCallback(false, "Ooops! There was a problem uploading your video. Please try again 🙏")
        }
    }

    private uploadFileWithSignedUrl = async (file: File, signedUrl: string, setFileUploadProgress: (progress: number) => void) => {
        try {
            const uploadResponse = await axios.put(signedUrl, file, {
                headers: {
                  'Content-Type': file.type,
                },
                onUploadProgress: (progressEvent) => {
                  const progress = Math.round((progressEvent.loaded / progressEvent.total!) * 100);
                  setFileUploadProgress(progress);
                },
              });
    
            if (uploadResponse.status === 200) {
                console.log(`🚀 File uploaded successfully: ${uploadResponse}`);
                setFileUploadProgress(100);
                return uploadResponse;
            }
        } catch (error) {
            console.log("error uploading file with signed url:", error)
            this.submissionCompletionCallback(false, "Ooops! There was a problem uploading your video. Please try again 🙏")
        }
    }

    // uploadFileWithSignedUrl = async (file: File, signedUrl: string, setFileUploadProgress: (progress: number) => void) => {
    //     return new Promise((resolve, reject) => {
    //         let xhr = new XMLHttpRequest()
    //         xhr.open("PUT", signedUrl)
    //         xhr.setRequestHeader("Content-Type", "application/octet-stream")
    //         xhr.overrideMimeType("video/mp4")

    //         xhr.onerror = (err) => {
    //             console.log("error uploading file with signed url:", err)
    //             this.submissionCompletionCallback(false, "Ooops! There was a problem uploading your video. Please try again 🙏")
    //             reject(err)
    //         }

    //         xhr.upload.onprogress = (progressEvent) => {
    //             if (progressEvent.lengthComputable) {
    //                 const amountCompleted = progressEvent.loaded / progressEvent.total
    //                 let percentCompleted = amountCompleted * 100
    //                 percentCompleted = Math.floor(percentCompleted)

    //                 setFileUploadProgress(percentCompleted)

    //                 console.log("percentCompleted:", percentCompleted)
    //             }
    //         }

    //         xhr.onreadystatechange = function () {
    //             if (xhr.readyState === 4 && xhr.status === 200) {
    //                 console.log("File uploaded successfully")
    //                 resolve(xhr.response)
    //             }
    //         };

    //         const dataToSend = new Blob([file], { type: "video/mp4" })
    //         xhr.send(dataToSend)
    //     })
    // }

    public startAutopilotFlow = async (entryID: string, desiredClipDuration: number, email: string, link?: string) => {
        const userIsLoggedIn = getUserIsLoggedInLocalStorage()
        const authorized = userIsLoggedIn ? true : false

        const requestBody = {
            entryID: entryID,
            link: link,
            desiredDuration: desiredClipDuration,
            email: localStorage.getItem('email') || email, // if logged in, use localStorage, else use global email state
            authorized: authorized,
            flowType: "autopilot"
        }

        console.log("Request Body in send to Task Manager", requestBody)

        const response = await axios.post(`https://latte-video-task-manager-dwr7snkjfq-ew.a.run.app/task-manager-queue`, requestBody)

        const data = await response.data

        if (data) {
            if (data.upgradeRequired == true) {
                this.submissionCompletionCallback(false, "Ooops! You've reached your limit for free video clips. Please upgrade to a paid plan to continue using Latte 🙏")
                this.setShowUpgradeModal(true)
            } else {
                this.submissionCompletionCallback(true, "Thank you! We'll send you an email as soon as your videos are ready 📹")
            }

            axios.get(`https://latte-analytics-server-dwr7snkjfq-ew.a.run.app/latte-video?entryID=${entryID}&event=submit`)
        }

        console.log("Response Data from Task Manager:", response.data)
    }

    public startAdvancedFlow = async (entryID: string, email: string, link?: string) => {
        const userIsLoggedIn = getUserIsLoggedInLocalStorage()
        const authorized = userIsLoggedIn ? true : false

        const requestBody = {
            entryID: entryID,
            link: link,
            email: localStorage.getItem('email') || email,
            authorized: authorized,
            flowType: "advanced"
        }

        console.log("Request Body in send to Task Manager", requestBody)

        const response = await axios.post(`https://latte-video-task-manager-dwr7snkjfq-ew.a.run.app/task-manager-queue`, requestBody)

        const data = await response.data

        if (data) {
            if (data.upgradeRequired == true) {
                this.submissionCompletionCallback(false, "Ooops! You've reached your limit for free video clips. Please upgrade to a paid plan to continue using Latte 🙏")
                this.setShowUpgradeModal(true)
            } else {
                this.submissionCompletionCallback(true, "Thank you! We'll send you an email as soon as your videos are ready 📹")
            }

            axios.get(`https://latte-analytics-server-dwr7snkjfq-ew.a.run.app/latte-video?entryID=${entryID}&event=submit`)
        }

        console.log("Response Data from Task Manager:", response.data)
    }
}