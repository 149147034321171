import React, { useEffect, useState, memo } from 'react';
import axios from 'axios';

interface AutopilotCompletionListenerProps {
    entryID: string | null;
    completionCallback: () => void;
}

const AutopilotCompletionListener = ({ entryID, completionCallback }: AutopilotCompletionListenerProps ) => {
    useEffect(() => {
        const es = new EventSource(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/real-time-updates?updateType=start-listening&entryID=${entryID}&isWebClient=true`);

        console.log("Autpilot completion listener rendered")
        console.log("Opened SSE connection")

        es.onmessage = (event) => {
            console.log("Ping received:", event.data);
            if (event.data.includes("video-repurposing-completed") || event.data.includes("video-post-processing-completed")) {
                console.log("Autopilot completed, closing SSE connection")
                completionCallback();
                es.close();
            }
        }

        return () => {
            es.close();
            console.log("Closed SSE connection")
        };
    }, [])

    return (
        <div>
        </div>
    )
}

export default AutopilotCompletionListener;