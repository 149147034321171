import React, { useRef, useEffect, useState, ChangeEventHandler, FormEventHandler } from "react"
import axios from 'axios';
import UserManager from '../HelperClasses/Networking/UserManager';

import { TbSend } from "react-icons/tb"
import MixpanelTracker from "../HelperClasses/Analytics/MixpanelTracker";

interface LogInModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const LogInModal = ({ isOpen, onClose }: LogInModalProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [email, setEmail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [textValue, setTextValue] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const [userNotFound, setUserNotFound] = useState(false);
    const userManager = new UserManager()
    const mixpanelTracker = new MixpanelTracker()

    console.log("EMAIL IN LOGIN FORM:", email)

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTextValue(e.target.value);
        const userInputString = e.target.value;
        const emailRegex = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/g;
        const replacedString = userInputString.replace(emailRegex, "");

        if (replacedString == "" && emailRegex.test(userInputString)) {
            setEmail(userInputString.toLowerCase());
            setIsValidEmail(true);
            console.log("VALID")
        } else {
            setIsValidEmail(false);
            console.log("INVALID")
        }
    }

    const handleSubmit = async () => {
        setTextValue("");
        
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
        mixpanelTracker.track("↗️ User attempting login", email)
        

        const userHasAccount = await userManager.getUserAccount(email);
        console.log("USER HAS ACCOUNT:", userHasAccount)
        
        if (userHasAccount) {
            axios.get(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/send-magic-link?email=${email}`).then(
                (resp) => {
                    console.log(resp.data)
                    setEmailSent(true);
                }
            )
    
            const timer = setTimeout(() => {
                onClose();
                setEmailSent(false);
            }, 5000);
            
            return () => clearTimeout(timer);
        } else {
            console.log("USER DOES NOT HAVE ACCOUNT")
            setUserNotFound(true);
        }
    }

    const handleDoneButtonClick: FormEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        handleSubmit();
    }

    const handleEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && isValidEmail) {
            handleSubmit();
        }
    }

    useEffect(() => {
        if (isOpen) {
            inputRef.current?.focus();
        }
      }, [isOpen]);

    return (
        <div className="fixed z-100 mx-auto my-auto inset-0 min-h-28 max-h-52 flex p-4 shadow-lg w-[500px] rounded-2xl bg-white border-2 border-[#ffd9c1]"
            style={
                isOpen ? { display: "flex" } : { display: "none" }
            }>
            <div className="flex flex-col space-y-3 items-center justify-center h-full w-full bg-white">
            <h1 className="font-poppins font-semibold text-2xl text-center">
                {emailSent 
                    ? "We've sent you an email!" 
                    : userNotFound 
                        ? "Oops, seems like you don't have an account yet" 
                        : "Enter your email"}
            </h1>
            <p className="font-poppins font-normal text-sm text-center">
                {emailSent
                    ? "Check your inbox for your login link 😇"
                    : userNotFound
                        ? "Sign up to get started 🚀"
                        : "We'll send you a magic link to login 🚀"}
            </p>
                {!emailSent && !userNotFound  &&(
                <div className="bg-white flex flex-row w-full h-10 border-2 border-[#ffeae5] rounded-2xl px-5 items-center justify-center">
                    <input
                        type="text"
                        ref={inputRef}
                        className="w-full h-full text-center font-poppins bg-transparent text-[12px] text-[#545454] rounded-xl outline-none"
                        placeholder="Email"
                        onChange={handleEmailChange}
                        onKeyUp={handleEnterKeyPress}
                        value={textValue}>
                    </input>
                    {
                        isValidEmail &&
                        <button className="w-6 h-6 bg-transparent"
                            onClick={handleDoneButtonClick}>
                            <TbSend />
                        </button>
                    }

                </div>)}
                {
                    userNotFound &&
                    <button className="font-poppins font-semibold px-4 py-2 text-xs md:text-sm text-center bg-cover bg-new-gradient rounded-full hover:scale-90 duration-200"
                        onClick={
                            () => {
                                window.open("https://app.latte.social/video/upgrade", "_blank")
                            }
                        }>
                            <h1 className="text-white">
                                Sign up
                            </h1>
                    </button>

                }
            </div>
            <button className="absolute top-0 right-0 m-4 font-poppins"
                onClick={onClose}>
                Close
            </button>
        </div>
    )
}