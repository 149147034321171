import { useEffect, useRef, useState } from "react";
import playLogo from "../../assets/play.png";
import pauseLogo from "../../assets/pause.png";

import "./CustomVideoPlayerCSS.css";

export default function CustomVideoPlayer({
    src, // video source
    startTimeString, // start time of video
    endTimeString,
    currentTimeProp = 0,
    isPlayingProp = false,
}) {
    const trimmedVideoSrc = src + "#t=" + startTimeString + "," + endTimeString;    
    const videoRef = useRef(null);
    const [startTime, setStartTime] = useState(parseFloat(startTimeString));
    const [endTime, setEndTime] = useState(parseFloat(endTimeString));

    const playButtonRef = useRef(null);
    const progressBarRef = useRef(null);
    const [playing, setPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [progress, setProgress] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStartX, setDragStartX] = useState(0);
    const videoTime = endTime - startTime;
    const progressBarContainerRef = useRef(null); 
    const [circlePosition, setCirclePosition] = useState(0);
    const [showCircle, setShowCircle] = useState(false);
    const [videoAreaMouseDown, setVideoAreaMouseDown] = useState(false);
    const MAX_RETRIES = 5; // Set the maximum number of retries
    const [retryCount, setRetryCount] = useState(0);
    const [videoLoaded, setVideoLoaded] = useState(false);

    const currentSrc = useRef(src);

    useEffect(() => {
        if (videoRef.current) {
          videoRef.current.src = currentSrc.current;
        }
      }, [currentSrc.current]); 

    // useEffect(() => {
    //     console.log(currentSrc.current, src);
    // }, [])

    useEffect(() => {
        currentSrc.current = src;
      }, [src]);

    const handleVideoLoaded = () => {
        setVideoLoaded(true);
    }

    const loadVideo = () => {
        // console.log("Loading video...");
        // console.log("Video loaded: ", videoLoaded);
        // console.log("Video ref: ", videoRef.current);

        videoRef.current.load();
    };

    const retryLoadVideo = () => {
        if (!videoLoaded && retryCount < MAX_RETRIES) {
            // console.log("Retrying video load...");
            loadVideo();
            setRetryCount(retryCount + 1);
        }
    };

    useEffect(() => {
        if (!videoLoaded) {
            const retryInterval = setInterval(() => {
                retryLoadVideo();
            }, 3000); // Retry every 3 seconds

            return () => clearInterval(retryInterval);
        }
    }, [videoLoaded, retryCount]);

    useEffect(() => {
        // console.log("Video loaded: ", videoLoaded);

        if (videoLoaded && currentTimeProp) {
            // console.log("🚨 Setting video time to: ", currentTimeProp);
            setVideoTime(Math.max(0, currentTimeProp));
        }

        if (videoLoaded && !currentTimeProp) {
            // console.log("🚨 Setting video time to: ", startTime);
            setVideoTime(Math.max(0, startTime));
        }

        if (isPlayingProp) {
            setPlaying(true);
        }
        
    }, [videoLoaded]);

    useEffect(() => {
        if (playing) {
            var playPromise = videoRef.current.play();

            if (playPromise !== undefined) {
                playPromise.then(_ => {
                // Automatic playback started!
                // Show playing UI.
            })
            .catch(error => {
                console.log("Error playing video: ", error);
                // Auto-play was prevented
                // Show paused UI.
            });
            }
        } else {
            videoRef.current.pause();
        }

        // Check currentTime and set progress
        if (videoRef.current.currentTime >= endTime) {
            // reset video
            setVideoTime(startTime);
        }

    }, [playing]);

    useEffect(() => {
        if (!isDragging) {
            setCirclePosition(
                Math.min(
                    Math.max(
                        (progressBarContainerRef.current.getBoundingClientRect().width / 100) * progress,
                        0
                    ),
                    progressBarContainerRef.current.getBoundingClientRect().width
                )
            );
        }
    }, [progress]);

    // Progress Bar Update
    useEffect(() => {
        const intervalId = window.setInterval(function () {
            let newTime = Math.max(videoRef.current?.currentTime - startTime, 0)
            let newTimeProgress = Math.max(((videoRef.current?.currentTime - startTime) / videoTime) * 100, 0);
            setCurrentTime(
                Math.min(
                    newTime,
                    videoTime
                )
            );
            setProgress(newTimeProgress)
        }, 10);

        return () => {
            clearInterval(intervalId);
            setStartTime(Math.max(0, parseFloat(startTimeString)));
        };
    }, [startTime, videoTime]);

    // If currentTime > videoTime, pause video
    useEffect(() => {
        if (currentTime >= videoTime) {
            setPlaying(false);
        }
    }, [currentTime, videoTime]);

    useEffect(() => {
        document.addEventListener("mousemove", handleDocumentMouseMove);
        document.addEventListener("mouseup", handleDocumentMouseUp);
        return () => {
          document.removeEventListener("mousemove", handleDocumentMouseMove);
          document.removeEventListener("mouseup", handleDocumentMouseUp);
        };
      }, [isDragging]);

    const convertTime = (timeInSeconds) => { 
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    }

    const setVideoTime = (time) => {
        const safeTime = Math.max(startTime, Math.min(time, videoTime + startTime));
        videoRef.current.currentTime = safeTime;
    }



    const handleVideoAreaClick = (e) => {
        if (e.target === progressBarContainerRef.current ||
            e.target.parentNode === progressBarContainerRef.current ||
            e.target === playButtonRef.current ||
            e.target.parentNode === playButtonRef.current ||
            isDragging
            ) {
            return;
            }

        if (e.type === "mousedown") {
            setVideoAreaMouseDown(true);
        } else if (e.type === "mouseup") {
            setVideoAreaMouseDown(false);
            setPlaying(!playing);
        }
        //console.log("video area clicked");
        
    };

    const handlePlayButtonClick = (e) => {
        setPlaying(!playing);
    };

    const handleProgressBarClick = (event) => {
        setShowCircle(true);
        const progressBar = progressBarContainerRef.current;
        const rect = progressBar.getBoundingClientRect();
        const x = Math.max(0, event.clientX - rect.left);
        const width = rect.width;
        const time = (x / width) * videoTime;
        const newTime = Math.max(startTime, Math.min(time, startTime + videoTime));
        //console.log(`New time: ${newTime}`)
        setVideoTime(startTime + time);
        //console.log(`Start time: ${startTime}`, typeof(startTime))
        //console.log(`Setting video time to ${time + startTime}}`)
        setProgress(
            Math.min(
                (videoRef.current.currentTime / videoRef.current.duration) * 100,
                100
            )
        );
        setCirclePosition(
            Math.min(
                Math.max(
                    event.clientX - progressBarContainerRef.current.getBoundingClientRect().left,
                    0
                ),
                progressBarContainerRef.current.getBoundingClientRect().width
            )
        );
        setTimeout(() => {
          setShowCircle(false);
        }, 50);
    };

    const handleProgressBarHover = (event) => {
        const progressBar = progressBarContainerRef.current;
        const rect = progressBar.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const width = rect.width;
        const time = (x / width) * videoTime;
        //setShowCircle(true);
        // show circle at x
    };


    const handleProgressBarMouseDown = (event) => {
        const progressBar = progressBarContainerRef.current;
        const rect = progressBar.getBoundingClientRect();
        const x = event.clientX - rect.left;
        setIsDragging(true);
        setDragStartX(x);
      };
      
      const handleDocumentMouseMove = (event) => {
        if (isDragging) {
          setCirclePosition(
            Math.max(
                0,
                Math.min(
                    event.clientX - progressBarContainerRef.current.getBoundingClientRect().left,
                    progressBarContainerRef.current.getBoundingClientRect().width
                )
            )
            );
          setShowCircle(true);
        }
      };
      
      
      const handleDocumentMouseUp = (event) => {
        if (isDragging) {
            const progressBar = progressBarContainerRef.current;
            const rect = progressBar.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const width = rect.width;
            const time = (x / width) * videoTime;
            const calculatedTime = time + startTime;
            setVideoTime(calculatedTime);
            setIsDragging(false);
            setTimeout(() => {
                setShowCircle(false);
            }, 500);
        }
      };


  return (
    <div className="container max-h-72">
        <div className="video-container" 
            onMouseDown={handleVideoAreaClick}
            onMouseUp={handleVideoAreaClick}>
            <div className="video-controls" id="video-controls">
                <div className="flex-column">
                    {videoLoaded &&
                        <div className="time-display">
                            <img 
                                src={ playing ? pauseLogo : playLogo } 
                                alt="Play" 
                                className="play-button" 
                                ref={playButtonRef}
                                onClick={handlePlayButtonClick} />
                            <span>{convertTime(currentTime)}</span>
                            <span style={{fontSize: "12px"}}>&nbsp;/&nbsp;</span>
                            <span>{convertTime(videoTime)}</span>
                        </div>
                    }
                    <div
                        className="time_progressbarContainer"
                        onClick={handleProgressBarClick}
                        onMouseOver={handleProgressBarHover}
                        onMouseDown={handleProgressBarMouseDown}
                        ref={progressBarContainerRef} 
                    >
                        <div 
                            style={{ width: `${Math.min(progress, 100)}%` }} 
                            className="time_progressBar"
                            ref={progressBarRef}
                            >
                        </div>
                        {showCircle && (
                            <div
                            className="circle"
                            style={{ 
                                position: "absolute",
                                left: `${circlePosition}px` }}
                            ></div>
                        )}
                    </div>
                </div>
            </div>
            <video
                className="video"
                preload="metadata"
                ref={videoRef}
                onLoadedMetadata={handleVideoLoaded}
                />

        </div>
    </div>
  );
  
}
