import React, { useEffect, useState, memo } from 'react';
import axios from 'axios';

interface QueryBasedFlowCompletionListenerProps {
    entryID: string | null;
    newSnippetsReadyCallback?: (isFinal: boolean) => void;
    initialProcessingCompletedCallback?: () => void;
    videoTrimmedCompletionCallback?: (videoID: string) => void;
    endpoint: string;
    videoID?: string;
}

const QueryBasedFlowCompletionListener = ({ entryID, newSnippetsReadyCallback, initialProcessingCompletedCallback, videoTrimmedCompletionCallback, endpoint, videoID }: QueryBasedFlowCompletionListenerProps ) => {
    useEffect(() => {
        const es = new EventSource(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/${endpoint}?updateType=listen&entryID=${entryID}`);

        //console.log("QueryBasedFlowCompletionListener rendered")
        //console.log("Opened SSE connection")

        es.onmessage = (event) => {
            console.log("Ping received:", event.data);
            // maybe refactor to check event data and skip initial if statement
            if (event.data.includes("completed")) {
                if (endpoint === "query-based-processing-updates") {
                    initialProcessingCompletedCallback?.();
                    es.close();
                }

                if (endpoint === "query-based-results-updates") {
                    newSnippetsReadyCallback?.(false);
                }

                if (endpoint === "query-based-trimming-updates" && videoID) {
                    videoTrimmedCompletionCallback?.(videoID);
                    es.close();
                }
            } else if (event.data.includes("final")) {
                newSnippetsReadyCallback?.(true);
                es.close();
            }
        }

        return () => {
            es.close();
            console.log("Closed SSE connection")
        };
    }, [])

    return (
        <div>
        </div>
    )
}

export default QueryBasedFlowCompletionListener;