import React, { useState } from "react";

interface GetMoreClipsButtonProps {
    onDurationClick: (duration: number) => void;
}

const GetMoreClipsButton: React.FC<GetMoreClipsButtonProps> = ({ 
    onDurationClick
}) => {
  const [showModal, setShowModal] = useState(false);

  const durations = [30, 60, 90, 180, 300];

  const handleClick = (duration : number) => {
    onDurationClick(duration);
    setShowModal(false);
  };

  return (
    <div
      className="relative bg-white rounded-2xl w-12 h-12 justify-center items-center flex cursor-pointer shadow-sm hover:scale-105 duration-300"
      onMouseEnter={() => setShowModal(true)}
      onMouseLeave={() => setShowModal(false)}
    >
      <div>+</div>
      {showModal && (
        <div 
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-48 rounded-xl shadow-lg bg-white z-50"
        >
            <div className="py-1 text-center">
                <div className="text font-bold text-center py-2">Get More Clips ✨</div>
                {durations.map((duration) => (
                <button
                    key={duration}
                    className="w-full text-center block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => handleClick(duration)}
                >
                    { duration <= 90 ? `${duration} seconds` : `${duration / 60} minutes` }
                </button>
                ))}
            </div>
        </div>
      )}
    </div>
  );
};

export default GetMoreClipsButton;
