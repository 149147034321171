import axios from 'axios'
import { UserAccount } from '../../Types/DataTypes'
import { setUserIsLoggedInLocalStorage, setUserIsLoggedOutLocalStorage, setEmailLocalStorage, unsetEmailLocalStorage } from '../loginHelperFunctions'

export default class UserManager {
    constructor (
        
    ) {
        // this.stripeServer = "get-subscription-status"
    }

    public getUserAccount = async (email: string | null) => {
        if (!email) {
            return
        }

        const response = await axios.get(`https://latte-edit-server-uhhxc4xeaq-ew.a.run.app/legacy/videoAccount?email=${email}`)

        const userAccount = response.data

        // console.log("🔥 User account:", userAccount)

        if (userAccount === null) {
            return null
        }

        const doesRequireUpgrade = this.checkIfUserRequiresUpgrade(userAccount)

        userAccount.requiresUpgrade = doesRequireUpgrade
    
        return userAccount
    }

    public getUserAccountFields = async (email: string, fields: string[]) => {
        const endpoint = `https://latte-video-server-dwr7snkjfq-ew.a.run.app/get-user-account-fields`
        const params = {
            email: email,
            fields: fields.join(","), // comma separated list of fields
        }
        const response = await axios.get(endpoint, { params: params })
        return response.data
    }

    public authenticateUser = async (
        token: string, 
        handleLoginAttempt: (isSuccessful: boolean, email: string) => void
    ) => {
        if (token) {
            // console.log(`⚡️ Verifying token: ${token}`)
            const response = await axios.get(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/verify-token?token=${token}`)
            const { isValid, email, message } = response.data;
            console.log(`⚡️ Token is valid: ${isValid}`)
            // console.log(`⚡️ Token message: ${message}`)
            const localStorageEmail = localStorage.getItem('email') || ""
            // console.log(`⚡️ Local storage email: ${localStorageEmail}, ${localStorageEmail !== ""}`)

            if (isValid && (localStorageEmail == "" || localStorageEmail === email)) {
                // console.log(`⚡️ User is valid: ${email}`)
                localStorage.setItem('email', email)
                setUserIsLoggedInLocalStorage();
                handleLoginAttempt(true, email);
                setEmailLocalStorage(email);
                return true
            }
        }
        
        // console.log("⚡️ User is not valid")
        setUserIsLoggedOutLocalStorage();
        handleLoginAttempt(false, "");
        unsetEmailLocalStorage();

        return false
    }

    public checkIfUserRequiresUpgrade = (userAccount: UserAccount) => {
        // if clipsMeteredSubscriptionID or minutesMeteredSubscriptionID exist, 
        // then user is on some metered plan
        // return false
        const clipsMeteredSubscriptionID = userAccount.clipsMeteredSubscriptionID
        console.log(`🕺 clipsMeteredSubscriptionID: ${clipsMeteredSubscriptionID}`)
        const minutesMeteredSubscriptionID = userAccount.minutesMeteredSubscriptionID
        console.log(`🕺 minutesMeteredSubscriptionID: ${minutesMeteredSubscriptionID}`)

        if (clipsMeteredSubscriptionID || minutesMeteredSubscriptionID) {
            // console.log(`User is on metered plan: ${clipsMeteredSubscriptionID}, ${minutesMeteredSubscriptionID}`)
            return false
        }


        const clipsGenerated = userAccount.clipsGeneratedThisMonth
        const maxClipsGenerated = userAccount.maxClipsGenerated

        const minutesUploaded = userAccount.minutesUploadedThisMonth
        const maxMinutesUploaded = userAccount.maxUploadMinutes

        if (clipsGenerated >= maxClipsGenerated || minutesUploaded >= maxMinutesUploaded) {
            console.log("User requires an upgrade")
            return true
        } else {
            return false
        }
    }

    public getSubscriptionStatus = async (email: string | null) => {
        const response = await axios.get("https://latte-payment-server-qo2mn26dqq-ew.a.run.app/get-subscription-status", { params: { email: email } })
        const subscriptionStatus = response.data.subscriptionStatus
        return subscriptionStatus
    }


    public userIsOnTrial = async (email: string | null) => {
        if (!email) {
            return null;
        }
        const userTrialResp = await axios.post("https://latte-payment-server-qo2mn26dqq-ew.a.run.app/user-on-trial", { email: email })
        const userIsOnTrial = userTrialResp.data.userIsOnTrial
        console.log(`🕺 email: ${email} is on trial: ${userIsOnTrial}`)
        return userIsOnTrial
    }

    public userSubmissionAllowed = async (email: string | null) => {
        // This should be the central point for all checks
        // to see if a user is allowed to submit a video request
        const userIsOnTrial = await this.userIsOnTrial(email)
        // console.log(`🕺 email: ${email} is on trial: ${userIsOnTrial}`)
        if (userIsOnTrial) {
            const userVideoRequests = await axios.get(`https://latte-video-server-dwr7snkjfq-ew.a.run.app/get-user-video-requests?email=${email}`)
            const userVideoRequestsCount = userVideoRequests.data.length
            // console.log(`🕺 email: ${email} has submitted ${userVideoRequestsCount} video requests`)
            if (userVideoRequestsCount > 0) {
                return false
            }   
        }

        const subscriptionStatus = await this.getSubscriptionStatus(email)
        // console.log(`🕺 email: ${email} has ${subscriptionStatus} subscription`)

        if (subscriptionStatus === "active" || userIsOnTrial) {
            return true
        } 

        return false
    }

    public endUserTrial = async (email: string | null) => {
        console.log(`🕺 ending trial for email: ${email}...`)
        // return true

        if (!email) {
            return null;
        }
        const endpoint = "https://latte-payment-server-qo2mn26dqq-ew.a.run.app/end-free-trial"
        const resp = await axios.post(endpoint, { email: email })

        return resp.data
    }

    public incrementMagicSubtitlesUsage = async (email: string) => {
        const endpoint = "https://latte-video-server-dwr7snkjfq-ew.a.run.app/increment-magic-subtitles-usage"
        const resp = await axios.post(endpoint, { email: email })
        return resp.data
    }
}